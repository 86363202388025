import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as ActionsAdmin from '../components/Common/ActionAdmin';
import { Consent_ModelToForm } from '../components/Common/DataManager';
import Utils from '../components/Common/Utils';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ConsentState {
    isLoading: boolean;
    xIndex?: string;
    data: any;
}

interface RequestConsentAction {
    type: 'REQUEST_CONSENT_DATA';
    xIndex: string;
    //data: any;
}

interface ReceiveConsentAction {
    type: 'RECEIVE_CONSENT_DATA';
    xIndex: string;
    data: any;
}

type KnownAction = RequestConsentAction | ReceiveConsentAction;

export const actionCreators = {
    requestData: (xIndex: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.consent && xIndex !== appState.consent.xIndex) {
            dispatch({ type: 'REQUEST_CONSENT_DATA', xIndex: xIndex });

            fetch(`/api/${ActionsAdmin.Models.Consent}/GetAsync`, {
                method: 'GET',
                headers: Utils.headerXRequest()
            })
                .then(response => response.json() as Promise<any>)
                .then(xdata => {
                    //console.log(Consent_ModelToForm(xdata.data));
                    dispatch({ type: 'RECEIVE_CONSENT_DATA', xIndex: xIndex, data: xdata.data ? Consent_ModelToForm(xdata.data) : {} });
                });
        }
    }
};

const unloadedState: ConsentState = { data: {}, isLoading: false };

export const reducer: Reducer<ConsentState> = (state: ConsentState | undefined, incomingAction: Action): ConsentState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_CONSENT_DATA': {
            //console.log('REQUEST_CONSENT_DATA');
            return {
                xIndex: action.xIndex,
                data: state.data,
                isLoading: true
            };
        }
        case 'RECEIVE_CONSENT_DATA': {
            //console.log('RECEIVE_CONSENT_DATA');
            //if (action.xIndex === state.xIndex) {
            if (action.data) {
                return {
                    xIndex: action.xIndex,
                    data: action.data,
                    isLoading: false
                };
            }
        }
            break;
    }

    return state;
};
