import * as React  from 'react';
import { Container } from 'reactstrap';
import Script from 'react-load-script';


class LoadScript extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            scriptLoaded: false,
            files: props.files
        }
    }
    handleScriptCreate() {
        this.setState({ scriptLoaded: false })
    }

    handleScriptError() {
        this.setState({ scriptError: true })
    }

    handleScriptLoad() {
        this.setState({ scriptLoaded: true })
    }
    getScript() {
        let load_files = []
        for (let i = 0; i < this.state.files.length; i++) {
            load_files.push(<Script
                url={this.state.files[i]}
                onCreate={this.handleScriptCreate.bind(this)}
                onError={this.handleScriptError.bind(this)}
                onLoad={this.handleScriptLoad.bind(this)}
                key={i}
            />)
        }
        return load_files;
    }
    render() {
        return (
            this.getScript()
            )
    }
}
export default LoadScript;