import React from 'react';
import $ from 'jquery'
import Auth from './Auth';
import DataManager from './DataManager';
import Inputmask from 'inputmask';
const moment = require('moment');


class Utils extends React.Component {

    static isEmptyObject(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop))
                return false;
        }
        return true;
    }
    static isEmptyOrSpaces(str) {
        return typeof str === 'undefined' || !str || str === '' || str === null || str.length === 0;// || str.match(/^ *$/) !== null;
    }

    static StoreDOB(val) {
        sessionStorage.setItem('dob_store', val);
    }
    static Get_StoreDOB() {
        return sessionStorage.getItem('dob_store');
    }

    static isSet(variable) {
        return typeof variable !== 'undefined';
    }

    static isExtension_Excel(file) {
        var reg = /(.*?)\.(xlsx)$/;
        return file.match(reg);
    }
    static isExtension_Images(file) {
        var reg = /(.*?)\.(jpg|bmp|jpeg|png)$/;
        return file.match(reg);
    }

    static toFileName(txt, separate = ':') {
        txt = txt.substring(txt.indexOf(separate) + 1);
        return txt.replace(/[/\\?%*:|"<>]/g, '-').trim();
    }

    static step1validate(val, unknow_time = false, unknow_place = false, company = false) {    
        let msg = [];
        if (!val || Object.keys(val).length < 1)
            return (<li className="err-msg">กรุณาระบุข้อมูลให้ครบถ้วน/Please enter your info</li>);
        if (!val.first_name || this.isEmptyOrSpaces(val.first_name))
            msg.push(<li key="fl_name" className="err-msg">กรุณาระบุ นามสมมุติ/Short name is required</li>);
        if (company && (!val.last_name || this.isEmptyOrSpaces(val.last_name)))
            msg.push(<li key="ref_name" className="err-msg">กรุณาระบุ โค้ดอ้างอิง/Reference code is required</li>);
        if (!val.gender || this.isEmptyOrSpaces(val.gender))
            msg.push(<li key="gender" className="err-msg">กรุณาระบุเพศ/Gender is required</li>);
        if (!val.date_of_birth || this.isEmptyOrSpaces(val.date_of_birth))
            msg.push(<li key="date_of_birth" className="err-msg">กรุณาระบุ วัน/เดือน/ปี เกิด /Birth date is required</li>);
        else {
            let bod = val.date_of_birth.split('/');
            let msg_dob = <li key="date_of_birth" className="err-msg">กรุณาตรวสอบ วัน/เดือน/ปี เกิด/Birth date invalid</li>;
            if ($.isNumeric(bod[0]) && $.isNumeric(bod[1]) && $.isNumeric(bod[2])) {
                var d = bod[0] * 1; var m = bod[1] * 1; var y = bod[2] * 1;
                if ((d > 31 || d < 1) || (m > 12 || m < 1) || y < 1000)
                    msg.push(msg_dob);
                else {
                    if (y >= 2300 && (y < 2443 || y > 2643)) {
                        msg.push(<li key="date_of_birth" className="err-msg">ปี พี.ศ. ต้องอยู่ระหว่าง 2443-2643/Year range 2443-2643</li>);
                    } else if (y < 2300 && (y < 1900 || y > 2100)) {
                        msg.push(<li key="date_of_birth" className="err-msg">ปี ค.ศ. ต้องอยู่ระหว่าง 1900-2100/Year range 1900-2100</li>);
                    }
                }
            } else
                msg.push(msg_dob);
        }
        if (!unknow_time || unknow_time !== true) {
            if (!val.birth_time)
                msg.push(<li key="hhval" className="err-msg">กรุณาระบุเวลาเกิด/Birth time is required</li>);
            else {
                const dt_msg = <li key="hhval" className="err-msg">กรุณาระบุเวลาเกิดให้ถูกต้อง/Birth time invalid, 00:00 - 23:59</li>;
                let bt = val.birth_time.split(':');
                if ($.isNumeric(bt[0]) && $.isNumeric(bt[1])) {
                    var f = bt[0] * 1; var l = bt[1] * 1;
                    if ((f < 0 || f > 23) || (l < 0 || l > 59))
                        msg.push(dt_msg);
                } else {
                    msg.push(dt_msg);
                }                
            }
            console.log(msg);
        }

        if (company) {
            if (!val.department || !val.department.value) {
                msg.push(<li key="department-val" className="err-msg">กรุณาระบุฝ่าย/แผนก/สังกัด, Department is required</li>);
                return (<ul className="err-msg text-danger">{msg}</ul>);
            }
            if (!val.position || !val.position.value) {
                msg.push(<li key="position-val" className="err-msg">กรุณาระบุตำแหน่งงาน/Job position is required</li>);
                return (<ul className="err-msg text-danger">{msg}</ul>);
            }
        }

        if (!unknow_place || unknow_place !== true) {
            if (!val.country || !val.country.value) {
                msg.push(<li key="country-val" className="err-msg">กรุณาระบุประเทศ/Country is required</li>);                
                return (<ul className="err-msg text-danger">{msg}</ul>);
            }
            if (!val.province || !val.province.value) {
                msg.push(<li key="province-val" className="err-msg">กรุณาระบุจังหวัด/City or Province is required</li>);                
                return (<ul className="err-msg text-danger">{msg}</ul>);
            } else if (val.province.name !== val.country.value) {
                msg.push(<li key="province-val" className="err-msg">กรุณาระบุจังหวัดภายในประเทศ/City or Province invalid</li>);
                return (<ul className="err-msg text-danger">{msg}</ul>);
            }
            if (val.country.value === 'THA') {
                if (!val.district || !val.district.value) {
                    msg.push(<li key="province-val" className="err-msg">กรุณาระบุอำเภอ/District is required</li>);
                    return (<ul className="err-msg text-danger">{msg}</ul>);
                } else if (val.district.name * 1 !== val.province.value * 1) {
                    msg.push(<li key="province-val" className="err-msg">กรุณาระบุอำเภอภายในจังหวัด/District invalid</li>);
                    return (<ul className="err-msg text-danger">{msg}</ul>);
                }
            } else if (val.district && val.district.value > 0) {
                if (val.district.name * 1 !== val.province.value * 1) {
                    msg.push(<li key="province-val" className="err-msg">อำเภอไม่ถูกต้อง/District invalid</li>);
                    return (<ul className="err-msg text-danger">{msg}</ul>);
                }
            }
        }

        if (msg.length === 0 && val.date_of_birth && val.birth_time) {
            const chk_date = this.InputToDateTime(val.date_of_birth, val.birth_time);
            if (!chk_date)
                msg.push(<li key="date_of_birth" className="err-msg">กรุณาตรวสอบ วัน/เดือน/ปี เกิด ให้ถูกต้อง/Please check your birth of date</li>);
        }

        if (msg.length > 0) {
            return (<ul className="err-msg text-danger">{msg}</ul>);
        }

        return true;
    }

    static GetTimeSelection(value) {
        let x = this.TimeSelection();
        let v = x.find(function (e) {
            return e.value == value;
        });
        return value;
    }

    static headerXRequest(auth = true) {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            'Host': window.location.hostname
        };
        if (auth) {
            const token = Auth.GetToken();
            if (token)
                headers["Authorization"] = `Bearer ${token}`;
        }
        return headers;
    }
    static RandomRgb(opacity = 1) {
        var num = Math.round(0xffffff * Math.random());
        var r = num >> 16;
        var g = num >> 8 & 255;
        var b = num & 255;
        return 'rgb(' + r + ', ' + g + ', ' + b + ',' + opacity+')';
    }

    static headerXFromData() {
        let headers = {};
        const token = Auth.GetToken();
        if (token)
            headers["Authorization"] = `Bearer ${token}`;
        return headers;
    }



    static DateToObject(d, t) {
        let ds = d.split('/');
        return {
            HR: parseInt(t),
            DD: parseInt(ds[0]),
            MM: parseInt(ds[1]),
            YY: parseInt(ds[2]),
            stage: 'r'
        }
    }



    static InputToDateTime(date, time) {
        try {
            let ds = date.split('/');
            let y = ((ds[2] * 1) >= 2300) ? (ds[2] * 1) - 543 : ds[2];
            if (y > 3500 || y < 1000) return false;
            let t = time.split(':');
            const x = new Date(y, (ds[1] * 1), 0, 0, 0, 0, 0);
            const d = new Date(y, (ds[1] * 1) - 1, ds[0] * 1, t[0] * 1, t[1] * 1, 0, 0);
            if (x.getMonth() !== d.getMonth() || x.getFullYear() != x.getFullYear()) {
                console.log('date=>' + moment(x).format('YYYY-MM-DD'));
                return false;
            }
            return d;
        } catch (e) {
            console.log(e);
            return false;
        }
    }
    static InputToDateFormat(date, time) {
        let ds = date.split('/');
        let y = ((ds[2] * 1) >= 2300) ? (ds[2] * 1) - 543 : ds[2];
        let t = time.split(':'); //2000-12-01T05:43:00.000Z
        return `${y}-${("0" + ds[1]).slice(-2)}-${("0" + ds[0]).slice(-2)} ${t[0]}:${t[1]}:00`;
        //return new Date(y, (ds[1] * 1) - 1, ds[0], t[0] * 1, t[1] * 1, 0, 0);
    }



    static isLoggedIn() {
        return false;
    }

    static ShowModalFormTable(data) {
        //if (!data || (!data.id && !data.code)) return;
        $('tbody tr').removeClass('bg-warning').removeClass('bg-row-active');
        if (data && data.id && data.id > 0)
            $('#rowno' + data.id).addClass('bg-row-active');
        else if (data && data.code && data.code.length > 0)
            $('#rowno' + data.code).addClass('bg-row-active');
        //alert(data.id);
        setTimeout(function () {
            //$(function () {
                //$('#rowno' + data.id ? '' + data.id : (data.code ? data.code : '')).addClass('bg-row-active');
                $("#common-modal-form").modal({ show: true });
            //});
            Inputmask('decimal').mask(document.getElementsByClassName("imask-decimal"));
        }, 20);
    }

    static HideModalFormTable(data) {
        $(function () {
            $(".modal").modal('hide');
            $('tbody tr').removeClass('bg-warning').removeClass('bg-row-active');
            $('#rowno' + data.id ? data.id : data.code).addClass('bg-warning');
        });
    }

    static blinkerTxt(r) {
        var i = 0;
        var blink;
        $(r).each(function () {
            var elem = $(this);
            blink = setInterval(function () {
                if (elem.css('visibility') == 'hidden') {
                    elem.css('visibility', 'visible');
                    i++;
                    if (i >= 3) {
                        clearInterval(blink);
                    }
                } else {
                    elem.css('visibility', 'hidden');
                }

            }, 200);
        });
    }

    static ValidateExtension(allowedFiles) {
        //allowedFiles = [".doc", ".docx", ".pdf"];
        var fileUpload = document.getElementById("fileUpload");
        var lblError = document.getElementById("lblError");
        var regex = new RegExp("([a-zA-Z0-9\s_\\.\-:])+(" + allowedFiles.join('|') + ")$");
        if (!regex.test(fileUpload.value.toLowerCase())) {
            lblError.innerHTML = "Please upload files having extensions: <b>" + allowedFiles.join(', ') + "</b> only.";
            return false;
        }
        lblError.innerHTML = "";
        return true;
    }

    static dateTimeToStringNumber(val, local = 'en-US', empty = true) {
        if (!val) {
            if (empty)
                return '';
            else
                return '-';
        }
        var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric' };
        var date = val;
        try {
            if (val instanceof Date) {
                if (val.getFullYear() < 1500)
                    return empty ? '' : '-';
                return val.toLocaleString(local, options);
            }
        } catch (e) {
            console.log(e);
            return empty ? '' : '-';
        }
        try {
            date = new Date(val);
            if (date.getFullYear() < 1500)
                return empty ? '' : '-';
            return date.toLocaleString(local, options);
        } catch (e) {
            console.log(e);
            return empty ? '' : '-';
        }
    }
}


export default Utils;