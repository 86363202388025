import * as React from 'react';


export default class Hero extends React.PureComponent<{}> {
    public render() {
        return (
            <section className="hero-section bg-red-1">         
                <div className="hero-slider">
                    <div className="hero-item set-bg" data-setbg="/img/hero/bg.png">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-8">
                                    <h2 className="text-hero"><span className="text-shadow">FASTEn Model<span className="tm">&trade;</span></span><br /><br /></h2>
                                </div>
                            </div>
                            </div>
                        </div>
                </div>
            </section>
        );
    }
}
