import * as WeatherForecasts from './WeatherForecasts';
import * as Counter from './Counter';
import * as Project from './Project';
import * as Consent from './Consent';
import * as Promos from './Promos';
import * as OptionalField from './OptionalField';
import * as ClientUser from './ClientUser';
import * as PageForm from './PageForm';
// The top-level state object
export interface ApplicationState {
    counter: Counter.CounterState | undefined;
    weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
    project: Project.ProjectState | undefined;
    consent: Consent.ConsentState | undefined;
    optionalField: OptionalField.OptionalFielState | undefined;
    clientUser: ClientUser.ClientUserState | undefined;
    pageForm: PageForm.PageFormState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    counter: Counter.reducer,
    weatherForecasts: WeatherForecasts.reducer,
    project: Project.reducer,
    consent: Consent.reducer,
    promos: Promos.reducer,
    optionalField: OptionalField.reducer,
    clientUser: ClientUser.reducer,
    pageForm: PageForm.reducer

};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
