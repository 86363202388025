import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import $ from 'jquery';
import Auth from '../Common/Auth';
export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean, user: any }> {
    public state = {
        isOpen: false,
        user: Auth.GetUser()
    };
    //public logout() {
    //    Auth.Logout();
    //    window.location.href = '/';
    //}

    public autlink() {
        if (this.state.user)
            return <li key="logout"><Link to="/logout">Logout</Link></li>;
        else
            return <li key="login"><Link to="/login">Login</Link></li>;
    }

    public render() {
        $(document).ready(function ($) {
            var path = window.location.pathname.split("/").pop();
            var target = $('a[href="/' + path + '"]');
            target.parent('li').addClass('active');
            let li = $('ul.main-menu li').first();
            if (!path || path === ''){
                 $(li).addClass('active');
            }
            $('ul.main-menu li a').click(function () { $('ul.main-menu li').removeClass('active'); $(this).parent('li').addClass('active');});
            $('#home-linker').click(function () { $('ul.main-menu li').removeClass('active'); $(li).addClass('active'); });
        });
        return (
            <header className="header-section clearfix">
                <div className="site-navbar">
			<Link to="/" className="site-logo" id="home-linker">
                        <img src="img/logo.png" alt=""/>
			</Link>
                        <div className="header-right">                            
                                <div className="header-info-box align-middle">
                                    <div className="hib-icon">
                                <img src="img/icons/map-marker.png" alt="" className="" />
                            </div>
                            <div className="hib-text">
                                <h6 className="comp">Wintegrate 99 Co.,Ltd.</h6>
                                <p className="adr d-none">1687/1 Phahon 19 Building, Floor 2, Phahonyothin Rd.<br />Ladyao, Chatuchak, Bangkok 10900 Thailand</p>
                                        <p className="hidden">BKK, Lat Prao, 711/2 Lat Prao Wang Hin Road</p>
                                        </div>
                                    </div>
                                </div>
			                    <nav className="site-nav-menu">
                                    <ul className="main-menu">
                                        <li><Link to="/">Home</Link></li>                                                                        
                                        <li><Link to="/about">About FASTEn Model&trade;</Link></li>
                                        <li><Link to="/product">Products</Link></li>    
                            <li><Link to="/contact">Contact</Link></li>
                            {this.autlink()}
                                    </ul>
                                </nav>

                            </div>
	</header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
