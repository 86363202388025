import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import Hero from './Hero';
import Footer from './Footer';
import LoadScript from '../Common/LoadScript';
import Auth from '../Common/Auth';
import '../../templates/public/css/slicknav.min.css';
import '../../templates/public/css/style.css';
import * as DataManager from '../Common/DataManager';

interface IProps {
}

interface IState {
    user?: any;
    scriptLoaded?: boolean;
    scriptError?: boolean;
}


class Layout extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            user: Auth.GetUser()
        };
    }

    componentDidMount() {
        if (window.location.pathname === '/index.html' || window.location.pathname === 'index.html' || window.location.pathname === 'index')
            window.location.href = '/';
    }

    handleScriptCreate() {
        this.setState({ scriptLoaded: false })
    }

    handleScriptError() {
        this.setState({ scriptError: true })
    }

    handleScriptLoad() {
        this.setState({ scriptLoaded: true })
    }
    userInfo() {
        let elm = <></>;
        if (Auth.isLoggedIn()) {
            if (!Auth.IsGeneralUser(this.state.user) && !Auth.IsProjectUser(this.state.user)) {//Auth.IsAdmin(this.state.user)) {
                Auth.Logout();
                window.location.href = '/';
            }
            if (this.state.user) {
                if (this.state.user.alias_name && DataManager.checkNotNull(this.state.user.alias_name)) {
                    let aln = this.state.user.alias_name;
                    let str_mid = aln.substring(aln.lastIndexOf("[") + 1, aln.lastIndexOf("]"));
                    if (DataManager.checkNotNull(str_mid))
                        elm = <div className="float-right user-name-public">{aln.substring(0, aln.lastIndexOf("["))} <b>{str_mid}</b></div>;
                    else
                        elm = <div className="float-right user-name-public">{aln}</div>;
                } else
                    elm = <div className="float-right user-name-public">{this.state.user.firstname} {this.state.user.lastname}</div>;
            }
        }   
        //<div className="float-right user-name-public">ccc</div>
        return elm;
        
    }
    public render() {
        return (
            <React.Fragment>                
            <NavMenu />
            <Hero />
                <section className="services-section" id="services-section">
                    <div className="services-warp" id="services_warp">
                        {this.userInfo()}
                        {this.props.children}
                    </div>         
                </section>
            <Footer/>
            <LoadScript files={['/js/main.js']} />
        </React.Fragment >
            )
    }
}
export default Layout;