
import * as React from 'react';
import { Route, withRouter, Switch} from 'react-router';
import { Redirect } from 'react-router-dom';

import Layout from './components/public/Layout';
import Main from './components/public/Main';
import LoginFrm from './components/LoginFrm';
//import MainRef from './components/public/MainRef';
import E404 from './components/E404';

//import Login from './components/LoginFrm';
import About from './components/public/About';
import Product from './components/public/Product';
import Contact from './components/public/Contact';

import Proj from './components/public/Pro';
import Logout from './components/Logout';
import Test from './components/public/Test';
import 'bootstrap/dist/css/bootstrap.css';
import './custom.css'

export default () => {
    if (window.location.pathname === '/index.html' || window.location.pathname === 'index.html' || window.location.pathname === 'index')
        window.location.href = '/';
    return (<Layout>
        <Switch>

            <Route exact path='/' component={Main} />            
            <Route path='/login' component={LoginFrm} />
            <Route path='/about' component={About} />
            <Route path='/product' component={Product} />
            <Route path='/contact' component={Contact} />
        <Route path='/pro/:xIndex?' component={Proj} />
        
        <Route path='/logout' component={Logout} />
            <Route path='/test' component={Test} />
            <Route exact strict path="/nox"/>
            <Route component={E404} />
        </Switch>
        </Layout>);
}
//<Route exact path='/ref/:xIndex?' component={MainRef} />
/*--> OK
<Route path='/404' component={E404} />
<Redirect to="/404" />
*/
