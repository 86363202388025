import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Auth from '../../Common/Auth';
import Loading from '../../Common/Loading';
export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean, loading: boolean, user: any }> {
    public state = {
        isOpen: false, loading: false, user: Auth.GetUser()
    };
    private logout = ()=>{
        this.setState({loading:true});
        Auth.Logout();
    }

    private userName = (state: { user: any }) => {
        if (state.user) {
            if (Auth.IsAdmin(state.user))
                return <h5 className="text-secondary"><Link to="/admin/user" className="profile-link">Administrator</Link></h5>;
            else if (Auth.IsProjectAdmin(state.user))
                return <h5 className="text-secondary">Company Admin</h5>;
            else if (Auth.IsPromoAdmin(state.user))
                return <h5 className="text-secondary">Promos Admin</h5>;
            else
                return <h5 className="text-secondary">{state.user.alias_name}</h5>;
        } else
            return <h5 className="text-secondary">Not found</h5>;
    }

    public render() {
        return (
            <header className="header-section header-size clearfix">
                <div className="site-navbar bg-dark">
                    <Link to="/admin" className="site-logo">
                        <img src="/img/logo-admin.png" alt=""/>
			        </Link>
                    <div className="header-right">                            
                            <div className="header-info-box">
                                <div className="hib-icon">
                                    <i className="fa fa-user-circle-o font-size-50 text-secondary" aria-hidden="true"></i>
                                </div>
                            <div className="hib-text">
                                {this.userName(this.state)}                                
                            </div>
                        </div>
                    <button className="search-switch" onClick={this.logout}><i className="fa fa-power-off" aria-hidden="true"></i></button>
                    </div>
                </div>
                <Loading loading={this.state.loading}/>
	        </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
