import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
//import $ from 'jquery';
import Auth from '../../Common/Auth';
//import ReactDOM from 'react-dom';

function menuShowUp() {
    $('span[data-toggle="collapse"] i').detach();
    $('.collapse').each(function (i, obj) {
        let id = obj.getAttribute('id');
        if (obj.classList.contains("show")) {
            $('span[data-target="#' + id + '"]').append('<i class="fa fa-minus float-right margin-top-4"></i>');
        } else {
            $('span[data-target="#' + id + '"]').append('<i class="fa fa-plus float-right margin-top-4"></i>');
        }
    });
}
interface IProps {
}

interface IState {
    user?: any;
}

///export default class SideMenu extends React.PureComponent<{}, {}> {
export default class SideMenu extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            user: Auth.GetUser()
        };
    }


    componentDidMount() {
        menuShowUp();
        //console.log(this.state.user);
        //---???
        let path = window.location.pathname;
        let links = $('a.nav-link');
        //console.log(links);
        for (var numb in links) {
            if (!links.hasOwnProperty(numb)) continue;
            if (path === $(links[numb]).attr('href')) {
                $('.nav-link').removeClass('nav-link-active');
                $(links[numb]).addClass('nav-link-active');

                //---- Find parent
                $(links[numb]).closest('.collapse').addClass('show');
            }
            //console.log($(links[numb]).attr('href'));
        }

        //console.log('Path: ' + path);

        $('.btn-link,.nav-link').click(function (obj) {
            $('.nav-link').removeClass('nav-link-active');
            $(this).addClass('nav-link-active');

            let cols = obj.target.getAttribute('data-toggle') === 'collapse';
            let show = $('' + obj.target.getAttribute('data-target')).hasClass('show');
            if (cols && show) {
                //$('' + obj.target.getAttribute('data-target')).toggle();
            } else {
                setTimeout(function () {
                    menuShowUp();
                }, 400);
            }
        });
    }


    handleClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.preventDefault();
        //$(e.target).attr('aria-expanded') === 'true'
        //let target = e.target;
        //if ($(target).hasClass('active')) {//e.currentTarget.attributes['aria-expanded'].va;//.getNamedItem('aria-expanded')?.value;
        //    $(e.target).attr('aria-expanded', 'false');
        //    //    $(`${$(e.target).attr('href')}`).tong('show');
        //    $(`${$(target).attr('href')}`).fadeOut();
        //    $(target).removeClass('active');
        //    console.log(`${$(e.target).attr('href')}`);
        //}        
        //console.log(attr);
        //console.log('The link was clicked.');
    };

    renderMenuType(user: any) {
        if (Auth.IsProjectAdmin(user)) {
            return (
                <div className="bg-dark border-right" id="sidebar-wrapper">
                    <div className="list-group list-group-flush list-group-menu">
                        <div className="accordion" id="accordion_menu">

                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link to="/admin/" className="nav-link"><i className="fa fa-home" aria-hidden="true"></i> Home</Link>
                                    <Link to="/admin/projects" className="nav-link"> Project data</Link>
                                    <Link to="/admin/projectuse" className="nav-link"> Project using</Link>
                                    <Link to="/admin/projreport" className="nav-link"> Reports</Link>
                                    <Link to="/logout" onClick={(e) => { if (window.confirm('คุณต้องการออกจากระบบ ใช่หรือไม่?')) { return true } else { e.preventDefault(); return false } }} className="nav-link"><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</Link>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            );
        } else if (Auth.IsPromoAdmin(user)) {
            return (
                <div className="bg-dark border-right" id="sidebar-wrapper">
                    <div className="list-group list-group-flush list-group-menu">
                        <div className="accordion" id="accordion_menu">

                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link to="/admin/" className="nav-link"><i className="fa fa-home" aria-hidden="true"></i> Home</Link>
                                    <Link to="/admin/promos" className="nav-link">Promo data</Link>
                                    <Link to="/admin/promocodes" className="nav-link">Promocodes</Link>
                                    <Link to="/logout" onClick={(e) => { if (window.confirm('คุณต้องการออกจากระบบ ใช่หรือไม่?')) { return true } else { e.preventDefault(); return false } }} className="nav-link"><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            );

        } else
            return (
                <div className="bg-dark border-right" id="sidebar-wrapper">
                    <div className="list-group list-group-flush list-group-menu">
                        <div className="accordion" id="accordion_menu">

                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link to="/admin/" className="nav-link"><i className="fa fa-lastfm" aria-hidden="true"></i> No menus</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/logout" onClick={(e) => { if (window.confirm('คุณต้องการออกจากระบบ ใช่หรือไม่?')) { return true } else { e.preventDefault(); return false } }} className="nav-link"><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</Link>
                                </li>
                            </ul>                            
                        </div>
                    </div>
                </div>
            );
    }
    renderMenuAdmin() {
        return (
            <div className="bg-dark border-right" id="sidebar-wrapper">
                <div className="list-group list-group-flush list-group-menu">
                    <div className="accordion" id="accordion_menu">
                        <div className="">
                            <div className="nav" id="heading_project">
                                <span className="nav-link width-100 collapsed text-left" data-toggle="collapse" data-target="#project_nav" aria-expanded="false" aria-controls="project_nav">
                                    Projects
                                </span>
                            </div>
                            <div id="project_nav" className="collapse" aria-labelledby="heading_project" data-parent="#accordion_menu">
                                <div className="nav-body">
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <Link to="/admin/projects" className="nav-link active"><i className="fa fa-arrow-circle-o-right"></i> Project data</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/projectuse" className="nav-link active"><i className="fa fa-arrow-circle-o-right"></i> Project using</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/projreport" className="nav-link active"><i className="fa fa-arrow-circle-o-right"></i> Reports</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="nav" id="heading_promos">
                                <span className="nav-link width-100 collapsed text-left" data-toggle="collapse" data-target="#promos_nav" aria-expanded="false" aria-controls="promos_nav">
                                    Promos
                                </span>
                            </div>
                            <div id="promos_nav" className="collapse" aria-labelledby="heading_promos" data-parent="#accordion_menu">
                                <div className="nav-body">
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <Link to="/admin/promos" className="nav-link active"><i className="fa fa-arrow-circle-o-right"></i> Promo data</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/promocodes" className="nav-link active"><i className="fa fa-arrow-circle-o-right"></i> Promocodes</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <Link to="/admin/package" className="nav-link">Packages</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/admin/consent/form" className="nav-link">Consent</Link>
                            </li>
                        </ul>

                        <div className="">
                            <div className="nav" id="heading_company">
                                <span className="nav-link width-100 collapsed text-left" data-toggle="collapse" data-target="#company_nav" aria-expanded="false" aria-controls="company_nav">
                                    Organization
                                </span>
                            </div>
                            <div id="company_nav" className="collapse" aria-labelledby="heading_company" data-parent="#accordion_menu">
                                <div className="nav-body">
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <Link to="/admin/company" className="nav-link active"><i className="fa fa-arrow-circle-o-right"></i> Organization</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/department" className="nav-link active"><i className="fa fa-arrow-circle-o-right"></i> Department</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/position" className="nav-link active"><i className="fa fa-arrow-circle-o-right"></i> Position</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="nav" id="heading_country">
                                <span className="nav-link width-100 collapsed text-left" data-toggle="collapse" data-target="#country_nav" aria-expanded="false" aria-controls="country_nav">
                                    Places
                                </span>
                            </div>
                            <div id="country_nav" className="collapse" aria-labelledby="heading_country" data-parent="#accordion_menu">
                                <div className="nav-body">
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <Link to="/admin/country" className="nav-link active"><i className="fa fa-arrow-circle-o-right"></i> Country</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/province" className="nav-link active"><i className="fa fa-arrow-circle-o-right"></i> Province</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/district" className="nav-link active"><i className="fa fa-arrow-circle-o-right"></i> District</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="nav" id="heading_description">
                                <span className="nav-link width-100 collapsed text-left" data-toggle="collapse" data-target="#description_nav" aria-expanded="false" aria-controls="description_nav">
                                    Description
                                    </span>
                            </div>
                            <div id="description_nav" className="collapse" aria-labelledby="heading_description" data-parent="#accordion_menu">
                                <div className="nav-body">
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <Link to="/admin/mainelement" className="nav-link active">1. Main elements</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/behavior18y" className="nav-link active">2. Behavior before 18y</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/relationparents" className="nav-link active">3. Relationship with parents</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/workingothers" className="nav-link active">4. Working with others</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/workingalone" className="nav-link active">5. Working alone</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/specsoulmaid" className="nav-link active">6. Specification of soulmate</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/perceptionre" className="nav-link active">7. Perception own sibling</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/fastenmodel" className="nav-link active">8. Fasten Model</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/recommend" className="nav-link active">9. Recommendation</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/ranking" className="nav-link active">10. Ranking</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <li className="nav-item">
                            <Link to="/admin/optionalfield/form" className="nav-link">Optional field</Link>
                        </li>
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <Link to="/admin/figures" className="nav-link"><i className="fa fa-lastfm" aria-hidden="true"></i> Figures</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/admin/persondata" className="nav-link"><i className="fa fa-users" aria-hidden="true"></i> Person data</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/logout" onClick={(e) => { if (window.confirm('คุณต้องการออกจากระบบ ใช่หรือไม่?')) { return true } else { e.preventDefault(); return false } }} className="nav-link"><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
    renderMenu(user: any) {
        if (Auth.IsAdmin(user)) return this.renderMenuAdmin();
        else {
            return this.renderMenuType(user);
        }
    }

    public render() {

        return this.renderMenu(this.state.user);
    }
}
