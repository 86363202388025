import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as ActionsAdmin from '../components/Common/ActionAdmin';
import { Project_ModelToForm } from '../components/Common/DataManager';
import Utils from '../components/Common/Utils';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ProjectState {
    isLoading: boolean;
    xIndex?: string;
    data: any;
}

interface RequestProjectAction {
    type: 'REQUEST_PROJECT_DATA';
    xIndex: string;
}

interface ReceiveProjectAction {
    type: 'RECEIVE_PROJECT_DATA';
    xIndex: string;
    data: any;
}
interface UpdateProjectAction {
    type: 'UPDATE_PROJECT_DATA';
    xIndex: string;
    data: any;
}

type KnownAction = RequestProjectAction | ReceiveProjectAction | UpdateProjectAction;

export const actionCreators = {
    requestData: (xIndex: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.project && xIndex !== appState.project.xIndex) {
            fetch(`/api/${ActionsAdmin.Models.Project}/GetAsync?id=${xIndex}`, {
                method: 'GET',
                headers: Utils.headerXRequest()
            })
                .then(response => response.json() as Promise<any>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_PROJECT_DATA', xIndex: xIndex, data: Project_ModelToForm(data.data ? data.data : null) });
                });

            dispatch({ type: 'REQUEST_PROJECT_DATA', xIndex: xIndex });
        }
    }
};

const unloadedState: ProjectState = { data: {}, isLoading: false };

export const reducer: Reducer<ProjectState> = (state: ProjectState | undefined, incomingAction: Action): ProjectState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'UPDATE_PROJECT_DATA':
            return {
                xIndex: action.xIndex,
                data: action.data,
                isLoading: false
            };   
        case 'REQUEST_PROJECT_DATA':
            return {
                xIndex: action.xIndex,
                data: state.data,
                isLoading: true
            };
        case 'RECEIVE_PROJECT_DATA':
            if (action.xIndex === state.xIndex) {
                return {
                    xIndex: action.xIndex,
                    data: action.data,
                    isLoading: false
                };
            }
              
            break;
    }

    return state;
};
