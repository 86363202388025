import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from '../Common/Actions';
import $ from 'jquery';
import { Field, reduxForm, change, onChange,submit,getFormValues } from 'redux-form';
import Loading from '../Common/Loading';
import Utils from '../Common/Utils';
import '../../templates/common/radio_btn.scss';
import Auth from '../Common/Auth';
import * as DataManager from '../Common/DataManager';
import HtmlElement, { renderDescriptionQA} from '../Common/HtmlElement';

class Step2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: Auth.GetUser(),
            loading: true, values: props.initialValues, xdata: false, GuestData: props.GuestData
        }
        this.goBack = this.goBack.bind(this);
        this.cancelProc = this.cancelProc.bind(this);
    }
    goBack(e) {
        this.props.onGoBack(1);
    }

    cancelProc(){
        this.props.onCancelProc(1);
    }

    componentDidMount() {
        //if (this.state.GuestData.data_Model && this.state.GuestData.data_Model.id && this.state.GuestData.data_Model.verifications &&
        //    Utils.Get_StoreDOB() === (this.state.GuestData.Person_Info ? this.state.GuestData.Person_Info.dob : this.state.GuestData.person_Info.dob)) {
        //    this.setState({
        //        loading: false
        //    });
        //} else {
            let rdata = this.state.GuestData;
            if (!rdata.Person_Info && rdata.person_Info)
                rdata['Person_Info'] = rdata.person_Info;
            if (!rdata.Person_Info['Birth_Place'])
                rdata.Person_Info['Birth_Place'] = rdata.Person_Info['birth_Place'];

            if (rdata.Person_Info.Birth_Place &&rdata.Person_Info.Birth_Place.country && rdata.Person_Info.Birth_Place.country.label) {
                rdata.Person_Info['Birth_Place']['country_name'] = rdata.Person_Info.Birth_Place.country.label;
                rdata.Person_Info['Birth_Place']['country_code'] = rdata.Person_Info.Birth_Place.country.value;
                rdata.Person_Info['Birth_Place']['country']['code'] = rdata.Person_Info.Birth_Place.country.value;
                
            } else if (rdata.Person_Info.birth_Place) {
                rdata.Person_Info['Birth_Place']['country_name'] = rdata.Person_Info['birth_Place'].country_name;
                rdata.Person_Info['Birth_Place']['country_code'] = rdata.Person_Info['birth_Place'].country_code;                
                if (rdata.Person_Info['Birth_Place']['country'])
                    rdata.Person_Info['Birth_Place']['country']['code'] = rdata.Person_Info['birth_Place'].country_code;
                else
                    rdata.Person_Info['Birth_Place'] = { country: { code: rdata.Person_Info['birth_Place'].country_code} }
            }

            if (rdata.Person_Info.Birth_Place && rdata.Person_Info.Birth_Place.province && rdata.Person_Info.Birth_Place.province.label) {
                rdata.Person_Info['Birth_Place']['province_name'] = rdata.Person_Info.Birth_Place.province.label;
                rdata.Person_Info['Birth_Place']['province_id'] = rdata.Person_Info.Birth_Place.province.value;
            } else if (rdata.Person_Info.birth_Place){
                rdata.Person_Info['Birth_Place']['province_name'] = rdata.Person_Info['birth_Place'].province_name;
                rdata.Person_Info['Birth_Place']['province_id'] = rdata.Person_Info['birth_Place'].province_id;
            }
            

            if (rdata.Person_Info.Birth_Place && rdata.Person_Info.Birth_Place.district && rdata.Person_Info.Birth_Place.district.value) {
                rdata.Person_Info['Birth_Place']['district_name'] = rdata.Person_Info.Birth_Place.district.label;
                rdata.Person_Info['Birth_Place']['district_id'] = rdata.Person_Info.Birth_Place.district.value;
            } else if (rdata.Person_Info.birth_Place && rdata.Person_Info.birth_Place.district_id) {
                rdata.Person_Info['Birth_Place']['district_name'] = rdata.Person_Info['birth_Place'].district_name;
                rdata.Person_Info['Birth_Place']['district_id'] = rdata.Person_Info['birth_Place'].district_id;
        }

        if (rdata.Person_Info.Birth_Place) {
            delete rdata.Person_Info.Birth_Place;
        }
        if (rdata.Users && rdata.Users.packageInfo && rdata.Users.packageInfo.promo_Code) {
            rdata.Users.packageInfo.promo_Code.person_Info = null;
            rdata.Users.packageInfo.promo_Code.projectInfo = null;
        }
        if (rdata.Users && rdata.Users.dataModel) {
            if (rdata.Users.dataModel.person_Info)
                delete rdata.Users.dataModel.person_Info;
            if (rdata.Users.dataModel.person_Info)
                delete rdata.Users.dataModel.person_Info;
            if (rdata.Users.dataModel.project_Info && rdata.Users.dataModel.project_Info.data_Model)
                delete rdata.Users.dataModel.project_Info.data_Model;
            if (rdata.Users.dataModel.promo_Code && rdata.Users.dataModel.promo_Code.person_Info)
                delete rdata.Users.dataModel.promo_Code.person_Info;
            if (rdata.Users.dataModel.promo_Code && rdata.Users.dataModel.promo_Code.projectInfo)
                delete rdata.Users.dataModel.promo_Code.projectInfo;
        }


        if (rdata.Person_Info && rdata.Person_Info.promo_Code && rdata.Person_Info.promo_Code.data_Model) {
            rdata.Person_Info.promo_Code.data_Model = null;
            rdata.Person_Info.promo_Code.projectInfo = null;
        }
        if (rdata.user) {
            delete rdata.user;
        }
        if (rdata.users) {
            delete rdata.users;
        }

        var uid = sessionStorage.getItem('personxi');
        if (DataManager.checkNotNull(uid) && !DataManager.isGuidEmpty(uid)) {
            Actions.Get_Step(uid, 1).then(xdata => {
                if (xdata.status === 200) {
                    this.setState({
                        xdata: xdata.data,
                        GuestData: xdata.data
                    });

                    this.props.onStepTwo(xdata.data);
                    if (this.state.user && this.state.user.packageInfo.packageConfig && this.state.user.packageInfo.packageConfig.show_verification === false) {
                        let verx = xdata.data.data_Model.verifications ? xdata.data.data_Model.verifications : [];
                        let vales = {};
                        for (var i in verx) {
                            vales[verx[i].id] = 'Skip';
                        }
                        this.props.onSubmit({ verify: vales });
                    }
                }
            }).then((x) => this.setState({ loading: false }));
        } else {
            Actions.Get_Verification(rdata).then(data => {
                if (data.status === 200) {
                    this.setState({
                        xdata: data.data,
                        loading: false,
                        GuestData: data.data
                    });
                    this.props.onStepThree(data.data);
                    if (this.state.user && this.state.user.packageInfo.packageConfig && this.state.user.packageInfo.packageConfig.show_verification === false) {
                        let verx = this.state.GuestData.data_Model.verifications ? this.state.GuestData.data_Model.verifications : [];
                        let vales = {};
                        for (var i in verx) {
                            vales[verx[i].id] = 'Skip';
                        }
                        this.props.onSubmit({ verify: vales });
                    }
                }
            }).then((x) => this.setState({ loading: false }));
        }

        
        //}
    }

    isUnknowTime(data) {
        if (typeof this.state.values.unknow_time != 'undefined')
            return this.state.values.unknow_time;
        else if (this.state.values.person_Info && typeof this.state.values.person_Info.unknow_time != 'undefined')
            return this.state.values.person_Info.unknow_time;
        else if (this.state.values.Person_Info && typeof this.state.values.Person_Info.unknow_time != 'undefined')
            return this.state.values.Person_Info.unknow_time;
        else if (this.state.GuestData && this.state.GuestData.person_Info && typeof this.state.GuestData.person_Info.unknow_time != 'undefined')
            return this.state.GuestData.person_Info.unknow_time;
        else if (this.state.GuestData && this.state.GuestData.Person_Info && typeof this.state.GuestData.Person_Info.unknow_time != 'undefined')
            return this.state.GuestData.Person_Info.unknow_time;
        else
            return false;
    }
    isUnknowPlace() {
        if (typeof this.state.values.unknow_place != 'undefined')
            return this.state.values.unknow_place;
        else if (this.state.values.person_Info && typeof this.state.values.person_Info.unknow_place != 'undefined')
            return this.state.values.person_Info.unknow_place;
        else if (this.state.values.Person_Info && typeof this.state.values.Person_Info.unknow_place != 'undefined')
            return this.state.values.Person_Info.unknow_place;
        else if (this.state.GuestData && this.state.GuestData.person_Info && typeof this.state.GuestData.person_Info.unknow_place != 'undefined')
            return this.state.GuestData.person_Info.unknow_place;
        else if (this.state.GuestData && this.state.GuestData.Person_Info && typeof this.state.GuestData.Person_Info.unknow_place != 'undefined')
            return this.state.GuestData.Person_Info.unknow_place;
        else
            return false;
    }
    renderPlace(state) {
        if (this.isUnknowPlace())
            return <> -</>;
        else
            return <>{this.state.values.district && this.state.values.district.value ? this.state.values.district.label + ', ' : ''} {this.state.values.province.label}, { this.state.values.country.label}</>;
    }
    //<div><b>{data.sequence_no}) {data.title}{i === 0 ? `: ${this.state.GuestData.data_Model.forecast.du}` : '-'}</b></div>
    //<div className="padding-bottom-8">{data.verification_Model ? data.verification_Model.description_th : '-'} ?</div>

    renderVerific() {
        let show_last = true;
        if (this.isUnknowPlace() || this.isUnknowTime())
            show_last = false;
        if (!this.state.loading && this.state.GuestData.data_Model && this.state.GuestData.data_Model.id && this.state.GuestData.data_Model.verifications) {
            return (<ul className="list-group">
                {this.state.GuestData.data_Model.verifications.map((data, i) => {
                    //console.log('verifications---');
                    //console.log(data);
                    let named = { y: 'rdo_yes_' + data.id, n: 'rdo_no_' + data.id, u: 'rdo_unknow_' + data.id};
                    if (!show_last && data.sequence_no === 7) return <></>;
                    // return (<li key={data.id} className="list-group-item hidden">
                    //    <Field component="input" type="radio" className="hidden" name={`verify[${data.id}]`} value="Skip" id={named.u} />
                    //</li>);                    
                    return <li key={data.id} className="list-group-item">
                        <div>{data.sequence_no}) {data.verification_Model ? renderDescriptionQA(data.verification_Model) : '-'}</div>
                            <div className="padding-bottom-8"></div>
                            <div className="radio-toggle">
                            <Field component="input" type="radio" name={`verify[${data.id}]`} value="Yes" id={named.y} />
                                <label htmlFor={named.y}><i className="fa fa-check"></i> ใช่ </label>
                                <Field component="input" type="radio" name={`verify[${data.id}]`} value="No" id={named.n} />
                                <label htmlFor={named.n}><i className="fa fa-times"></i> ไม่ใช่</label>
                                <Field component="input" type="radio" name={`verify[${data.id}]`} value="Unknow" id={named.u} />
                                <label htmlFor={named.u}>ไม่ทราบ</label>
                            </div>
                        </li>
                })}
            </ul>);
        } else if (this.state.loading)
            return (<ul className="list-group"><li className="list-group-item">Looking for data...</li></ul>);
        else
            return (<ul className="list-group"><li className="list-group-item">ไม่พบข้อมูลคำถาม</li></ul>);
    }

    render() {
        const { handleSubmit, onChange } = this.props;    
        return (
            <form className="form-horizontal" onSubmit={handleSubmit} >
                <div className="card-body basic-txt"><Loading loading={this.state.loading} />
                    <div className="header-info text-center padding-5 hidden">คำบรรยายเพื่อตรวจสอบตัวตน</div>
                    <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm"><b>นามสมมุติ/Assumed Name:</b> {this.state.values.first_name} {DataManager.checkNotNull(this.state.values.last_name) ? <>({this.state.values.last_name})</> : ''}</div>
                        <div className="col-sm first-upper"><b>เพศ/Gender:</b> <span className="first-upper">{this.state.values.gender}</span></div>                                           
                        <div className="col-sm-1"></div>
                    </div>
                    <div className="padding-2"></div>
                    <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm"><b>วว/ดด/ปปปป เกิด, DD/MM/YYYY of Birth:</b> {this.state.values.date_of_birth} {this.isUnknowTime() ? 'xx:xx' :this.state.values.birth_time}</div>
                        <div className="col-sm"><b>สถานที่เกิด/Place of Birth:</b> {this.renderPlace(this.state)}</div>
                        <div className="col-sm-1"></div>
                    </div>
                    <div className="padding-2"></div>
                    <div className="text-info font-size-15">
                        ต่อไปนี้เป็นบทวิเคราะห์เบื้องต้น เพื่อให้คุณแน่ใจว่าสะท้อนความเป็นตัวคุณเพียงพอ กรณีตอบทีละข้อ<br />
                        Below is the preliminary analysis for you to verify if it fairly reflects who you are.  Please evaluate one by one.
</div>
                    <div className="padding-2"></div>
                    <div className="row">
                        <div className="col-12">
                            {this.renderVerific()}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="card-body">
                        <div className="text-info padding-bottom-10">
                            <i>“หากโดยรวมคำบรรยายข้างต้นไม่ใกล้เคียงกับความเป็นจริงในระดับที่ท่านพึงพอใจ ควรแก้ไขข้อมูลเกิด หรือยกเลิกรายการ เนื่องจากผลวิเคราะห์ <b>FASTEn Model™</b> ในลำดับต่อไปจะมีความน่าเชื่อถือต่ำเช่นกัน”</i>
                        </div>                        
                        <div className="text-info padding-bottom-10">
                            <i>“If the above analysis as an overall is not satisfactory, it is recommended to correct your birth information or cancel this transaction as the analysis on your <b>FASTEn Model™</b> in the next step will be less reliable as well”.</i>
                        </div>
                        <div className="text-danger padding-bottom-10">
                            *<b>หมายเหตุ:</b> หากกด Next ท่านจะไม่สามารถแก้ไขข้อมูลของท่านได้อีก<br />
                            <span className="ml-2"></span><b>Remark:</b> Once press "NEXT", you will be not be able to revise data again.
                        </div>
                        <div className="form-group btn-center text-center">
                            <span className="margin-5">
                                <button type="button" onClick={this.goBack} className="btn btn-dark btn-lg"><i className="fa fa-fast-backward text-secondary" aria-hidden="true"></i> Back </button>
                            </span>
                            <span className="margin-5 hidden">
                                <button type="button" onClick={this.cancelProc} className="btn btn-secondary btn-lg"><i className="fa fa-undo text-danger"></i> ยกเลิก</button>
                            </span>
                            <span className="margin-5">
                                <button type="submit" className="btn btn-dark btn-lg"> Next <i className="fa fa-check text-success" aria-hidden="true"></i></button>
                            </span>
                        </div>
                    </div>
                </div>
            </form>
                );
    }
}


Step2 = reduxForm({
    form: 'Step_3'
})(Step2);
Step2 = connect(
   (state, props) => ({
       state: { values: props.values, GuestData: props.GuestData},
       initialValues: props && props.values ? props.values : { start: true },
       GuestData: props.GuestData
   })
)(Step2);
export default Step2;