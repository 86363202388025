import React, { Component } from 'react';
import { connect } from 'react-redux';
import Auth from './Common/Auth';
import Loading from './Common/Loading';

class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.setState({ loading: true });
        Auth.Logout();
        window.location.href = '/';
    }

    render(){
        return(
            <div className="container"><Loading loading={this.state.loading} />
                    <div className="row">      
                        <div className="col-sm-12">
                            <div className="text-center">
                                <h1 className="err-text text-secondary">&nbsp;</h1>
                                <h2 className="err-text">Loggin out, please wait for a while...</h2>
                                <div className="space-btm"></div>
                            </div>
                        </div>
                    </div>
            </div>
        );
        }
    }

export default connect()(Logout);
