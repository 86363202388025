import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Field, reduxForm, change, onChange,submit } from 'redux-form';
import { connect } from 'react-redux';
import Utils from '../Common/Utils';
import * as Actions from '../Common/Actions';
import * as HtmlElement from '../Common/HtmlElement';
import Inputmask from "inputmask";
import Auth from '../Common/Auth';
import { select_none } from '../Common/DataManager';
let country = '';
let province = 0;
let district = null;

class Step1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: props.values, ctvalue: 'THA', user: Auth.GetUser(),
            loading: true, province_selection: [], district_selection: [], optional_fields: false,
        }     
        this.current_country = false;
        this.start = true;
        this.event_count = 0;
        this.selectedCountry = (e, val) => {
            //console.log(val);
            this.current_country = val.value;
            this.loadProvince(val.value);
        }
        this.selectedProvince = (e, val) => {
            this.loadDistrict(val.value);

        }
        this.selectedDistrict = (e, val) => {
            district = val;
        }
        this.selectedDepartment = (e, val) => {
           
        }
        this.selectedPosition = (e, val) => {

        }
        this.getDepartment = (input) => {
            return Actions.DropDown_List('Department', input,'r='+ this.state.user.packageInfo.company_Info.id);
        }
        this.getPosition = (input) => {
            return Actions.DropDown_List('Position', input, 'r='+this.state.user.packageInfo.company_Info.id);
        }


    }

    loadProvince(v) {
        if (v && !Utils.isEmptyOrSpaces(v)) {
            Actions.DropDown_Country('Province',v).then(xdata => {
                if (xdata.status === 200) {
                    this.setState({ province_selection: xdata.data });
                } else {
                    this.setState({ province_selection: [] });
                }
            }).then((xdata) => { });
        }
    }
    loadDistrict(v) {
        if (v) {
            Actions.DropDown_Country('District', v).then(xdata => {
                if (xdata.status === 200) {
                    if (this.current_country && this.current_country !== 'THA')
                        this.setState({ district_selection: [select_none, ...xdata.data] });
                    else
                        this.setState({ district_selection: xdata.data });
                } else {
                    this.setState({ district_selection: [] });
                }
            }).then((xdata) => { });
        }
    }

    getCountry(input) {
        //loadOptions={this.getCountry}
        return Actions.DropDown_List('Country',input);
    }
    getProvince(input) {
        return Actions.DropDown_List('Province', input,country);
    }
    getDistrict(input) {
        return Actions.DropDown_List('District', input,province);
    }

    setHH = (e,v) => {
        if (v && v.value)
            this.setState({ HH: v.value });
        else
            this.setState({ HH: 0 });
    }

    loadOptionalField() {
        Actions.Get_OptionalField().then(xdata => {
            if (xdata && xdata.status === 200 && xdata.data) {
                let fields = HtmlElement.OptionalFields(xdata);
                if (fields && fields.length > 0)
                    this.setState({ optional_fields: <>{fields}</> });
            }
        });
    }

    renderRefcode(state) {
        if ((state.user && state.user.packageInfo) && (state.user.packageInfo.projectInfo && state.user.packageInfo.projectInfo) && (state.user.packageInfo.projectInfo.project_type === 'Project')) {
            return "form-group row required";
        } else
            return "form-group row";
    }
                                     
    renderCompanyInput(state) {
        if ((state.user && state.user.packageInfo) && (state.user.packageInfo.projectInfo && state.user.packageInfo.projectInfo) && (state.user.packageInfo.projectInfo.project_type === 'Project')
            && (state.user && state.user.packageInfo.company_Info)
        ) {
            return <>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group row required">
                            <label className="col-sm-3 text-right control-label col-form-label">แผนก:<small class="form-text text-separate text-muted">Department</small></label>
                            <div className="col-sm-9">
                                <span className="width-100">
                                    <Field id="select-department" name="department" className="custom-select" multi={false} required={true}
                                        onChange={this.selectedDepartment} component={HtmlElement.RenderSelectAsync} defaultOptions 
                                        multi={false} loadOptions={this.getDepartment} autoLoad={true}/>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group row required">
                            <label className="col-sm-3 text-right control-label col-form-label">ตำแหน่ง:<small class="form-text text-separate text-muted">Position</small></label>
                            <div className="col-sm-9">
                                <Field id="select-position" name="position" className="custom-select" multi={false} required={true}
                                    onChange={this.selectedPosition} component={HtmlElement.RenderSelectAsync} defaultOptions
                                    multi={false} loadOptions={this.getPosition} autoLoad={true}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>;
        }
        return <></>;
    }

    componentDidMount() {
        this.loadOptionalField();
        //const xIndex = this.props.xIndex || 0;     
        Inputmask("99/99/9999").mask(document.getElementsByClassName("imask-date"));
        Inputmask("99:99").mask(document.getElementsByClassName("imask-time"));
        //this.loadProvince('THA');
    }

    componentWillReceiveProps(nextProps) {
        if (this.start && nextProps.initialValues && nextProps.initialValues.country) {
            this.start = false;
                this.current_country = nextProps.initialValues.country.value;
                this.loadProvince(nextProps.initialValues.country.value);
                if (nextProps.initialValues && nextProps.initialValues.province && nextProps.initialValues.province.value)
                    this.loadDistrict(nextProps.initialValues.province.value);
            
        }
        this.event_count++;

    }

    renderCheckboxDate() {
        if (this.state.user && this.state.user.packageInfo.packageConfig && ((this.state.user.packageInfo.packageConfig.show_birthchart === true && this.state.user.packageInfo.packageConfig.show_elements != 8) || this.state.user.packageInfo.packageConfig.show_birthchart === false)) {
            return (
                <div className="form-group row">
                    <span className="col-sm-4 text-right control-label col-form-label"></span>
                    <div className="col-sm-8">
                        <Field name="unknow_time" component="input" type="checkbox" className="form-check-input" id="unknow_time" />
                        <label class="form-check-label" for="unknow_time">ไม่ทราบเวลาเกิด/Do not know time of birth</label>
                    </div>
                </div>
            );
        } else
            return <></>;
        /*
         <div className="form-group row">
                    <span className="col-sm-4 text-right control-label col-form-label"></span>
                    <div className="col-sm-6">
                        <div className="row">
                            <div className="col-sm">
                                <Field name="unknow_date" component="input" type="checkbox" className="form-check-input" id="unknow_date" />
                                <label class="form-check-label" for="unknow_date">ไม่ทราบวันเกิด</label>
                            </div>
                            <div className="col-sm">
                                <Field name="unknow_time" component="input" type="checkbox" className="form-check-input" id="unknow_time" />
                                <label class="form-check-label" for="unknow_time">ไม่ทราบเวลาเกิด</label>
                            </div>
                        </div>
                    </div>
                </div>
         */
    }
    renderCheckboxPlace() {
        if (this.state.user && this.state.user.packageInfo.packageConfig && ((this.state.user.packageInfo.packageConfig.show_birthchart === true && this.state.user.packageInfo.packageConfig.show_elements != 8) || this.state.user.packageInfo.packageConfig.show_birthchart === false)) {
            return (
                <div className="row">
                    <div className="col-sm-8">
                        <div className="form-group row">
                            <label className="col-lg-2 text-right control-label col-form-label"></label>
                            <div className="col-lg-10">
                                <Field name="unknow_place" component="input" type="checkbox" className="form-check-input" id="unknow_place" />
                                <label class="form-check-label" for="unknow_place">ไม่ทราบสถานที่เกิด/Do not know place of birth</label>
                            </div>
                        </div>
                    </div>
                </div>

            );
        } else
            return <></>;
    }
    render() {
        const { handleSubmit, onChange } = this.props;
        return (
            <form className="form-horizontal" onSubmit={handleSubmit} >
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group row required">
                                <label className="col-sm-3 text-right control-label col-form-label">นามสมมุติ:<small class="form-text text-separate text-muted">Short Name</small></label>                                
                                <div className="col-sm-9">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="input-name"><i className="fa fa-user-o"></i></span>
                                        </div>
                                        <Field className="form-control" name="first_name" placeholder="นามสมมุติ/Short Name..." component="input" type="text" aria-describedby="input-name" />
                                    </div>
                                </div>
                            </div>
                            <div className={this.renderRefcode(this.state)}>
                                <label className="col-sm-3 text-right control-label col-form-label">โค้ดอ้างอิง:<small class="form-text text-separate text-muted">Reference Code</small></label>
                                <div className="col-sm-9">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="input-lastname"><i className="fa fa-user"></i></span>
                                        </div>
                                        <Field className="form-control" name="last_name" placeholder="โค้ดอ้างอิง/Reference Code..." component="input" type="text" aria-describedby="input-lastname"/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row required">
                                <label className="col-sm-3 text-right control-label col-form-label">เพศ:<small class="form-text text-separate text-muted">Gender</small></label>
                                <div className="col-sm-9">
                                        <label className="label-chkbox" htmlFor="male">
                                            <Field type="radio" className="option-x-input xradio" component="input" name="gender" id="male"  value="male" required=""/>
                                        <span><i className="fa fa-male text-secondary"></i> ชาย<small class="form-text text-separate-option text-secondary">Male</small></span>
                                        </label>
                                        <span className="padding-lr-30">&nbsp;</span>
                                        <label className="label-chkbox" htmlFor="female">
                                        <Field type="radio" className="option-x-input xradio" component="input" name="gender" id="female" value="female" required=""/>
                                        <span><i className="fa fa-female text-secondary"></i> หญิง<small class="form-text text-separate-option text-secondary">Female</small></span>
                                        </label>
                                </div>
                            </div>         
                        </div>
                        <div className="col-md-6">
                            <div className="form-group row required">
                                <label className="col-sm-4 text-right control-label col-form-label">วว/ดด/ปปปป เกิด:<small class="form-text text-separate text-muted">DD/MM/YYYY of Birth</small></label>
                                <div className="col-sm-8">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="input-date"><i className="fa fa-calendar"></i></span>
                                        </div>
                                        <Field className="form-control imask-date" name="date_of_birth" placeholder="00/00/0000" component="input" type="text" aria-describedby="input-date"/>
                                    </div>
                                </div>
                            </div>
                            {this.renderCheckboxDate()}
                            <div className="form-group row required">
                                <label className="col-sm-4 text-right control-label col-form-label">เวลาเกิด:<small class="form-text text-separate text-muted">Time of Birth</small></label>
                                <div className="col-sm-8">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="input-time" htmlFor="select-time"><i className="fa fa-clock-o"></i></span>
                                        </div>
                                        <Field className="form-control imask-time" name="birth_time" placeholder="00:00" component="input" type="text" aria-describedby="input-time"/>
                                    </div>        
                                    <small id="birth_time_help" className="form-text text-muted">Time format 00:00 - 23.59</small>    
                                    <span className="col-sm-5 text-right col-form-label"></span>                                  
                                </div>
                            </div>                                                   
                        </div>
                    </div>
                    {this.renderCompanyInput(this.state)}                    
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="form-group row margin-0">
                                <label className="col-lg-4 text-right control-label col-form-label"></label>
                                <div className="col-lg-8">
                                    <span className="text-secondary">สถานที่เกิด/Place of Birth:<span className="text-required">*</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderCheckboxPlace()}
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="form-group row">
                                <label className="col-lg-4 text-right control-label col-form-label">ประเทศ<small class="form-text text-separate text-muted">Country</small></label>
                                <div className="col-lg-8">
                                    <span className="width-100">
                                        <Field id="select-country" name="country" className="custom-select" multi={false} required={true}
                                            onChange={this.selectedCountry} multi={false} loadOptions={this.getCountry} defaultOptions
                                            component={HtmlElement.RenderSelectAsync} aria-describedby="input-country"/>
                                    </span>
                                </div>
                            </div>                                                 
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group row">
                                <label className="col-lg-4 text-right control-label col-form-label">จังหวัด<small class="form-text text-separate text-muted">City or Province</small></label>
                                <div className="col-lg-8">
                                    <span className="width-100">
                                        <Field id="select-province" name="province" className="custom-province" multi={false} required={true}
                                            onChange={this.selectedProvince} multi={false} options={this.state.province_selection}
                                            component={HtmlElement.RenderSelectInput} aria-describedby="input-province" />
                                    </span>
                                </div>
                            </div>                                                 
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group row">
                                <label className="col-lg-4 text-right control-label col-form-label">อำเภอ</label>
                                <div className="col-lg-8">
                                    <Field id="select-province" name="district" className="custom-district" multi={false} required={true}
                                        onChange={this.selectedDistrict} multi={false} options={this.state.district_selection}
                                        component={HtmlElement.RenderSelectInput} aria-describedby="input-district" />
                                </div>
                            </div>                                          
                        </div>
                    </div>
                    {this.state.optional_fields}
                </div>
                <div className="border-top">
                    <div className="card-body">
                        <div className="form-group btn-center text-center">
                            <span className="padding-left-15"><button type="submit" className="btn btn-dark btn-lg"><i className="fa fa-check text-success"></i> Next</button></span></div>
                    </div>
                </div>
            </form>
                );
    }
}

/*

<span className="width-100">
    <Field options={Utils.TimeSelection()} onChange={this.setHH} id="select-time" name="HH" className="custom-select" multi={false} required={true} 
                        component={HtmlElement.RenderSelectInput} aria-describedby="input-time"/>
</span>

*/

// Form = connect((state, props) => ({
//         state: state.plan,
//         initialValues: state.plan.xdata,
//         form: props.form,
//         mode:props.mode
// }),
Step1 = reduxForm({
    form: 'Step_1'
})(Step1);
Step1 = connect(
   (state, props) => ({
        state: props.values,
        initialValues: props && props.values ? props.values : { start: true, country: { value: 'THA', label: 'Thailand' } }
   })
)(Step1);

export default Step1;