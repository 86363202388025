import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Field, reduxForm, change, onChange, submit } from 'redux-form';
import { connect } from 'react-redux';
import $ from 'jquery';
import { ApplicationState } from '../../../store';

import * as ClientUserStore from '../../../store/ClientUser';
import Loading from '../../Common/Loading';
import Utils from '../../Common/Utils';
import * as Actions from '../../Common/Actions';
import * as HtmlElement from '../../Common/HtmlElement';
import Inputmask from "inputmask";
import Auth from '../../Common/Auth';
import * as ActionAdmin from '../../Common/ActionAdmin';
import * as DataManager from '../../Common/DataManager';

let country = '';
let province = 0;
let district = null;

class ProjectUsingForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: props.values, ctvalue: 'THA', user: Auth.GetUser(), xIndex: 0, package_config:false,
            country_selected: false, project_selection: [], position_selection: [], department_selection: [], package_config: [], country_selection:[],
            project_selected: false, position_selected: false, department_selected: false,optional_fields:false,
            loading: false, province_selection: [], district_selection: [], modal: { title: '', msg: '' },
            dfvalue: { label: this.props.val, value: this.props.val, name: this.props.val }
        }
        this.start = true;
        this.current_country = false;
        this.event_count = 0;
        this.selectedProject = (e, val) => {
            this.setState({ project_selected: val });
            this.getDepartmentByProject(val.value);
            this.getPositionByProject(val.value);
            this.getPackageConfig(val.value);
        }
        this.selectedDepartment = (e, val) => {
            this.setState({ department_selected: val });
        }
        this.selectedPosition = (e, val) => {
            this.setState({ position_selected: val });
        }
        this.selectedCountry = (e, val) => {
            this.current_country = val.value;
            this.setState({ dfvalue: val })
            this.loadProvince(val.value);            
        }
        this.selectedProvince = (e, val) => {
            this.loadDistrict(val.value);
            //if (this.current_country && this.current_country !== 'THA') {
            //    //this.refs.ref_district = {};
            //    //this.refs.ref_district.value = null;// DataManager.select_none;
            //}
            this.setState({ contry_selected: val.name });

        }
        this.selectedDistrict = (e, val) => {
            district = val;
        }
        this.loadProject= (val)=> {
            return Actions.DropDown_List(ActionAdmin.Models.Project, val).then(xdata => {
                return xdata;//this.setState({ project_selection: xdata, project_selected: false });
            });
        }
        this.getDepartment = (input) => {
            return Actions.DropDown_List('Department', input, 'r=' + (this.props.initialValues.users.packageInfo ? this.props.initialValues.users.packageInfo.company_Info.id : ''));
        }
        this.getPosition = (input) => {
            return Actions.DropDown_List('Position', input, 'r=' + (this.props.initialValues.users.packageInfo ? this.props.initialValues.users.packageInfo.company_Info.id : ''));
        }
        this.getDepartmentByProject = (val) => {
            Actions.DropDown_List(ActionAdmin.Models.Department + 'ByProject', null, 'r='+val).then(xdata => {
                this.setState({ department_selection: xdata, department_selected: false });
            });
        }
        this.getPositionByProject = (val) => {
            Actions.DropDown_List(ActionAdmin.Models.Position + 'ByProject', null, 'r=' +val).then(xdata => {
                this.setState({ position_selection: xdata, position_selected: false });
            });
        }
        this.getPackageConfig = (val) => {
            ActionAdmin.Get_PackageConfigByProject(val).then(xdata => {
                if (xdata && xdata.status === 200 && xdata.data) {
                    this.setState({ package_config: xdata.data });
                } else
                    this.setState({ package_config: false });
            });
        }
        //this.loadCountry();

        this.submit = (values) => {
            if (!values || !values.person_Info) {
                this.setState({ modal: { title: 'Warning', msg: 'กรุณาระบุข้อมูลให้ครบถ้วน' }});
                $(function () {
                    $(".modal").modal({ show: true });
                });
                return;
            }            
            if (!values.person_Info.id) {
                if (Auth.IsProjectAdmin(this.state.user) && this.state.user.packageInfo && this.state.user.packageInfo.projectInfo) {
                    values.person_Info['project'] = { value: this.state.user.packageInfo.projectInfo.id, label: this.state.user.packageInfo.projectInfo.name, xid: this.state.user.packageInfo.projectInfo.id };
                }
                let msg = [];
                if (!values.person_Info.project || !values.person_Info.project.value) {
                    msg.push(<li key="project-val" className="err-msg">กรุณาระบุ งาน/Project</li>);
                }
                if (!values.person_Info.department || !values.person_Info.department.value) {
                    msg.push(<li key="department-val" className="err-msg">กรุณาระบุฝ่าย/แผนก/สังกัด</li>);
                }
                if (!values.person_Info.position || !values.person_Info.position.value) {
                    msg.push(<li key="position-val" className="err-msg">กรุณาระบุตำแหน่งงาน</li>);
                }
                if (msg.length > 0) {
                    this.setState({ modal: { title: 'Warning', msg: <ul className="err-msg text-danger">{msg}</ul>}});
                    $(function () {
                        $(".modal").modal({ show: true });
                    });
                    return;
                }
            }

            let packageInfo = this.state.user.packageInfo ? this.state.user.packageInfo : null;
            if (!packageInfo && values.data_Model && values.users && values.users.packageInfo && values.users.packageInfo.projectInfo) {
                packageInfo = values.users.packageInfo;
            }
            
            //Actions.IsProject_Available(this.state.user.packageInfo.projectInfo.id).then(xdata => {
            //if (xdata && xdata.status === 200 && xdata && xdata.data.status) {
            const validate = Utils.step1validate(values.person_Info, values.person_Info.unknow_time, values.person_Info.unknow_place, true)//(packageInfo && packageInfo.projectInfo.project_type === 'Project') && packageInfo.company_Info);
            if (validate !== true) {
                this.setState({ modal: { title: 'Warning', msg: validate }, loading: false });
                $(function () {
                    $(".modal").modal({ show: true });
                });
                return;
            }

            let places = {
                country: { label: 'Thailand', value: 'THA', code: 'THA', name_en: 'Thailand' },
                province: { id: 10, label: "Bangkok", name: "THA", value: "10", name_en: "Bangkok" },
                district: { id: 1001, label: "Phra Nakhon", name: "10", value: "1001", name_en: "Phra Nakhon" }
            };

            if (values.person_Info.unknow_place && values.person_Info.unknow_place === true) {
                values.person_Info['country'] = places.country;
                values.person_Info['province'] = places.province;
                values.person_Info['district'] = places.district;
                values.person_Info['birth_Place'] = { country: places.country, province: places.province, district: places.district };
            } else if (values.person_Info.district) {
                values.person_Info['birth_Place'] = {
                    country: Object.assign({}, values.person_Info.country, { name_en: values.person_Info.country.label, code: values.person_Info.country.value }),
                    province: Object.assign({}, values.person_Info.province, { name_en: values.person_Info.province.label }),
                    district: Object.assign({}, values.person_Info.district, { name_en: values.person_Info.district.label })
                };
            } else
                values.person_Info['birth_Place'] = {
                    country: Object.assign({}, values.person_Info.country, { name_en: values.person_Info.country.label, code: values.person_Info.country.value }),
                    province: Object.assign({}, values.person_Info.province, { name_en: values.person_Info.province.label })
                };

            if (values.person_Info.unknow_time && values.person_Info.unknow_time === true)
                values.person_Info['birth_time'] = '01:00';
            values.person_Info['dob'] = Utils.InputToDateFormat(values.person_Info.date_of_birth, values.person_Info.birth_time);

            if (values.data_Model && values.data_Model.id) {
                values.data_Model = { id: values.data_Model.id};
                //values.data_Model['id'] = values.data_Model.id;
            }else
                values['users'] = this.state.user ? this.state.user : null;

            if (values.person_Info.company_Employee && !DataManager.isGuidEmpty(values.person_Info.company_Employee.id)) {
                if (values.person_Info.company_Employee.department && values.person_Info.department) {
                    values.person_Info.company_Employee.department['id'] = values.person_Info.department.value;
                    values.person_Info.company_Employee.department['name'] = values.person_Info.department.label;
                    values.person_Info.company_Employee.department_id = values.person_Info.department.value;
                }
                if (values.person_Info.company_Employee.position && values.person_Info.position) {
                    values.person_Info.company_Employee.position['id'] = values.person_Info.position.value;
                    values.person_Info.company_Employee.position['name'] = values.person_Info.position.label;
                    values.person_Info.company_Employee.position_id = values.person_Info.position.value;
                }                
                
            }
            if (values.person_Info.district && values.person_Info.district.value === null)
                values.person_Info.district = null;
            if (values.person_Info.birth_Place.district && values.person_Info.birth_Place.district.value === null)
                values.person_Info.birth_Place.district = null;
            //} else {
            //    this.setState({ modal: { title: 'Warning', msg: xdata.message ? xdata.message : xdata.Message } });
            //    $(function () {
            //        $(".modal").appendTo("body").modal({ show: true });
            //    });

            //this.setState({ loading: false });
            //});
            this.setState({ loading: true });
            ActionAdmin.Create_Guest(values).then(xdata => {
                if (xdata.status === 200) {
                    //this.setState({ modal: { title: '', msg: 'บันทึกข้อมูลสำเร็จ' } });
                    //$(".modal").modal({ show: true });
                    this.props.history.push('/admin/projectuse');
                    //$(".modal").modal({ show: false });
                } else {
                    this.setState({ modal: { title: 'Warning', msg: xdata.message ? xdata.message : xdata.Message }, loading: false  });
                    $(".modal").modal({ show: true });                    
                }
            }).then(x => {  });
        }
        this.loadProject();
    }

    loadOptionalField() {
        Actions.Get_OptionalField().then(xdata => {
            if (xdata && xdata.status === 200 && xdata.data) {
                let fields = HtmlElement.OptionalFields(xdata);
                if (fields && fields.length > 0)
                    this.setState({ optional_fields: <>{fields}</> });
            }
        });
    }


    loadProvince(v) {
        if (v && !Utils.isEmptyOrSpaces(v)) {            
            Actions.DropDown_Country('Province', v).then(xdata => {
                if (xdata.status === 200) {
                    this.setState({ province_selection: xdata.data, country_selected:v });
                } else {
                    this.setState({ province_selection: [] });
                }
            }).then((xdata) => { });
        }
    }
    loadDistrict(v) {
        if (v && this.current_country === 'THA') {
            Actions.DropDown_Country('District', v).then(xdata => {
                if (xdata.status === 200) {
                    if (this.current_country && this.current_country !== 'THA')
                        this.setState({ district_selection: [DataManager.select_none,...xdata.data ]});
                    else
                        this.setState({district_selection: xdata.data });
                } else {
                    this.setState({ district_selection: [] });
                }
            }).then((xdata) => { });
        } else
            this.setState({ district_selection: [DataManager.select_none] });
    }
    loadCountry() {
        Actions.DropDown_Country('Country').then(xdata => {
            if (xdata.status === 200) {
                this.setState({ country_selection: xdata.data});
            } else {
                this.setState({ country_selection: [] });
            }
        }).then((xdata) => { });
        
    }

    getCountry(input) {
        //loadOptions={this.getCountry}
        return Actions.DropDown_List('Country', input);
    }
    getProvince(input) {
        return Actions.DropDown_List('Province', input, country);
    }
    getDistrict(input) {
        return Actions.DropDown_List('District', input, province);
    }

    setHH = (e, v) => {
        if (v && v.value)
            this.setState({ HH: v.value });
        else
            this.setState({ HH: 0 });
    }
    renderCompanyInput(xstate) {
        //this.props.initialValues.users.packageInfo
        let state = this.props.initialValues;
        if ((state.users && state.users.packageInfo) && state.users.packageInfo.projectInfo && (state.users.packageInfo.projectInfo.project_type === 'Project')
            && (state.users && state.users.packageInfo.company_Info)
        ) {
            return <>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group row required">
                            <label className="col-sm-3 text-right control-label col-form-label">แผนก:<small className="form-text text-separate text-muted">Department</small></label>
                            <div className="col-sm-9">
                                <span className="width-100">
                                    <Field id="select-department" name="person_Info.department" className="custom-select" multi={false} required={true}
                                        onChange={this.selectedDepartment} component={HtmlElement.RenderSelectAsync} defaultOptions
                                        multi={false} loadOptions={this.getDepartment} autoLoad={true} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group row required">
                            <label className="col-sm-3 text-right control-label col-form-label">ตำแหน่ง:<small className="form-text text-separate text-muted">Position</small></label>
                            <div className="col-sm-9">
                                <Field id="select-position" name="person_Info.position" className="custom-select" multi={false} required={true}
                                    onChange={this.selectedPosition} component={HtmlElement.RenderSelectAsync} defaultOptions
                                    multi={false} loadOptions={this.getPosition} autoLoad={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </>;
        }
        return <></>;
    }
    ensureDataFetched() {
        const xIndex = this.props.match.params.xIndex || 0;
        if (xIndex == this.state.xIndex)
            return;
        this.setState({ xIndex: xIndex });
        if (xIndex && xIndex != 0) {
            this.props.requestData(xIndex);            
        }else {
            this.props.dispatch({
                type: 'UPDATE_CLIENTUSER_DATA',
                xIndex: null,
                data: {}
            });
        }
    }
    componentDidMount() {
        this.loadOptionalField();
        this.ensureDataFetched();
        //this.props.state.values.first_name = 'ddddd';
        //const xIndex = this.props.xIndex || 0;     
        Inputmask("99/99/9999").mask(document.getElementsByClassName("imask-date"));
        Inputmask("99:99").mask(document.getElementsByClassName("imask-time"));
        //if (this.props.initialValues && this.props.initialValues.person_Info && this.props.initialValues.person_Info.country && this.props.initialValues.person_Info.country.value)
        //    this.loadProvince(this.props.initialValues.person_Info.country.value);
        //else
        //    this.loadProvince('THA');
    }
    componentDidUpdate(prevProps) {
        //console.log(prevProps);
        if (prevProps.match.params.xIndex)
            this.ensureDataFetched();
        else if (prevProps.initialValues && prevProps.initialValues.person_Info) {
            this.props.dispatch({
                type: 'UPDATE_CLIENTUSER_DATA',
                xIndex: null,                
                data: {}
            });
            
        }
        
    }
    componentWillReceiveProps(nextProps) {
        console.log('componentWillReceiveProps++');        
        if (this.start && this.event_count > 0 && nextProps.initialValues && nextProps.initialValues.person_Info && nextProps.initialValues.person_Info.country) {
            //console.log(nextProps.initialValues.person_Info.country);
            //console.log(this.props.match.params.xIndex);
            this.start = false;
            if (this.props.match.params.xIndex === nextProps.match.params.xIndex) {
                this.current_country = nextProps.initialValues.person_Info.country.value;
                this.loadProvince(nextProps.initialValues.person_Info.country.value);
                if (nextProps.initialValues.person_Info && nextProps.initialValues.person_Info.province && nextProps.initialValues.person_Info.province.value)
                    this.loadDistrict(nextProps.initialValues.person_Info.province.value);

                if (nextProps.initialValues.users && nextProps.initialValues.users.packageInfo && nextProps.initialValues.users.packageInfo.packageConfig) {
                    console.log(nextProps.initialValues.users.packageInfo);
                    this.setState({ package_config: nextProps.initialValues.users.packageInfo.packageConfig});
                }
            }
        } else if (this.start && (!this.props.match.params.xIndex || this.props.match.params.xIndex === '')) {
            this.current_country = 'THA';
            this.loadProvince(this.current_country);
        }
        this.event_count++;

    }
    renderCheckboxDate(config = false) {
        if (this.state.user && this.state.user.packageInfo && this.state.user.packageInfo.packageConfig && ((this.state.user.packageInfo.packageConfig.show_birthchart === true && this.state.user.packageInfo.packageConfig.show_elements != 8) || this.state.user.packageInfo.packageConfig.show_birthchart === false)) {
            return (
                <div className="form-group row margin-0 padding-bottom-5">
                    <span className="col-sm-4 text-right control-label col-form-label margin-0"></span>
                    <div className="col-sm-8">
                        <Field name="person_Info.unknow_time" component="input" type="checkbox" className="form-check-input" id="person_Info.unknow_time" />
                        <label className="form-check-label" for="person_Info.unknow_time">ไม่ทราบเวลาเกิด/Do not know time of birth</label>
                    </div>
                </div>
            );
        } else if (config && ((config.show_birthchart === true && config.show_elements != 8) || config.show_birthchart === false)) {
            return (
                <div className="form-group row margin-0 padding-bottom-5">
                    <span className="col-sm-4 text-right control-label col-form-label margin-0"></span>
                    <div className="col-sm-8">
                        <Field name="person_Info.unknow_time" component="input" type="checkbox" className="form-check-input" id="person_Info.unknow_time" />
                        <label className="form-check-label" for="person_Info.unknow_time">ไม่ทราบเวลาเกิด/Do not know time of birth</label>
                    </div>
                </div>
            );
        }else
            return <></>;
        /*
         <div className="form-group row">
                    <span className="col-sm-4 text-right control-label col-form-label"></span>
                    <div className="col-sm-6">
                        <div className="row">
                            <div className="col-sm">
                                <Field name="unknow_date" component="input" type="checkbox" className="form-check-input" id="unknow_date" />
                                <label className="form-check-label" for="unknow_date">ไม่ทราบวันเกิด</label>
                            </div>
                            <div className="col-sm">
                                <Field name="unknow_time" component="input" type="checkbox" className="form-check-input" id="unknow_time" />
                                <label className="form-check-label" for="unknow_time">ไม่ทราบเวลาเกิด</label>
                            </div>
                        </div>
                    </div>
                </div>
         */
    }
    renderCheckboxPlace(config = false) {
        if (this.state.user && this.state.user.packageInfo && this.state.user.packageInfo.packageConfig && ((this.state.user.packageInfo.packageConfig.show_birthchart === true && this.state.user.packageInfo.packageConfig.show_elements != 8) || this.state.user.packageInfo.packageConfig.show_birthchart === false)) {
            return (
                <div className="row padding-bottom-5">
                    <div className="col-sm-12">
                        <Field name="person_Info.unknow_place" component="input" type="checkbox" className="form-check-input" id="person_Info.unknow_place" />
                        <label className="form-check-label" for="person_Info.unknow_place">ไม่ทราบสถานที่เกิด/Do not know place of birth</label>
                    </div>
                </div>
            );
        } else if (config && ((config.show_birthchart === true && config.show_elements != 8) || config.show_birthchart === false)) {
            return (
                <div className="row padding-bottom-5">
                    <div className="col-sm-12">
                        <Field name="person_Info.unknow_place" component="input" type="checkbox" className="form-check-input" id="person_Info.unknow_place" />
                        <label className="form-check-label" for="person_Info.unknow_place">ไม่ทราบสถานที่เกิด/Do not know place of birth</label>
                    </div>
                </div>
            );
        } else
            return <></>;
    }

    renderRForm(state) {
        //if ((this.state.country_selected === 'THA' && this.state.district_selection.length == 0) && state.initialValues.person_Info && state.initialValues.person_Info.birth_Place && state.initialValues.person_Info.birth_Place.province_id) {
        //    this.loadDistrict(state.initialValues.person_Info.birth_Place.province_id);
        //}
        //this.event_count++;
        //if (!this.current_country && this.event_count > 0 && state.initialValues && state.initialValues.person_Info && state.initialValues.person_Info.country) {
        //    this.current_country = this.props.initialValues.person_Info.country.value;
        //    if (this.state.province_selection.length === 0)
        //        this.loadProvince(state.initialValues.person_Info.country.value);
        //    if (this.state.district_selection.length === 0)
        //        this.loadDistrict(state.initialValues.person_Info.province.value);

        //}

        if (state.initialValues && state.initialValues.data_Model && state.initialValues.data_Model.id && state.initialValues.users && state.initialValues.users.packageInfo && state.initialValues.users.packageInfo.projectInfo) {
            return (<div className="text-secondary">
                <div className="mb-2"><b>Project :</b> {state.initialValues.users.packageInfo.projectInfo.name}</div>
                <div><b>Start :</b> {DataManager.dateTimeToInput(state.initialValues.users.packageInfo.projectInfo.start_date)}</div>
                <div className="mb-2"><b>End :</b> {DataManager.dateTimeToInput(state.initialValues.users.packageInfo.projectInfo.end_date)}</div>
                <div className="mb-2">{state.initialValues.users.packageInfo.projectInfo.description}</div>
                <div className="mb-2"><b>Package :</b> {state.initialValues.users.packageInfo.name}</div>
            </div>);
        } else if (Auth.IsAdmin(this.state.user)) {
            return (<>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group row required">
                            <label className="col-lg-12 text-left control-label col-form-label">Project</label>
                            <div className="col-lg-12">
                                <span className="width-100">
                                    <Field id="select-project" name="person_Info.project" className="custom-project" required="required"
                                        onChange={this.selectedProject} multi={false} options={this.state.project_selection} loadOptions={this.loadProject} defaultOptions
                                        component={HtmlElement.RenderSelectAsync} aria-describedby="input-project" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group row required">
                            <label className="col-lg-12 text-left control-label col-form-label">Department</label>
                            <div className="col-lg-12">
                                <span className="width-100">
                                    <Field id="select-department" name="person_Info.department" className="custom-department" required="required"
                                        onChange={this.selectedDepartment} multi={false} options={this.state.department_selection}
                                        component={HtmlElement.RenderSelectInput} aria-describedby="input-department" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group row required">
                            <label className="col-lg-12 text-left control-label col-form-label">Position</label>
                            <div className="col-lg-12">
                                <span className="width-100">
                                    <Field id="select-position" name="person_Info.position" className="custom-position"
                                        onChange={this.selectedPosition} multi={false} options={this.state.position_selection} def={this.state.position_selected ? this.state.position_selected.value : false}
                                        component={HtmlElement.RenderSelectInput} aria-describedby="input-position" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </>);
        } else if (Auth.IsProjectAdmin(this.state.user) && this.state.user.packageInfo && this.state.user.packageInfo.projectInfo) {
            if (this.state.department_selection.length < 1) {
                this.getDepartmentByProject(this.state.user.packageInfo.projectInfo.id);                
            }
            if (this.state.position_selection.length < 1) {
                this.getPositionByProject(this.state.user.packageInfo.projectInfo.id);
            }
            
            return (<>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group row required">
                            <label className="col-lg-12 text-left control-label col-form-label">Project :</label>
                            <div className="col-lg-12">
                                <b className="text-info">{this.state.user.packageInfo.projectInfo.name}</b>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group row required">
                            <label className="col-lg-12 text-left control-label col-form-label">Department</label>
                            <div className="col-lg-12">
                                <span className="width-100">
                                    <Field id="select-department" name="person_Info.department" className="custom-department" required="required"
                                        onChange={this.selectedDepartment} multi={false} options={this.state.department_selection}
                                        component={HtmlElement.RenderSelectInput} aria-describedby="input-department" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group row required">
                            <label className="col-lg-12 text-left control-label col-form-label">Position</label>
                            <div className="col-lg-12">
                                <span className="width-100">
                                    <Field id="select-position" name="person_Info.position" className="custom-position"
                                        onChange={this.selectedPosition} multi={false} options={this.state.position_selection} def={this.state.position_selected ? this.state.position_selected.value : false}
                                        component={HtmlElement.RenderSelectInput} aria-describedby="input-position" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </>);
        }else
            return '-';
    }

                 

   


    render() {
        
        const { handleSubmit, onChange } = this.props;
        return (<div className="card"><div className="card-body">
            <form className="form-horizontal" onSubmit={handleSubmit(this.submit)} ><Loading loading={this.state.loading} txt={true} />
                <div className="card-body">
                    <h5 className="card-title card-title-border text-secondary"><i className="fa fa-pencil" aria-hidden="true"></i> Person info form</h5>
                    <div className="row">
                        <div className="col-md-3 border-right">
                            {this.renderRForm(this.props)}
                        </div>
                        <div className="col-md-9">
                            <div className="row">
                        <div className="col-md-6">
                            <div className="form-group row required">
                                        <label className="col-sm-3 text-right control-label col-form-label">นามสมมุติ:<small className="form-text text-separate text-muted">Short Name</small></label>
                                <div className="col-sm-9">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="input-name"><i className="fa fa-user-o"></i></span>
                                        </div>
                                                <Field className="form-control" name="person_Info.first_name" placeholder="นามสมมุติ/Short Name..." component="input" type="text" aria-describedby="input-name" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row required">
                                        <label className="col-sm-3 text-right control-label col-form-label">โค้ดอ้างอิง:<small className="form-text text-separate text-muted">Reference Code</small></label>
                                <div className="col-sm-9">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="input-lastname"><i className="fa fa-user"></i></span>
                                        </div>
                                                <Field className="form-control" name="person_Info.last_name" placeholder="โค้ดอ้างอิง/Reference Code..." component="input" type="text" aria-describedby="input-lastname" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row required">
                                        <label className="col-sm-3 text-right control-label col-form-label">เพศ:<small className="form-text text-separate text-muted">Gender</small></label>
                                <div className="col-sm-9">
                                    <label className="label-chkbox" htmlFor="male">
                                                <Field type="radio" className="option-x-input xradio" component="input" name="person_Info.gender" id="male" value="male" required="" />
                                                <span><i className="fa fa-male text-secondary"></i> ชาย<small className="form-text text-separate-option text-secondary">Male</small></span>
                                    </label>
                                    <span className="padding-lr-30">&nbsp;</span>
                                    <label className="label-chkbox" htmlFor="female">
                                        <Field type="radio" className="option-x-input xradio" component="input" name="person_Info.gender" id="female" value="female" required="" />
                                                <span><i className="fa fa-female text-secondary"></i> หญิง<small className="form-text text-separate-option text-secondary">Female</small></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group row required">
                                        <label className="col-sm-4 text-right control-label col-form-label">วว/ดด/ปปปป เกิด:<small className="form-text text-separate text-muted">DD/MM/YYYY of Birth</small></label>
                                <div className="col-sm-8">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="input-date"><i className="fa fa-calendar"></i></span>
                                        </div>
                                        <Field className="form-control imask-date" name="person_Info.date_of_birth" placeholder="00/00/0000" component="input" type="text" aria-describedby="input-date" />
                                    </div>
                                </div>
                                    </div>
                                    {this.renderCheckboxDate(this.state.package_config)}
                            <div className="form-group row required">
                                        <label className="col-sm-4 text-right control-label col-form-label">เวลาเกิด:<small className="form-text text-separate text-muted">Time of Birth</small></label>
                                <div className="col-sm-8">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="input-time" htmlFor="select-time"><i className="fa fa-clock-o"></i></span>
                                        </div>
                                        <Field className="form-control imask-time" name="person_Info.birth_time" placeholder="00:00" component="input" type="text" aria-describedby="input-time" />
                                    </div>
                                    <small id="birth_time_help" className="form-text text-muted">Time format 00:00 - 23.59</small>
                                    <span className="col-sm-5 text-right col-form-label"></span>
                                </div>
                            </div>
                                </div>
                            </div>
                            
                            {this.renderCompanyInput(this.state)}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group row margin-0">
                                        <label className="col-lg-1 control-label col-form-label"></label>
                                        <div className="col-lg-11">
                                            <span className="text-secondary">สถานที่เกิด/Place of Birth:<span className="text-required">*</span></span>
                                            {this.renderCheckboxPlace(this.state.package_config)}
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group row">
                                        <label className="col-lg-4 text-right control-label col-form-label">ประเทศ<small className="form-text text-separate text-muted">Country</small></label>
                                        <div className="col-lg-8">
                                            <span className="width-100">
                                                <Field id="select-country" name="person_Info.country" className="custom-select" multi={false} required={true}
                                                    onChange={this.selectedCountry} loadOptions={this.getCountry} defaultOptions
                                                    component={HtmlElement.RenderSelectAsync} aria-describedby="input-country" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group row">
                                        <label className="col-lg-4 text-right control-label col-form-label">จังหวัด<small className="form-text text-separate text-muted">City or Province</small></label>
                                        <div className="col-lg-8">
                                            <span className="width-100">
                                                <Field id="select-province" name="person_Info.province" className="custom-province" multi={false} required={true}
                                                    onChange={this.selectedProvince} multi={false} options={this.state.province_selection}
                                                    component={HtmlElement.RenderSelectInput} aria-describedby="input-province" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group row">
                                        <label className="col-lg-4 text-right control-label col-form-label">อำเภอ</label>
                                        <div className="col-lg-8">
                                            <Field id="select-province" ref="ref_district" name="person_Info.district" className="custom-district" multi={false} required={true}
                                                onChange={this.selectedDistrict} multi={false} options={this.state.district_selection}
                                                component={HtmlElement.RenderSelectInput} aria-describedby="input-district" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.optional_fields}
                            <div className="">
                                <div className="card-body">
                                    <div className="form-group btn-center text-center">
                                        <span className="padding-left-15"><button type="submit" className="btn btn-dark btn-lg"><i className="fa fa-check text-success"></i> บันทึกข้อมูล</button></span></div>
                                </div>
                            </div>
                        </div>
                        
                    </div>                   
                    

                </div>
               
            </form>
        </div>
            {HtmlElement.RenderModal(this.state.modal)}
        </div>
        );
    }
}

/*

<span className="width-100">
    <Field options={Utils.TimeSelection()} onChange={this.setHH} id="select-time" name="HH" className="custom-select" multi={false} required={true} 
                        component={HtmlElement.RenderSelectInput} aria-describedby="input-time"/>
</span>

*/

// Form = connect((state, props) => ({
//         state: state.plan,
//         initialValues: state.plan.xdata,
//         form: props.form,
//         mode:props.mode
// }),


ProjectUsingForm = reduxForm({
    form: 'ProjectUsing_Form',
    enableReinitialize: true
})(ProjectUsingForm);

ProjectUsingForm = connect(
    (state, props) => ({
        initialValues: (state.clientUser && state.clientUser.data && state.clientUser.data.person_Info) ? state.clientUser.data : { person_Info: { country: { value: 'THA', label: 'Thailand' } } }, //state.clientUser.data,
        state: { xvalues: state.clientUser.data}
    }), ClientUserStore.actionCreators
)(ProjectUsingForm);

/*
ProjectUsingForm = connect(
    (state, props) => ({
        state: props.values,
        initialValues: props && props.values ? props.values : { start: true, country: { value: 'THA', label: 'Thailand' } }
    })
)(ProjectUsingForm);
*/
export default ProjectUsingForm;