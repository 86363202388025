import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from '../Common/Actions';
import $ from 'jquery';
import { Field, reduxForm, change, onChange,submit,getFormValues } from 'redux-form';
import Loading from '../Common/Loading';
import Utils from '../Common/Utils';
import * as ActionAdmin from '../Common/ActionAdmin';
import '../../templates/public/css/content-style.css';
import Auth from '../Common/Auth';
import { checkNotNull } from '../Common/DataManager';
class Step0 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: Auth.GetUser(),
            loading: true, values: props.initialValues, xdata: false, GuestData: props.GuestData, page: {}
        }
        this.goBack = this.goBack.bind(this);
        this.xpage_key = 'xpage';
    }

    goBack(e) {
        this.props.onGoBack(1);
    }
     
    componentDidMount() {
        let page = sessionStorage.getItem(this.xpage_key);
        if (page && checkNotNull(page)) {
            page = JSON.parse(page);
            this.setState({
                page: page, loading: false
            });
        } else {
            ActionAdmin.Get(ActionAdmin.Models.Pro, { type: 'first' }).then(xdata => {
                if (xdata && xdata.status === 200 && xdata.data) {
                    this.setState({
                        page: xdata.data
                    });
                    sessionStorage.setItem(this.xpage_key, JSON.stringify(xdata.data));
                } else {
                    this.setState({
                        page: {}
                    });
                    sessionStorage.removeItem(this.xpage_key);
                }
            }).then((x) => this.setState({ loading: false }));
        }
    }

    renderAuthCode(state) {
        //console.log(Auth.IsAdmin());
        if ((state.user && state.user.packageInfo && state.user.packageInfo.projectInfo) || Auth.IsAdmin()) {
            //if (state.user.packageInfo.projectInfo.project_type === 'Project') {
                
            //}
            return <></>;
        }
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card login-form border-info">
                        <div className="card-header bg-info"><i className="fa fa-sign-in" aria-hidden="true"></i> Enter code to access</div>
                        <div className="card-body">
                            <div>
                                <label htmlFor="Code">CODE:</label>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="iCode"><i className="fa fa-key" aria-hidden="true"></i></span>
                                    </div>
                                    <Field component="input" id="Code" name="Code" autoComplete="off" type="text" className="form-control" placeholder="Enter promo code..." aria-describedby="iCode" required />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="padding-5"></div>
                    <div className="text-danger text-center">{this.state.msg}</div>
                </div>
            </div>
            );
    }

    render() {    
        const { handleSubmit, onChange, pristine, reset, submitting } = this.props;
        return (
            <form className="form-horizontal form-consent" onSubmit={handleSubmit} >
                <div className="card-body"><Loading loading={this.state.loading}/>
                    <div className="row">
                        <div className="col-sm">      
                            <div className="header-info text-secondary">{this.state.page.name}</div>
                            <div className="consent-info">
                                <div className="consent-info-text" dangerouslySetInnerHTML={{ __html: this.state.page.description }} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm text-left">
                            <span className="col-sm margin-left-10 breakword">
                                    <Field name="is_agreed" component="input" type="checkbox" className="form-check-input" id="is_agreed" />
                                <label className="form-check-label breakword" htmlFor="is_agreed">ฉันยอมรับเงื่อนไขและข้อตกลงการใช้บริการ / I agree with terms and conditions of the service</label>                                
                            </span>
                        </div>
                    </div>  
                    <div className="padding-10"></div>
                    {this.renderAuthCode(this.state)}
                    
                                      
                </div>
                <div className="border-top">
                    <div className="card-body">
                        <div className="form-group btn-center text-center">
                            <span className="margin-5">
                                <button type="submit" className="btn btn-primary btn-lg" disabled={pristine || submitting}> <i className="fa fa-check text-success" aria-hidden="true"></i> ตกลง</button>
                            </span>                      
                    </div>
                    </div>
                </div>                
            </form>
                );
    }
}


Step0 = reduxForm({
    form: 'Step_0'
})(Step0);

export default Step0;