import React, { Component } from 'react';
import { connect } from 'react-redux';
import Utils from '../../Common/Utils';
import $ from 'jquery';
import { ApplicationState } from '../../../store';
import { Field, reduxForm } from "redux-form";
import * as ProjectStore from '../../../store/Project';
import * as HtmlElement from '../../Common/HtmlElement';
import * as Actions from '../../Common/Actions';
import * as ActionAdmin from '../../Common/ActionAdmin';
import * as DataManager from '../../Common/DataManager';
import selectOption from '../../Common/selectOption';
import Inputmask from "inputmask";
import Loading from '../../Common/Loading';

class ProjectForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false, props: props, package_selection: [], company_selection: [], company_selected: false,
            modal: { title: '', msg: '' }, users_selection:[],start:true,
        }
        //this.select_user = React.createRef();
        //this.getUsers = (input) => {
        //    //let r = this.state.company_selected && this.state.company_selected.value ? { company_id: this.state.company_selected.value } : '';
        //    //return Actions.DropDown_List(ActionAdmin.Models.Users, input, DataManager.serializeToQstr(r)).then(xdata => {
        //    //    return xdata
        //    //});
        //}
        this.selectedPackage = (e, val) => {
            console.log(val);
        }
        this.selectedCompany = (e, val) => {
            this.setState({ company_selected: val });
            //this.refs.select_user.props.loadOptions(' ');
            //this.refs.select_user.props.defaultOptions = true;            
            this.loadUsers('company_id='+val.value);
            this.props.change('user', false);
        }
        this.selectedUser = (e, val) => {
            console.log(val);
        }
        this.loadPackage();
        this.loadCompany();


        this.submit = (vals)=> {
            var data = DataManager.Project_FormToModel(vals);
            this.setState({ modal: { title: '', msg: '' } });
            console.log(data);
            if (data.error && data.error.length > 0) {

                this.setState({ modal: { title: '', msg: <ul className="err-msg text-danger">{data.error}</ul> } });
                $(".modal").modal({ show: true });

                return;
            }
            this.setState({ loading: true });            
            ActionAdmin.Post_Model(ActionAdmin.Models.Project, data).then(xdata => {
                vals.start_date = data.dates.start;
                vals.end_date = data.dates.end;
                if (xdata && xdata.status === 200) {                    
                    vals['id'] = xdata.data.id;
                    vals['project_type'] = xdata.data.project_type;
                    vals['key'] = xdata.data.key;
                    //data['start_date'] = data.dates.start;
                    //data['end_date'] = data.dates.end;                
                    this.props.dispatch({
                        type: 'UPDATE_PROJECT_DATA',
                        xIndex: xdata.data.id,
                        data: vals
                    });
                    //this.setState({ modal: { title: '', msg: 'บันทึกข้อมูลสำเร็จ' } });
                    //$(".modal").modal({ show: true });
                    //this.props.history.push('/admin/project/form/' + xdata.data.id);
                    this.props.history.push('/admin/projects');
                    //$(".modal").modal({ show: false });
                } else {
                    this.setState({ frmeror: xdata.Message ? xdata.Message : xdata.message, loading: false });
                    this.setState({ modal: { title: 'ไม่สามารถบันททึกข้อมูลได้', msg: <ul className="err-msg text-danger">กรุณาลองใหม่อีกครั้ง<li>{xdata.Message ? xdata.Message : xdata.message}</li></ul> } });
                    $(".modal").modal({ show: true });
                }
                data = {};
            }).then((x) => { });
        }
    }

    
    componentDidMount() {
        this.ensureDataFetched();    
        Inputmask("99/99/9999 99:99").mask(document.getElementsByClassName("imask-datetime"));
        //Inputmask("99:99").mask(document.getElementsByClassName("imask-time"));
        //console.log(this.props);
        //this.loadUsers('company_id='+val.value);
    }

    // This method is called when the route parameters change
    componentDidUpdate(prevProps) {
        if (this.props.pristine && !prevProps.pristine) {
            this.setState({ company_selected: false });
        }
        if (prevProps.initialValues && prevProps.initialValues.company_Info && prevProps.initialValues.company_Info.id > 0 && this.state.start === true) {
            this.setState({ start: false });
            this.loadUsers('company_id=' + prevProps.initialValues.company_Info.id);
        }

        this.ensureDataFetched();
    }
    ensureDataFetched() {
        const xIndex = this.props.match.params.xIndex || 0;
        if (xIndex && xIndex != 0)
            this.props.requestData(xIndex);
        else {
            this.props.dispatch({
                type: 'UPDATE_PROJECT_DATA',
                xIndex: 0,
                data: {}
            });
        }
    }
    loadPackage() {
        Actions.DropDown_List(ActionAdmin.Models.Package).then(xdata => {
            this.setState({ package_selection: xdata });       
        });
    }
    loadCompany() {
        Actions.DropDown_List(ActionAdmin.Models.Company).then(xdata => {
            this.setState({ company_selection: xdata, company_selected: false });
        });
    }
    loadUsers(r) {
        Actions.DropDown_List(ActionAdmin.Models.Users, null, r).then(xdata => {
            this.setState({ users_selection: xdata});
        });
    }
 
    render() {
        const { handleSubmit, onChange, pristine, reset, submitting } = this.props;
        //console.log(this.props.initialValues);
        return (
            <>            
            <form className="form-horizontal" onSubmit={handleSubmit(this.submit)} >
                    <div className="card"><Loading loading={this.state.loading} txt={true} />
                        <div className="card-body">
                            <h5 className="card-title card-title-border text-secondary"><i className="fa fa-pencil" aria-hidden="true"></i> Project form</h5>
                <div className="row">
                    <div className="col-sm">                    
                        
                            <div className="form-group row required">
                                <label htmlFor="name" className="col-sm-4 text-right control-label col-form-label">Name:</label>
                                <div className="col-sm-8">
                                    <Field name="name" component="input" type="text" className="form-control" id="name" required />
                                </div>
                                    </div>
                                    {this.props.initialValues && this.props.initialValues.id ?
                                        <div className="form-group row required">
                                            <label htmlFor="quantity" className="col-sm-4 text-right control-label col-form-label">Quantity:</label>
                                            <div className="col-sm-8">
                                                {this.props.initialValues.quantity}
                                            </div>
                                        </div>
                                        :
                                        <div className="form-group row required">
                                            <label htmlFor="quantity" className="col-sm-4 text-right control-label col-form-label">Quantity:</label>
                                            <div className="col-sm-8">
                                                <Field name="quantity" component="input" type="number" className="form-control" id="quantity" required min="1" />
                                            </div>
                                        </div>}
                                    <div className="form-group row required">
                                        <label htmlFor="start_date" className="col-sm-4 text-right control-label col-form-label">Start:</label>
                                        <div className="col-sm-8">
                                            <Field name="start_date" component="input" type="text" className="form-control imask-datetime" id="start_date" required placeholder="00/00/0000 00:00" />
                                        </div>
                                    </div>
                                    <div className="form-group row required">
                                        <label htmlFor="end_date" className="col-sm-4 text-right control-label col-form-label">End:</label>
                                        <div className="col-sm-8">
                                            <Field name="end_date" component="input" type="text" className="form-control imask-datetime" id="end_date" required placeholder="00/00/0000 00:00"/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="path" className="col-sm-4 text-right control-label col-form-label">Path:</label>
                                        <div className="col-sm-8">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon3">{document.location.protocol + '//' + document.location.hostname + (document.location.port ? ':' + document.location.port : '')+'/pro/'}</span>
                                                </div>
                                                <Field name="path" component="input" type="text" className="form-control" id="path" aria-describedby="basic-addon3" maxLength="32" />
                                            </div>                                            
                                        </div>
                                    </div>
                            <div className="form-group row">
                                <label htmlFor="wood" className="col-sm-4 text-right control-label col-form-label">Description:</label>
                                <div className="col-sm-8">
                                    <Field name="description" component="textarea" type="text" className="form-control" id="description" />
                                </div>
                            </div>
                            </div>

                                <div className="col-sm">
                                <div className="form-group row required">
                                    <label className="col-lg-4 text-right control-label col-form-label">Package:</label>
                                    <div className="col-lg-8">
                                        <span className="width-100">
                                            <Field id="select-package" name="package" className="custom-dropdown" required="required"
                                            onChange={this.selectedPackage} multi={false} options={this.state.package_selection}
                                            component={HtmlElement.RenderSelectInput} aria-describedby="input-package" />
                                        </span>
                                    </div>
                                </div> 
                                <div className="form-group row required">
                                        <label className="col-lg-4 text-right control-label col-form-label">Organization:</label>
                                    <div className="col-lg-8">
                                        <span className="width-100">
                                            <Field id="select-company" name="company" className="custom-company" required="required"
                                                onChange={this.selectedCompany} multi={false} options={this.state.company_selection}
                                                component={HtmlElement.RenderSelectInput} aria-describedby="input-company" />
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <label className="col-lg-4 text-right control-label col-form-label">Admin:</label>
                                    <div className="col-lg-8">
                                            <span className="width-100">
                                                <Field id="select-user" ref="select_user" name="user" className="custom-user" required="required" defaultOptions
                                                    onChange={this.selectedUser.bind(this, "select_user")} multi={false} options={this.state.users_selection} autoLoad={true}
                                                component={HtmlElement.RenderSelectInputCreateable} aria-describedby="input-user" />
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group row">
                                <label htmlFor="remark" className="col-sm-4 text-right col-form-label">Remark:</label>
                                <div className="col-sm-8">
                                            <Field name="remark" component="textarea" type="text" className="form-control" id="remark" />
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div className="row">
                        {this.props.xerror ? <div className="alert alert-danger" role="alert"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.props.xerror}</div> : <></>}
                            </div>
                            <hr className="border-bottom" />
                            <div className="row">
                                <hr/>
                                <div className="col-sm text-center">
                        <button type="button" className="btn btn-danger mr-2" data-dismiss="modal" disabled={pristine || submitting} onClick={reset}><i className="fa fa-times"></i> Clear</button>
                        <button type="submit" className="btn btn-primary" disabled={pristine || submitting}><i className="fa fa-check"></i> {this.props.btnName ? this.props.btnName : 'Save & Changes'}</button>
                                </div>
                            </div>
                    </div>
                </div>
            </form>
            { HtmlElement.RenderModal(this.state.modal) }
        </>
        );
    }
}

ProjectForm = reduxForm({
    form:"Frm_Project",
    enableReinitialize: true
})(ProjectForm);
ProjectForm = connect(
    (state, props) => ({
        state: { iniValues: state.project.data },
        initialValues: state.project.data
    }), ProjectStore.actionCreators
)(ProjectForm);

export default ProjectForm;

//const mapStateToProps = (state, props) => ({
//    initialValues: props.initialValues, // retrieve name from redux store 
//})
//export default ModalForm(
//    mapStateToProps
//)(reduxForm({
//    form: 'sixxxmple',
//    enableReinitialize: true
//})(ModalForm))