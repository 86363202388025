import * as React from 'react';
import { Container } from 'reactstrap';
import $ from 'jquery';
import NavMenu from './NavMenu';
import SideMenu from './SideMenu';
import Footer from './Footer';
import LoadScript from '../../Common/LoadScript';
import Auth from '../../Common/Auth';
import { RouteComponentProps } from 'react-router';


export interface DispatchProps {

}

export interface AppProps {
    loading?: boolean;
}

interface State {
    user?: any;
    scriptLoaded?: boolean;
    scriptError?: boolean;
}


type Props = DispatchProps & AppProps & RouteComponentProps<{}>;


class Layout extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            user: Auth.GetUser()
        }
    } 
    handleScriptCreate() {
        this.setState({ scriptLoaded: false })
    }

    handleScriptError() {
        this.setState({ scriptError: true })
    }

    handleScriptLoad() {
        this.setState({ scriptLoaded: true })
    }

    componentDidMount() {
        if (!Auth.IsAdmin(this.state.user) && !Auth.IsProjectAdmin(this.state.user) && !Auth.IsPromoAdmin(this.state.user)) {
            window.location.href = '/';
        }

        $("#menu-toggle").click(function (e) {
            e.preventDefault();
            $("#wrapper").toggleClass("toggled");
        });

    }

    public render() {
        return (
            <React.Fragment>           
                <NavMenu/>
                <div className="d-flex" id="wrapper">
                    <SideMenu/>
                    <div id="page-content-wrapper">
                        <nav className="navbar navbar-expand-lg navbar-light custom-show-nav">
                            <button className="btn btn-light shadow-sm" id="menu-toggle"><i className="navbar-toggler-icon text-danger"></i></button>
                        </nav>
                        <div className="container-fluid">
                            <div className="row clearfix">
                                <div className="col-md-12 margin-right-10">
                                {this.props.children}
                                </div>
                            </div>
                        </div>            
                    </div>
                </div>

            <Footer/>
            <LoadScript files={['/js/main.js']} />
        </React.Fragment >
            )
    }
}
export default Layout;