import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm} from 'redux-form';
import $ from 'jquery';
import Loading from './Common/Loading';
import * as HtmlElement from './Common/HtmlElement';
import Auth from './Common/Auth';
import * as Actions from './Common/Actions';
import MetaTags from 'react-meta-tags';
import '../templates/public/login.css';
import html2canvas from 'html2canvas';

class LoginFrm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true, msg:false
         };
    }
    submit = (values) => {

        if (!values || !values.Username || !values.Password){
            this.setState({msg:'Username and password are required.'});
            return;
        }
        this.setState({loading:true});
        Actions.Login(values).then(xdata => {
                if (xdata.status === 200) {                 
                    console.log('OK');
                    Auth.Login(xdata.data);
                    window.location.href = '/admin';
                    return;
                }else{
                   this.setState({msg:this.errMsg(xdata.message ? xdata.message : xdata.Message),loading:false}); 
                   //$('.alert').alert();
                }
            });//.then((x) => {if(x.status !== 200)this.setState({ loading: false })});
            //this.setState({ loading: false });
        return;
    }
    errMsg(msg){
        return(
        <div id="alert-msg" className="alert alert-warning alert-dismissible fade show login-form text-left" role="alert">
            <strong><i className="fa fa-exclamation-circle" aria-hidden="true"></i></strong> {msg}
            {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button> */}
        </div>
        );
    }
    componentDidMount() {
        document.title = "FASTEn Model ™";
        if (Auth.isLoggedIn()) {            
            //window.location.href = '/admin';
        }else{
            this.setState({loading:false});
        }
    }
    render(){
        const { handleSubmit } = this.props;          
        return(
            <div className="container">      
                <MetaTags>
                    <title>FASTEn Model ™</title>
                    <meta name="description" content={HtmlElement.getPageDescription()} />
                    <meta property="og:title" content="FASTEn Model ™" />
                </MetaTags>
                <div className="row">                  
                            <div className="col-12">< Loading loading={this.state.loading}/>
                                <div className="card login-form">
                                    <div className="card-header"><i className="fa fa-sign-in" aria-hidden="true"></i> FASTEn Model&trade; Access</div>
                                        <div className="card-body">
                                            <div >
                                                <form onSubmit={handleSubmit(this.submit)}>
                                                    <label htmlFor="usrname">Username:</label>
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="iUsername"><i className="fa fa-user-o" aria-hidden="true"></i></span>
                                                        </div>
                                                        <Field component="input" id="usrname" name="Username" type="text" className="form-control" placeholder="Username" aria-describedby="iUsername" required/>
                                                    </div>
                                                    <label htmlFor="psswd">Password:</label>
                                                    <div className="input-group mb-3">                                                 
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="iPassword"><i className="fa fa-key" aria-hidden="true"></i></span>
                                                        </div>
                                                        <Field component="input" id="psswd" name="Password"  type="password" className="form-control" placeholder="Password" aria-describedby="iPassword" required/>
                                                    </div>
                                                    <div className="text-center">                                                    
                                                        <button type="submit" className="btn btn-dark"><i className="fa fa-unlock-alt" aria-hidden="true"></i> Login</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>    
                                    <div className="padding-5"></div> 
                                    <div className="text-danger text-center">{this.state.msg}</div>
                                    <div className="space-btm"></div>                           
                            </div>    
                </div>
            </div>
            );
            
        }
    }
const fields = {username:"",password:""};
LoginFrm = reduxForm({
    form: 'Form_Login'
})(LoginFrm)
export default LoginFrm;
