import React, { Component } from 'react';
import { connect } from 'react-redux';
import Utils from '../Common/Utils';
import $ from 'jquery';
import { Field, reduxForm } from "redux-form";
import * as HtmlElement from '../Common/HtmlElement';
import * as Actions from '../Common/Actions';
import * as ActionAdmin from '../Common/ActionAdmin';
import * as DataManager from '../Common/DataManager';
import selectOption from '../Common/selectOption';
import Inputmask from "inputmask";
import Auth from '../Common/Auth';
import { push } from 'connected-react-router';

class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false, props: props, user: Auth.GetUser(),
            modal: { title: '', msg: '' }
        }
        


        this.submit = (vals) => {
            console.log(vals);

            if (!vals) {
                this.setState({ modal: { title: 'Warning', msg: 'กรุณาระบุข้อมูล' } });
                $('.modal').modal('show');
                return;
            }
            if (!vals.password || vals.password.length < 8) {
                this.setState({ modal: { title: 'Warning', msg: 'กรุณาตั้งรหัสผ่านอย่างน้อย 8 ตัวอักษร' } });
                $('.modal').modal('show');
                return;
            }
            if (!vals.confirm_password || vals.confirm_password !== vals.password) {
                this.setState({ modal: { title: 'Warning', msg: 'กรุณายืนยันรหัสผ่านให้ตรงกับที่ตั้งไว้' } });
                $('.modal').modal('show');
                return;
            }
            //console.log(this.state.user);

            this.setState({ loading: true });
            vals['id'] = this.state.user.id;
            ActionAdmin.ChangePassword(vals).then(xdata => {
                console.log(xdata);
                if (xdata && xdata.status === 200 && xdata.data) {
                    this.setState({ modal: { title: 'บันทึกข้อมูลสำเร็จ', msg: 'บันทึกข้อมูลเรียบร้อย' } });
                    $('.modal').modal('show');
                } else {
                    this.setState({ modal: { title: 'ไม่สามารถบันทึกข้อมูลได้', msg: DataManager.getError(xdata) } });
                    $('.modal').modal('show');
                }
                vals = {};
            }).then((x) => this.setState({
                loading: false
            }));
        }
    }

    
    componentDidMount() {

    }

    // This method is called when the route parameters change
    componentDidUpdate(prevProps) {
    }
 

 
    render() {
        const { handleSubmit, onChange, pristine, reset, submitting  } = this.props;
        return (
            <>
            <form className="form-horizontal" onSubmit={handleSubmit(this.submit)} >
                <div class="card">
                        <div class="card-body">
                            <h5 className="card-title text-secondary card-title-border"><i className="fa fa-cog" aria-hidden="true"></i> Change password</h5>
                            <div class="row">
                                <div class="col-md-3"></div>
                                <div class="col-md-6">
                                    <div className="form-group row">
                                        <span className="col-sm-4 col-form-label text-right">
                                            User:
                                        </span>
                                        <div className="col-sm-8 col-form-label">
                                            {this.state.user.full_name}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <span className="col-sm-4 col-form-label text-right">
                                           New Password:
                                        </span>
                                        <div className="col-sm-8">
                                            <Field name="password" component="input" type="password" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <span className="col-sm-4 col-form-label text-right">
                                            Confirm Password:
                                        </span>
                                        <div className="col-sm-8">
                                            <Field name="confirm_password" component="input" type="password" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        {this.props.xerror ? <div className="alert alert-danger" role="alert"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.props.xerror}</div> : <></>}
                                    </div>
                                    <div className="row">
                                        <div className="col-sm text-center">
                                        <button type="button" className="btn btn-danger mr-2" data-dismiss="modal" disabled={pristine || submitting} onClick={reset}><i className="fa fa-times"></i> Clear</button>
                                        <button type="submit" className="btn btn-primary" disabled={pristine || submitting}><i className="fa fa-check"></i> {this.props.btnName ? this.props.btnName : 'Save & Changes'}</button>
                                        </div>
                                    </div>
                                </div>   
                                <div class="col-md-3"></div>
                    </div>                    
                    </div>
                </div>
            </form>
            { HtmlElement.RenderModal(this.state.modal) }
        </>
        );
    }
}

User = reduxForm({
    form:"Frm_User",
    enableReinitialize: true
})(User);
export default connect()(User);

//const mapStateToProps = (state, props) => ({
//    initialValues: props.initialValues, // retrieve name from redux store 
//})
//export default ModalForm(
//    mapStateToProps
//)(reduxForm({
//    form: 'sixxxmple',
//    enableReinitialize: true
//})(ModalForm))