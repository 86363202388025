import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import HtmlElement, {getPageDescription} from '../Common/HtmlElement';
class Product extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render(){
        return (<div className="container fcontainer">
            <MetaTags>
                <title>Products</title>
                <meta name="description" content={'Products of '+ getPageDescription()} />
                <meta property="og:title" content="Products" />
            </MetaTags>
            <div className="row font-sarabun">
                <div className="col-12">                    
                    <h1 className="header-info mb-0"><i class="fa fa-circle-o text-warning" aria-hidden="true"></i> Products</h1>
                    <p style={{'font-size':'16px'}}>เพราะเราเข้าใจว่าท่านไม่ต้องการดาวน์โหลดแอบพลิเคชั่น และไม่มีความจำเป็นต้องใช้บริการเราบ่อย เราจึงออกแบบบริการที่เหมาะกับการใช้งานจริง ดังนี้</p>
                    <ul className="list-group font-normal">
                        <li className="list-group-item">
                            <h3 className="h3 text-danger">1. สำหรับบุคคลทั่วไป</h3>
                            <div className="text-ident">ท่านสามารถติดต่อเพื่อซื้อรหัสเพื่อเข้าใช้งานในระบบแบบไม่จำเป็นต้องลงทะเบียน โดย 1 รหัสสามารถใช้ได้กับ 1 ข้อมูล เมื่อใช้งานแล้ว ก็ยังสามารถใช้รหัสเดิม เพื่อเข้าไปดูผลซ้ำได้ (แต่ไม่สามารถแก้ไขข้อมูลที่กรอกไปแล้วได้) กรณีสั่งซื้อครั้งละหลายรหัสท่านจะได้รับส่วนลด   และท่านสามารถติดต่อเพื่อปรึกษาส่วนตัวผ่านช่องทางออนไลน์ได้ โดยท่านที่ซื้อรหัสไปแล้วสามารถแจ้งเพื่อรับส่วนลดค่าปรึกษาได้</div>
                        </li>
                        <li className="list-group-item">
                            <h3 className="h3 text-danger">2. สำหรับที่ปรึกษามืออาชีพ</h3>
                            <div className="text-ident">ในอนาคตทางเราจะจัดอบรมผู้ที่สนใจใช้ <b>FASTEn Model&trade;</b> เพื่อการพัฒนา ซึ่งเหมาะสำหรับผู้ที่มีประสบการณ์การทำงานจริง เจ้าของกิจการ หรือเจ้าหน้าที่ฝ่ายทรัพยากรบุคคลที่ต้องการมีเครื่องมือในการประเมินพฤติกรรม โดยจะเป็นการอบรมเชิงปฏิบัติ ต้องสะสมจำนวนเคสของการไปทดลองวิเคราะห์จริง ผู้ที่ผ่านการอบรมจะได้รับใบประกาศนียบัตรรับรองการผ่านการฝึกงาน</div>
                        </li>
                        <li className="list-group-item">
                            <h3 className="h3 text-danger">3. สำหรับองค์กร</h3>
                            <div className="text-ident">เราสามารถจัดอบรมให้กับหน่วยงานด้วยการใช้ <b>FASTEn Model&trade;</b> ได้หลากหลายรูปแบบ เช่น Team Building, Talent Grooming, Work Counselling โดยสามารถให้ผู้เข้าร่วมกิจกรรม กรอกข้อมูลส่วนตัวเข้าระบบแบบเรียลไทม์ และประมวลผลเป็นรายงานภาพรวมของหน่วยงานได้ นอกจากนี้เรายังสามารถจัดอบรมผ่านสื่อออนไลน์ เช่น Zoom ได้ตามความต้องการของหน่วยงาน</div>
                        </li>
                    </ul>
                    <div className="padding-bottom-5"></div>
                    <p className="text-center text-primary">ค่าบริการและรายละเอียดเพิ่มเติม สามารถสอบถามผ่าน Line: @Wintegrate99  หรือ E-mail: admin@wintegrate99.com</p>
                </div>                
            </div>
        </div>
        );
        }
    }

export default Product;
