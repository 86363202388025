import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as ActionsAdmin from '../components/Common/ActionAdmin';
import Utils from '../components/Common/Utils';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface PageFormState {
    isLoading: boolean;
    xIndex?: string;
    data: any;
}

interface RequestPageFormAction {
    type: 'REQUEST_PAGE_DATA';
    xIndex: string;
    //data: any;
}

interface ReceivePageFormAction {
    type: 'RECEIVE_PAGE_DATA';
    xIndex: string;
    data: any;
}
interface UpdatePageFormAction {
    type: 'UPDATE_PAGE_DATA';
    xIndex: string;
    data: any;
}

type KnownAction = RequestPageFormAction | ReceivePageFormAction | UpdatePageFormAction;

export const actionCreators = {
    requestData: (xIndex: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.pageForm && xIndex !== appState.pageForm.xIndex) {
            fetch(`/api/${ActionsAdmin.Models.Project}/GetPage?id=${xIndex}`, {
                method: 'GET',
                headers: Utils.headerXRequest()
            })
                .then(response => response.json() as Promise<any>)
                .then(xdata => {
                    //console.log(PageForm_ModelToForm(xdata.data));
                    dispatch({ type: 'RECEIVE_PAGE_DATA', xIndex: xIndex, data: xdata.data ? xdata.data : {} });
                });

            dispatch({ type: 'REQUEST_PAGE_DATA', xIndex: xIndex });
        }
    }
};

const unloadedState: PageFormState = { data: {}, isLoading: false };

export const reducer: Reducer<PageFormState> = (state: PageFormState | undefined, incomingAction: Action): PageFormState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PAGE_DATA': {
            //console.log('REQUEST_PAGE_DATA');
            return {
                xIndex: action.xIndex,
                data: state.data,
                isLoading: true
            };
        }
        case 'UPDATE_PAGE_DATA': {
            //console.log('REQUEST_PAGE_DATA');
            return {
                xIndex: action.xIndex,
                data: state.data,
                isLoading: false
            };
        }
        case 'RECEIVE_PAGE_DATA': {
            //console.log('RECEIVE_PAGE_DATA');
            if (action.xIndex === state.xIndex) {
            //if (action.data) {
                return {
                    xIndex: action.xIndex,
                    data: action.data,
                    isLoading: false
                };
            }
        }
            break;
    }

    return state;
};
