import * as React from 'react';
import { connect } from 'react-redux';
import Loading from '../../Common/Loading';
import $ from 'jquery';
import HtmlSet from '../../Common/HtmlSet';
import * as ActionAdmin from '../../Common/ActionAdmin';
import Utils from '../../Common/Utils';
import ModalForm from '../../Common/ModalForm';
import * as DataManager from '../../Common/DataManager';
import * as HtmlElement from '../../Common/HtmlElement';
import * as Actions from '../../Common/Actions';
import * as PromosStore from '../../../store/Promos';
import Auth from '../../Common/Auth';
import { Field, reduxForm, change, onChange, submit } from 'redux-form';
class PromoCodes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true, modal: [], xdata: false, search: null, xSearch: DataManager.search_args, users_selection: [], users_selected: false, age_selected: false, gender_selected: false,
            project_selection: [], project_selected: false, user: Auth.GetUser()
        }

        this.search = () => {
            let input_val = document.getElementById('search_name').value;
            var q = [];
            if (DataManager.checkNotNull(input_val))
                q.push('doc=' + input_val);
            if (this.state.age_selected && this.state.age_selected.value > 0) {
                q.push('age=' + this.state.age_selected.value);
            }
            if (this.state.gender_selected && DataManager.checkNotNull(this.state.gender_selected.value)) {
                q.push('gen=' + this.state.gender_selected.value);
            }
            if (this.state.users_selected && !DataManager.isGuidEmpty(this.state.users_selected.value)) {
                q.push('uid=' + this.state.users_selected.value);
            }
            if (this.state.project_selected && !DataManager.isGuidEmpty(this.state.project_selected.value)) {
                q.push('proj=' + this.state.project_selected.value);
            }
            this.props.history.push(`/admin/promocodes/1/${q.join('||')}`);
        }
        this.clear = () => {
            this.props.match.params.search = undefined;
            this.setState({
                search: {}, xSearch: false, age_selected: false, project_selected: false,
                gender_selected: false, users_selected: false
            });
            this.props.change('users', false);
            this.props.change('gender', false);
            this.props.change('position', false);
            this.props.change('ages', false);
            this.props.change('project', false);
            $('input').val('');
            //this.loadUsers();
            this.props.history.push(`/admin/promocodes`);
        }
        this.getQstr = (s = null) => {
            if (DataManager.checkNotNull(s)) {
                let search = DataManager.searchQstr(s);
                if (search) {
                    this.setState({ search: search });
                    return search;
                }
            } else {
                this.setState({ search: {} });
            }
            return false;
        }
        this.selectedProject = (e, val) => {
            this.setState({ project_selected: val });
            //this.loadUsers('r=' + val.value);
            console.log(val);
        }
        this.selectedUsers = (e, val) => {
            this.setState({ users_selected: val });
            //this.refs.select_user.props.loadOptions(' ');
            //this.refs.select_user.props.defaultOptions = true;
            console.log(this.refs.select_user);
        }
        this.selectedAges = (e, val) => {
            this.setState({ age_selected: val });
            //this.refs.select_user.props.loadOptions(' ');
            //this.refs.select_user.props.defaultOptions = true;
            console.log(this.refs.select_user);
        }
        this.selectedGender = (e, val) => {
            this.setState({ gender_selected: val });
            //this.refs.select_user.props.loadOptions(' ');
            //this.refs.select_user.props.defaultOptions = true;
            console.log(this.refs.select_user);
        }
        
    }

    submit = (vals) => {
        Utils.HideModalFormTable(vals);

        let is_reload = vals && vals.id && vals.id != 0 ? false : true;

        this.setState({ loading: true });
        ActionAdmin.Post_Model(ActionAdmin.Models.PromoCode, vals).then(data => {
            if (data.status === 200) {
                let xdata = this.state.xdata;
                const i = xdata.data.findIndex((obj => obj.id == vals.id));
                xdata.data[i] = vals;
                this.setState({
                    loading: false,
                    xdata: xdata
                });
                if (is_reload) this.loadData(this.state.xIndex);
            } else {
                this.setState({ frmeror: data.Message ? data.Message : data.title });
                Utils.ShowModalFormTable(vals);
            }

        }).then((x) => this.setState({
            loading: false
        }));
        return;
    }
    loadProject(uid = false) {
        Actions.DropDown_List(ActionAdmin.Models.Promos, null, (uid ? 'uid=' + uid : false)).then(xdata => {
            this.setState({ project_selection: xdata, project_selected: false });
        });
    }
    loadUsers() {
        //val
        Actions.DropDown_List(ActionAdmin.Models.Users).then(xdata => {
            this.setState({ users_selection: xdata, users_selected: false });
        });
    }
    getAges() {
        let ages = [{ value: false, label: 'All..' }];
        for (var i = 1; i <= 10; i++) {
            ages.push({ value: i, label: ((i - 1) * 10) + '-' + (i * 10) });
        }

        return ages;
    }
    Download() {
        let search = this.getQstr(this.props.match.params.search);
        let model_name = ActionAdmin.Models.PromoCode;
        let file_name = model_name + '_' + DataManager.Guid() + '.xlsx';
        this.setState({ loading: true });
        ActionAdmin.Get_file(model_name, file_name, search).then(xdata => {
            console.log(xdata.size);
            if (xdata && xdata.size > 2500) {
                let url = window.URL.createObjectURL(xdata);
                //console.log(url);
                let a = document.createElement('a');
                a.href = url;
                a.download = file_name;
                a.click();
            } else {
                alert('ไม่สามารภดาวน์โหลดได้');
            }
        }).then(x => { this.setState({ loading: false }); });
    }
    deleteData(data) {
        console.log(data)
        let is_reload = false;
        ActionAdmin.Delete_Model(ActionAdmin.Models.PromoCode, data.id).then(xdata => {
            console.log(xdata);
            if (xdata && xdata.status === 200) {
                // ok
                is_reload = true;
            }
            if (is_reload) this.loadData(this.state.xIndex);
        });
    }

    linkDept(data) {
        this.props.history.push('/admin/department/1/comp=' + data.id);
    }
    linkPost(data) {
        this.props.history.push('/admin/position/1/comp=' + data.id);
    }




    componentDidMount() {
        const xIndex = this.state.xIndex || 1;
        let search = this.getQstr(this.props.match.params.search);
        ActionAdmin.Get_List(ActionAdmin.Models.PromoCode, xIndex, search).then(data => {
            if (data.status === 200) {
                this.setState({
                    loading: false,
                    xIndex: xIndex,
                    xdata: data,
                    search: this.props.match.params.search,
                    xSearch: search
                });
            }
        }).then((x) => this.setState({
            loading: false
        }));

        if (Auth.IsAdmin(this.state.user)) {
            this.loadUsers();
            this.loadProject();
        } else {
            this.loadProject(this.state.user.id);
        }
    }

    promoCodeRender(promoCode) {
        let promos = [];
        promos[0] = promoCode.substring(0, 4);
        promos[1] = promoCode.substring(4, promoCode.length);
        return <><b>{promos[0]}</b>{promos[1]}</>;
    }
    renderName(data) {

        if (data.step > 0 && data.personInfo) {
            if (DataManager.checkNotNull(data.personInfo.first_name) && DataManager.checkNotNull(data.personInfo.last_name))
                return <>{data.personInfo.first_name + ' ' + data.personInfo.last_name}</>;
            else if (DataManager.checkNotNull(data.personInfo.alias_name))
                return <>{data.personInfo.alias_name + ' ' + data.personInfo.group_name}</>;
            else
                return data.personInfo.first_name;
        } else
            return <></>;

    }

    renderDataTable(props) {
        let is_admin = Auth.IsAdmin(props.user);
        let current_project = null;
        if (!is_admin) {
            current_project = props.user.packageInfo.projectInfo;
            //console.log('current_project:' + current_project.id);
        }
        if (props && props.xdata && props.xdata.data) {
            return (<tbody>
                {props.xdata.data.map((data) => {
                    let Change_status = data.is_active === true ? < button type="button" className="btn btn-danger btn-sm" onClick={() => { if (window.confirm('คุณต้องการลบข้อมูล หรือไม่?')) { this.deleteData(data); } }

                    }> <i className="fa fa-times"></i> Remove </button > : < button type="button" className="btn btn-success btn-sm" onClick={() => { if (window.confirm('คุณต้องการตั้งค่าข้อมูลเป็น Active หรือไม่?')) { this.deleteData(data); } }
                    }> <i className="fa fa-toggle-on">   </i> Active </button >;

                    //console.log('project_id:' + data.project_id);
                    if (!is_admin && (current_project && current_project.id == data.project_id)) {
                        //oki
                    } else if (!is_admin)
                        Change_status = null;

                    return <tr key={data.id} id={`rowno${data.id}`}>
                        <td>{this.promoCodeRender(data.code)}</td>
                        <td>{data.is_active === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times"></i>}</td>
                        <td>{data.is_used === true ? <>{DataManager.dateTimeToInput(data.used_date, true, false)}</> : <>-</>}</td>
                        <td>{data.step > 0 ? <><b className={data.step > 2 ? "text-success" : "text-primary"}>{data.step}</b> ({DataManager.dateTimeToInput(data.step_date, true, false)})</> : <>-</>}</td>
                        <td>{this.renderName(data)}</td>
                        <td>{data.projectInfo.name}</td>

                        <td className="text-center center">
                            <div className="btn-toolbar center">
                                <button type="button" className="btn btn-success btn-sm mr-1" onClick={() => this.updateShowData(data)}><i className="fa fa-eye"></i> ดู</button>

                                {Change_status}
                            </div>
                        </td>
                    </tr>
                })}
            </tbody>);
        } else {
            return (<tbody>
                <tr>
                    <th colSpan="10"><p>Not found</p></th>
                </tr>
            </tbody>);
        }
    }

    loadData(xIndex, s = false) {
        ActionAdmin.Get_List(ActionAdmin.Models.PromoCode, xIndex, s).then(data => {
            if (data.status === 200) {
                this.setState({
                    xdata: data,
                    xIndex: xIndex
                    //xSearch: nextProps.match.params.search
                });
            }
            console.log(data);
        }).then((x) => this.setState({ loading: false }));
    }

    componentWillReceiveProps(nextProps) {
        const xIndex = nextProps.match.params.xIndex || 0;
        if ((xIndex === 0 || nextProps.match.params.xIndex === this.state.xIndex) && (nextProps.match.params.search === this.state.search)) {
            this.setState({
                search: nextProps.match.params.search
            });
            return;
        } else if ((xIndex === 0 || nextProps.match.params.xIndex === this.state.xIndex) && Utils.isEmptyObject(nextProps.match.params.search) && (nextProps.match.params.search === this.state.search))
            return;

        this.setState({
            loading: true
        });
        //console.log('xIndex:' + xIndex);
        //console.log('params.xIndex:' + nextProps.match.params.xIndex);
        //console.log('Prop:' + nextProps.match.params.search);
        //console.log('Search:' + this.state.search);
        ActionAdmin.Get_List(ActionAdmin.Models.PromoCode, xIndex, this.getQstr(nextProps.match.params.search)).then(data => {
            if (data.status === 200) {
                this.setState({
                    xdata: data,
                    xIndex: xIndex,
                    search: nextProps.match.params.search,
                    xSearch: this.getQstr(nextProps.match.params.search)
                });
            }
        }).then((x) => this.setState({ loading: false }));



    }

    updateShowData(data) {
        //console.log(data);
        if (!data.dataModel || !data.dataModel.person_info_id || DataManager.isGuidEmpty(data.dataModel.person_info_id) || data.step < 3) {
            alert('ข้อมูลไม่สมบูรณ์'); return;
        }
        this.props.history.push('/admin/cuzprofile/' + data.dataModel.person_info_id);

        //let modal = [];
        //modal['title'] = data.name;
        //modal['msg'] = <table class="table table-hover table-borderless table-data-list">
        //    <tbody>


        //        <tr>
        //            <th scope="row">Promocodes :</th>
        //            <td>{data.code == null ? '-' : data.code}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">ID :</th>
        //            <td>{data.id == null ? '-' : data.id}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">Used date - Step date:</th>
        //            <td>{DataManager.dateTimeToInput(data.used_date, false, false)} - {DataManager.dateTimeToInput(data.step_date, false, false)}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">Step :</th>
        //            <td>{data.step == null ? '-' : data.step}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">Useable :</th>
        //            <td>{data.is_active === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-secondary"></i>}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">Is used :</th>
        //            <td>{data.is_used === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-secondary"></i>}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">Code :</th>
        //            <td>{data.code}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">Description :</th>
        //            <td>{data.description == null ? '-' : data.description}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">Person Info :</th>
        //            <td>{data.personInfo == null ? '-' : `${data.personInfo.first_name} ${data.personInfo.last_name}`}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">Project name :</th>
        //            <td>{data.projectInfo == null ? '-' : data.projectInfo.name}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">Period date :</th>
        //            <td>{data.projectInfo == null ? '-' : DataManager.dateTimeToInput(data.projectInfo.start_date, false, false)} - {DataManager.dateTimeToInput(data.projectInfo.end_date, false, false)}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">Project Code :</th>
        //            <td>{data.projectInfo == null ? '-' : data.projectInfo.code}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">Useable :</th>
        //            <td>{data.projectInfo && data.projectInfo.is_active === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-secondary"></i>}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">Path :</th>
        //            <td>{data.projectInfo == null ? '-' : data.projectInfo.path}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">Quantity :</th>
        //            <td>{data.projectInfo == null ? '-' : data.projectInfo.quantity}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">Project type :</th>
        //            <td>{data.projectInfo == null ? '-' : data.projectInfo.project_type}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">Description :</th>
        //            <td>{data.projectInfo == null ? '-' : data.projectInfo.description}</td>
        //        </tr>
        //        <tr>
        //            <th scope="row">Remark :</th>
        //            <td>{data.projectInfo == null ? '-' : data.projectInfo.remark}</td>
        //        </tr>
        //    </tbody>
        //</table>;

        //this.setState({ modal: modal });
        //$("#comonn-modal").modal({ show: true });
    }
    renderOptionUser(state) {
        if (Auth.IsAdmin(state.user)) {
            return (
                <div className="form-group">
                    <label className="col-lg-12 text-left control-label col-form-label">Admin</label>
                    <div className="col-lg-12">
                        <span className="width-100">
                            <Field id="select-users" name="users" className="custom-users" required="required"
                                onChange={this.selectedUsers} multi={false} options={this.state.users_selection}
                                component={HtmlElement.RenderSelectInput} aria-describedby="input-users" />
                        </span>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="form-group">
                    <label className="col-lg-12 text-left control-label col-form-label">Promo admin:</label>
                    <div className="col-lg-12">
                        <b className="text-info">{state.user.alias_name}</b>
                    </div>
                </div>
            );
        }
    }

    render() {

        return (<>
            <div className="card">
                <div className="card-body"><Loading loading={this.state.loading} txt={true} />
                    <h5 className="card-title card-title-border text-secondary"><i className="fa fa-cog" aria-hidden="true"></i> Promocodes</h5>
                    <div className="row">
                        <div className="col-sm-2 border-right margin-bottom-20">
                            <div className="row">
                                <div className="col-12">
                                    <button type="button" className="btn btn-primary mb-2 mr-1 hidden" onClick={() => this.updateFrm({})}><i className="fa fa-plus"></i> เพิ่มข้อมูล</button>
                                    <button type="button" className="btn btn-secondary mb-2 mr-1" onClick={() => this.Download({})} data-toggle="tooltip" data-placement="right" title="กรุณาค้นหาก่อนทำการดาวน์โหลด"> <i className="fa fa-download">  </i>  Download </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 margin-bottom-20">
                            <div className="row">
                                <div className="col-sm-6">
                                    {this.renderOptionUser(this.state)}
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="col-lg-12 text-left control-label col-form-label">Promos</label>
                                        <div className="col-lg-12">
                                            <span className="width-100">
                                                <Field id="select-project" name="project" className="custom-project" required="required"
                                                    onChange={this.selectedProject} multi={false} options={this.state.project_selection}
                                                    component={HtmlElement.RenderSelectInput} aria-describedby="input-project" />
                                            </span>
                                        </div>
                                    </div>
                                </div>                                
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="col-lg-12 text-left control-label col-form-label">เพศ</label>
                                        <div className="col-lg-12">
                                            <span className="width-100">
                                                <Field id="select-gender" name="gender" className="custom-gender" required="required"
                                                    onChange={this.selectedGender} multi={false} options={DataManager.getGender()}
                                                    component={HtmlElement.RenderSelectInput} aria-describedby="input-company" />
                                            </span>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="col-lg-12 text-left control-label col-form-label"> กลุ่มอายุ </label>
                                        <div className="col-lg-12">
                                            <span className="width-100">
                                                <Field id="select-ages" name="ages" className="custom-ages" required="required"
                                                    onChange={this.selectedAges} multi={false} options={this.getAges()}
                                                    component={HtmlElement.RenderSelectInput} aria-describedby="input-company" />

                                            </span>
                                        </div>

                                    </div>

                                </div>
                            </div>
                       
                        </div>
                        <div className="col-sm-4">
                            <div className="row">
                                <div className="col-sm-12 text-right">
                                    <div className="form-group">
                                        <label className="col-lg-12 text-left control-label col-form-label">Search</label>
                                        <div className="col-lg-12">
                                            <div className="mb-2">
                                                <label for="search_name" className="sr-only">Search</label>
                                                <input type="text" className="form-control" id="search_name" placeholder="Search..." defaultValue={this.state.xSearch.doc} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 text-right">
                                    <div className="form-group">
                                        <label className="col-lg-12 text-left control-label col-form-label">  </label>
                                        <div className="col-lg-12">
                                            <button type="button" className="btn btn-primary mb-2 mr-1" onClick={this.search}><i className="fa fa-search"></i> Search</button>
                                            <button type="button" className="btn btn-secondary mb-2" onClick={this.clear}><i className="fa fa-refresh"></i> Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                   
                    {this.state.modal}

                    <table className="table table-sm table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Promo Code</th>
                                <th scope="col">Useable</th>
                                <th scope="col">Used date</th>
                                <th scope="col">Step / Step date</th>
                                <th scope="col">Alias name</th>
                                <th scope="col">Promo name</th>
                                <th scope="col" className="btn-col-2"></th>

                            </tr>
                        </thead>
                        {this.state.loading && !this.state.xdata ? <tbody><tr><td colSpan="10"><p>Loading...</p></td></tr></tbody> : this.renderDataTable(this.state)}
                    </table>
                    <div className="row">
                        <div className="col-sm-12 float-right">
                            <div className="dataTables_paginate paging_simple_numbers float-right padding-right" id="zero_config_paginate">
                                {this.state.loading && !this.state.xdata ? 'Looking for data...' : HtmlSet.renderPagination(this.state, '/admin/promocodes/', this.props.match.params.search)}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {HtmlElement.RenderModal(this.state.modal, <i className="fa fa-info text-primary" aria-hidden="true"></i>, 'modal-xl')}
        </>
        );
    }
}
PromoCodes = reduxForm({
    form: "Promo_Codes",
    enableReinitialize: true
})(PromoCodes);
PromoCodes = connect(
    (state, props) => ({
        initialValues: state.project.data
    }), PromosStore.actionCreators
)(PromoCodes);



export default connect()(PromoCodes);