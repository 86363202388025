import * as React from 'react';


export default class Footer extends React.PureComponent<{}> {
    getYear = () => {
        const y = new Date().getFullYear();
        if (y === 2019) return y;
        return (<span>2019-{y}</span>);
    }
    public render() {
        return (
            <footer className="footer-section ">		
                <div className="footer-buttom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 order-2 order-lg-1 p-0">
                                <div className="copyright">
                                    <img src="/img/hero/logo.png" className="foot-logo" /> Copyright ©{this.getYear()} All rights reserved &#10026; <a href="https://www.wintegrate99.com" target="_blank">www.wintegrate99.com</a> &#10026; Line: @wintegrate99
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
	        </footer>

        );
    }
}