import React, { Component } from 'react';
import { connect } from 'react-redux';
import Utils from '../../Common/Utils';
import $ from 'jquery';
import * as HtmlElement from '../../Common/HtmlElement';
import * as Actions from '../../Common/Actions';
import * as ActionAdmin from '../../Common/ActionAdmin';
import { Field, reduxForm, change, onChange, submit} from 'redux-form';
import * as DataManager from '../../Common/DataManager';
//import selectOption from '../../Common/selectOption';
import Inputmask from "inputmask";
import { Bar, Chart } from 'react-chartjs-2';
import Select from 'react-select';
//import * as jsPDF from 'jspdf';
import moment from 'moment';
import Auth from '../../Common/Auth';
//import * as html2canvas from 'html2canvas';
import ModalForm from '../../Common/ModalForm';
import Loading from '../../Common/Loading';
import '../../../templates/admin/flexed.css';

var C2S = require('canvas2svg');
const add = (a, b) => a + b;

let labels = ['1', '2', '3', '4', '5'];

function tweakLib(){
    C2S.prototype.getContext = function (contextId) {
        if (contextId == "2d" || contextId == "2D") {
            return this;
        }
        return this;
    }

    C2S.prototype.style = function () {
        return this.__canvas.style
    }
    C2S.prototype.__parseFont = function () {
        return this.__parseFont
    }

    C2S.prototype.getAttribute = function (name) {
        return this[name];
    }

    C2S.prototype.addEventListener = function (type, listener, eventListenerOptions) {
        console.log("canvas2svg.addEventListener() not implemented.")
    }
}


class ProjectReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: Auth.GetUser(), frmeror: null, is_search: false,
            loading: true, xdata: { gender: {}, dept: {}, post: {} }, companies: {},
            props: props, package_selection: [],
            company_selection: [], company_selected: false,
            project_selection: [], project_selected: false,
            project: false
            , search: false,
            modal: { title: '', msg: '' },
            chart_options: {
                data: {
                    labels: this.getLabel(),
                    pointBackgroundColor: "rgba(220,220,220,1)",
                    datasets: []
                },
                options: {
                    spanGaps: true,
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: {
                        display: true,                        
                        labels: {
                            fontColor: "black"
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                //stepSize: 5,
                                max: 100,
                                fontColor: 'black',
                                callback: function (value) {
                                    return value + "%"
                                }
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                fontColor: 'black'
                            },
                        }]
                    }
                },
                options_zone: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                fontColor: 'black'
                            },
                        }],
                        xAxes: [{
                            ticks: {
                                fontColor: 'black'
                            },
                        }]
                    },
                    legend: {
                        display: true,
                        labels: {
                            fontColor: "black",
                            fontSize: this.getFontSize(),
                        }
                    }
                }
            }
        }
        //this.select_user = React.createRef();
        this.getUsers = (input) => {
            let r = this.state.company_selected && this.state.company_selected.value ? { company_id: this.state.company_selected.value } : '';
            return Actions.DropDown_List(ActionAdmin.Models.Users, input, DataManager.serializeToQstr(r)).then(xdata => {
                return xdata
            });
        }
        this.selectedPackage = (val, e) => {
            console.log(val);
        }
        this.selectedCompany = (val, e) => {
            this.setState({ company_selected: val });
            this.loadProject(val.value);
        }
        this.selectedProject = (val, e) => {
            this.setState({ project_selected: val });
            if (!this.state.company_selected || !this.state.company_selected.value) {
                if (this.state.company_selection.length > 0) {
                    if (typeof this.refs.search_company != 'undefined' && this.refs.search_company) {
                        //let comp = this.oadCompanyByProject(val.value);
                        this.refs.search_company.value = this.loadCompanyByProject(val.value).value;
                    }
                }
            }
            //console.log(this.refs.select_user);
        }

        this.selectedUser = (e, val) => {
            console.log(val);
        }
        
        


        this.search = () => {
            //console.log(this.state.company_selected);
            //console.log(this.state.project_selected);
            let search = {};
            if (this.state.company_selected && this.state.company_selected.value)
                search['comp'] = this.state.company_selected.value;
            if (this.state.project_selected && this.state.project_selected.value)
                search['proj'] = this.state.project_selected.value;
            this.setState({ is_search: true });
            this.ensureDataFetched(search);
        }
        this.clear = () => {
            this.setState({ project_selected: false, company_selected: false, is_search: false });
            if (typeof this.refs.search_company != 'undefined' && this.refs.search_company)
                this.refs.search_company.value = {};
            this.loadProject();
            this.refs.search_project = {};
            this.ensureDataFetched();
        }

        this.getRenderData = () => {
            let overall = [];
            overall['f'] = document.getElementById("overall_f");
            overall['a'] = document.getElementById("overall_a");
            overall['s'] = document.getElementById("overall_s");
            overall['t'] = document.getElementById("overall_t");
            overall['en'] = document.getElementById("overall_en");
            let gender = document.getElementById("chart_gender");

            let departments = [];
            if (this.state.companies && this.state.companies.department && this.state.companies.department.length > 0) {
                for (var ix in this.state.companies.department) {
                    departments[ix] = {
                        header: $(`#deparntment_name${ix}`).text(), data: {
                            f: document.getElementById(`deparntment_f${ix}`).toDataURL("image/png"),
                            a: document.getElementById(`deparntment_a${ix}`).toDataURL("image/png"),
                            s: document.getElementById(`deparntment_s${ix}`).toDataURL("image/png"),
                            t: document.getElementById(`deparntment_t${ix}`).toDataURL("image/png"),
                            en: document.getElementById(`deparntment_en${ix}`).toDataURL("image/png")
                        }
                    }
                }
            }

            let positions = [];
            if (this.state.companies && this.state.companies.position && this.state.companies.position.length > 0) {
                for (var ix in this.state.companies.position) {
                    positions[ix] = {
                        header: $(`#position_name${ix}`).text(), data: {
                            f: document.getElementById(`position_f${ix}`).toDataURL("image/png"),
                            a: document.getElementById(`position_a${ix}`).toDataURL("image/png"),
                            s: document.getElementById(`position_s${ix}`).toDataURL("image/png"),
                            t: document.getElementById(`position_t${ix}`).toDataURL("image/png"),
                            en: document.getElementById(`position_en${ix}`).toDataURL("image/png")
                        }
                    }
                }
            }

            let companies = [];
            if (this.state.companies && this.state.companies.bycompany && this.state.companies.bycompany.length > 0) {
                for (var ix in this.state.companies.bycompany) {
                    companies[ix] = {
                        header: $(`#company_name${ix}`).text(), data: {
                            f: document.getElementById(`company_f${ix}`).toDataURL("image/png"),
                            a: document.getElementById(`company_a${ix}`).toDataURL("image/png"),
                            s: document.getElementById(`company_s${ix}`).toDataURL("image/png"),
                            t: document.getElementById(`company_t${ix}`).toDataURL("image/png"),
                            en: document.getElementById(`company_en${ix}`).toDataURL("image/png")
                        }
                    }
                }
            }

            //var a = document.createElement('a');
            //a.href = input.toDataURL("image/png");
            //a.download = 'somefilename.png';
            //a.click();

            //let graphData = { options: this.state.chart_options.options_zone, type: 'bar', data: this.state.companies.company.zone_f };
            //tweakLib();
            //graphData['options']['responsive'] = false;
            //graphData['options']['animation'] = false;
            //var svgContext = C2S(500, 500);
            //var mySvg = new Chart(svgContext, graphData);
            //console.log(svgContext.getSerializedSvg(true));

            let over_all = null;
            let gender_main = null;
            if (overall['f'] && overall['a'] && overall['s'] && overall['t'] && overall['f'] && overall['en'])
                over_all = { header: "Over all", data: { f: overall['f'].toDataURL("image/png"), a: overall['a'].toDataURL("image/png"), s: overall['s'].toDataURL("image/png"), t: overall['t'].toDataURL("image/png"), en: overall['en'].toDataURL("image/png") } };

            if (gender)
                gender_main = { header: 'Table of gender', table: $('#table_gender').html(), body: gender.toDataURL("image/png") };

            let company = null;
            let project = null;
            let project_id = DataManager.Guid(false);;
            if (this.state.is_search && this.state.company_selected && this.state.company_selected.value)
                company = this.state.company_selected.label;
            else if (this.state.user.company_Employee && this.state.user.company_Employee.company_Info)
                company = this.state.user.company_Employee.company_Info.name;
            if (this.state.is_search && this.state.project_selected && this.state.project_selected.value) {
                project = this.state.project_selected.label;
                project_id = this.state.project_selected.value;
            }

            return { gender: gender_main, overall: over_all, departments: departments, positions: positions, companies: companies, Company_name: company, Project_name: project, project_id: project_id };
        }

        this.savePdf = () => {
            

            this.setState({ loading: true });
            let model_name = "MasterReport";
            let file_name = model_name + '_' + moment(new Date()).format('YYYY-MM-DD_HHmmss') + '.pdf';
            ActionAdmin.Report(model_name, this.getRenderData()).then(xdata => {
                if (xdata) {
                    let url = window.URL.createObjectURL(xdata);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = file_name;
                    a.click();
                }
            }).then(x => { this.setState({ loading: false }); });

            //-----------------------------
//            this.setState({ loading: true });
//            const input = document.getElementById('card_body');

            
//html2canvas(input)
//    .then((canvas) => {
//        const imgData = canvas.toDataURL('image/png');
//                    var imgWidth = 210;
//                    var pageHeight = 295;
//                    var imgHeight = canvas.height * imgWidth / canvas.width;
//                    var heightLeft = imgHeight;
//                    var doc = new jsPDF('p', 'mm');
//                    var position = 0;

//                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
//                    heightLeft -= pageHeight;

//                    while (heightLeft >= 0) {
//                        position = heightLeft - imgHeight;
//                        doc.addPage();
//                        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
//                        heightLeft -= pageHeight;
//                    }
//                    doc.save("FASTEn_saved.pdf");


//                    this.setState({ loading: false });
//                });


//            //html2canvas(input)
//            //    .then((canvas) => {
//            //        const imgData = canvas.toDataURL('image/png');
//            //        const pdf = new jsPDF("p", "mm", "a4");

//            //        const imgProps = pdf.getImageProperties(imgData);
//            //        console.log('W:' + imgProps.width);
//            //        console.log('H:' + imgProps.height);
//            //        var width = pdf.internal.pageSize.getWidth(); //input.width;//
//            //        var height = pdf.internal.pageSize.getHeight();
//            //        const pdfHeight = (imgProps.height * width) / imgProps.width;

//            //        pdf.addImage(imgData, 'PNG', 0, 0, width, pdfHeight);
//            //        pdf.save("FASTEn_saved.pdf");
//            //        this.setState({ loading: false });
//            //    });
            
        }

        this.submitMail = (val)=>{
            this.setState({ frmeror: null });
            if (!val || !val.email) {
                this.setState({ frmeror: 'กรุณาระบุที่อยู่อีเมล์' });
                return;
            }

            this.setState({ loading: true });
            $("#common-modal-form").modal('hide');
            let req_data = this.getRenderData();
            req_data['email'] = val.email;

            let model_name = "MailMasterReport";
            ActionAdmin.Report(model_name, req_data, true).then(xdata => {
                if (xdata && xdata.status === 200) {
                    alert('ส่งข้อมูลสำเร็จ กรุณาดูข้อความในอีเมล์');
                } else {
                    //this.setState({ frmeror: 'ไม่สามารถส่งอีเมล์ได้ #2 กรุณาลองใหม่อีกครั้ง' });
                    console.log(xdata);
                    alert('ไม่สามารถส่งอีเมล์ได้ #2 กรุณาลองใหม่อีกครั้ง');
                }
            }).then(x => { this.setState({ loading: false }); });

            ////if (!this.state.GuestData || !this.state.GuestData.person_Info) {
            ////    this.setState({ frmeror: 'ไม่สามารถส่งอีเมล์ได้ #1 กรุณาลองใหม่อีกครั้ง' });
            ////    alert(this.state.frmeror);
            ////    return;
            ////}
            //this.setState({ loading: true });
            //Actions.SentMail({ id: this.state.user.id, email: val.email }).then(data => {
            //    if (data.status === 200) {
            //        alert('ส่งข้อมูลสำเร็จ กรุณาดูข้อความในอีเมล์');
            //    } else {
            //        this.setState({ frmeror: 'ไม่สามารถส่งอีเมล์ได้ #2 กรุณาลองใหม่อีกครั้ง' });
            //        alert(this.state.frmeror);
            //    }
            //    this.setState({ loading: false });
            //});
        }
    }

    getLabel() {
        return ['1', '2', '3', '4', '5'];
    }

    componentDidMount() {
        this.ensureDataFetched();
        Inputmask("99/99/9999 99:99").mask(document.getElementsByClassName("imask-datetime"));

        if (Auth.IsAdmin(this.state.user)) {
            //this.loadPackage();
            this.loadCompany();
            this.loadProject();
        } else {
            if (this.state.user.company_Employee)
            this.loadProject(this.state.user.company_Employee.company_Info.id);
        }
        console.log('W:' + $(window).width());
    }

    // This method is called when the route parameters change
    componentDidUpdate(prevProps) {
        if (this.props.pristine && !prevProps.pristine) {
            //this.setState({ company_selected: false });
        }
        if (prevProps.match.params.search && !this.state.search) {
            this.ensureDataFetched();
        }
    }
    ensureDataFetched(search = false) {
        this.setState({ loading: true });
        ActionAdmin.Get_Report(ActionAdmin.Models.Ages, search).then(xdata => {
            if (xdata && xdata.status === 200 && xdata.data) {
                let labels = [];
                let cx = 0;
                for (var n = 10; n <= 100; n += 10) {
                    labels[cx] = '' + (n - 10) + '-' + n + '';
                    cx++;
                }
                if (xdata.data && xdata.data.gender)
                this.setState({
                    xdata: {
                        gender: { labels: labels, pointBackgroundColor: xdata.data.gender[0].backgroundColor, datasets: xdata.data.gender },
                        gender_percentage: { labels: labels, pointBackgroundColor: xdata.data.gender_percentage[0].backgroundColor, datasets: xdata.data.gender_percentage },
                        dept: { labels: labels, datasets: xdata.data.departments},
                        post: { labels: labels, datasets: xdata.data.position }
                    },
                    search: search
                });
            } else
                this.setState({
                    xdata: {},
                    search: search
                });
        });

        ActionAdmin.Get_Report(ActionAdmin.Models.FASTEn_Company, search).then(xdata => {
            if (xdata && xdata.status === 200) {
                if (xdata.data && xdata.data.company)
                this.setState({
                    companies: {
                        company: xdata.data.company,
                        department: xdata.data.department,
                        bycompany: xdata.data.bycompany,
                        position: xdata.data.position
                    },
                    search: search
                });
            } else
                this.setState({
                    companies: {
                        company: {},
                        department: {},
                        bycompany: {},
                        position: {}
                    },
                    search: search
                });
            this.setState({ loading: false });
        });

    }
    getFontSize() {
        if ($(window).width() < 580)
            return 18;
        else if ($(window).width() < 800) {
            return 16;
        } else
            return 14;
    }
    loadPackage() {
        Actions.DropDown_List(ActionAdmin.Models.Package).then(xdata => {
            this.setState({ package_selection: xdata });
        });
    }
    loadCompany() {
        Actions.DropDown_List(ActionAdmin.Models.Company).then(xdata => {
            this.setState({ company_selection: [{ value: false, label: 'All..' },...xdata], company_selected: false });
        });
    }
    loadProject(val) {
        Actions.DropDown_List(ActionAdmin.Models.Project, null, val ? 'r=' + val : false).then(xdata => {
            this.setState({ project_selection: xdata, project_selected: false });
        });
    }
    loadCompanyByProject(val) {
        return Actions.DropDown_List(ActionAdmin.Models.CompanyByProject,val).then(xdata => {
            this.setState({ company_selected: xdata[0] });
            return xdata[0];
        });
    }
    openFrm() {
        $("#common-modal-form").modal({ show: true });
    }
    renderEmailFrm() {
        return <div className="form-group row">
            <label className="col-sm-3 text-right control-label col-form-label">E-mail:</label>
            <div className="col-sm-9">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="input-email"><i className="fa fa-envelope"></i></span>
                    </div>
                    <Field className="form-control" name="email" placeholder="" component="input" type="email" aria-describedby="input-email" required />
                </div>
            </div>
        </div>;
    }
    submitMail(val) {
        this.submitMail(val);
    }

    renderTable(dataset,id) {
        //return <></>;
        if (!dataset)
            return <h2 classNam="text-secondary">Data not found</h2>;
        var html = `<table id="${id}" class="table table-sm table-hover">`;
        html += `<thead><tr><th>Age</th>`;

        let summ = [];
        var columnCount = 0;
        let last_row = '<tr class="text-bold"><td>Total</td>';
        $.each(dataset.datasets, function (idx, item) {
            html += '<th style="background-color:' + item.fillColor + ';">' + item.label + '</th>';
            columnCount += 1;
            summ[idx] = item.data.reduce((a, b) => a + b, 0);
            last_row += '<td>' + summ[idx] + '</td>';
        });
        html += '<th>Total</th>';
        html += '</tr></thead><tbody>';

        $.each(dataset.labels, function (idx, item) {
            html += '<tr><td>' + item + '</td>';
            let sum = 0;
            for (var i = 0; i < columnCount; i++) {
                html += '<td style="background-color:' + dataset.datasets[i].fillColor + ';">' + (dataset.datasets[i].data[idx] === '0' ? '-' : dataset.datasets[i].data[idx]) + '</td>';
                sum = sum + dataset.datasets[i].data[idx];
            }
            html += '<td>' + sum + '</td>';
            html += '</tr>';
        });
        last_row += '<td>' + summ.reduce((a, b) => a + b, 0) + '</td></tr>';
        html += last_row;
        html += '</tbody></table>';

        return <div className="consent-info-text" dangerouslySetInnerHTML={{ __html: html }} />;
    }
    renderChart_Position(state) {
        let charts = [];
        if (state.companies && state.companies.position && state.companies.position.length > 0) {
            for (var ix in state.companies.position) {
                charts.push(<>
                    <div key={`title${ix}`} id={`position_name${ix}`} className="header-info-sm text-left">{state.companies.position[ix].name}</div>
                    <div key={`rowxharts${ix}`} className="d-flex flex-wrap">
                        <div className="card-body w-lg-20 x5-col">                            
                                <div className="watermark-chart hidden">&nbsp;</div>
                                    <Bar id={`position_f${ix}`} data={state.companies.position[ix].report.zone_f} options={this.state.chart_options.options_zone} className="" height={100} width={100} />
                        </div>
                        <div className="card-body w-lg-20 x5-col">
                                <div className="watermark-chart hidden">&nbsp;</div>
                                    <Bar id={`position_a${ix}`} data={state.companies.position[ix].report.zone_a} options={this.state.chart_options.options_zone} className="" height={100} width={100} />
                        </div>
                        <div className="card-body w-lg-20 x5-col">
                                <div className="watermark-chart hidden">&nbsp;</div>
                                    <Bar id={`position_s${ix}`} data={state.companies.position[ix].report.zone_s} options={this.state.chart_options.options_zone} className="" height={100} width={100} />
                        </div>
                        <div className="card-body w-lg-20 x5-col">
                                <div className="watermark-chart hidden">&nbsp;</div>
                                    <Bar id={`position_t${ix}`} data={state.companies.position[ix].report.zone_t} options={this.state.chart_options.options_zone} className="" height={100} width={100} />
                        </div>
                        <div className="card-body w-lg-20 x5-col">
                                <div className="watermark-chart hidden">&nbsp;</div>
                                    <Bar id={`position_en${ix}`} data={state.companies.position[ix].report.zone_en} options={this.state.chart_options.options_zone} className="" height={100} width={100} />
                        </div>
                    </div></>);
            }
            return <><div class="border-top border-secondary"></div><div className="header-info text-dark text-left">By Position</div>{charts}</>;
        }
        return <></>;
    }

    renderOptionComapny(state) {
        if (Auth.IsAdmin(state.user)) {
            return (                
                <div class="form-group">
                    <label for="select-company">Organization</label>
                    <div className="width-100">
                        <Select ref="search_company" options={this.state.company_selection} id="select-company" name="company" className="custom-input" multi={false} placeholder="เลือก..." onChange={this.selectedCompany} value={this.state.company_selected} defaultOptions={true} aria-describedby="input-company" />
                    </div>
                </div>
            );
        } else {
            return (
                <div class="form-group">
                    <label>Organization : </label>
                    <div>
                        <b className="text-info">{state.user.company_Employee ? state.user.company_Employee.company_Info.name : '-'}</b>
                    </div>
                </div>
                );
        }
    }


    renderChart_Department(state) {
        let charts = [];
        if (state.companies && state.companies.department && state.companies.department.length > 0) {
            for (var ix in state.companies.department) {
                charts.push(<>
                    <div key={`title${ix}`} id={`deparntment_name${ix}`} className="header-info-sm text-left">{state.companies.department[ix].name}</div>
                    <div key={`rowxharts${ix}`} className="d-flex flex-wrap">
                        <div className="card-body w-lg-20 x5-col">
                                <div className="watermark-chart hidden">&nbsp;</div>                               
                               <Bar id={`deparntment_f${ix}`} data={state.companies.department[ix].report.zone_f} options={this.state.chart_options.options_zone} className="" height={100} width={100} />
                        </div>
                        <div className="card-body w-lg-20 x5-col">
                                <div className="watermark-chart hidden">&nbsp;</div>
                                    <Bar id={`deparntment_a${ix}`} data={state.companies.department[ix].report.zone_a} options={this.state.chart_options.options_zone} className="" height={100} width={100} />

                        </div>
                        <div className="card-body w-lg-20 x5-col">
                                <div className="watermark-chart hidden">&nbsp;</div>
                                    <Bar id={`deparntment_s${ix}`} data={state.companies.department[ix].report.zone_s} options={this.state.chart_options.options_zone} className="" height={100} width={100} />

                        </div>
                        <div className="card-body w-lg-20 x5-col">
                                <div className="watermark-chart hidden">&nbsp;</div>
                                    <Bar id={`deparntment_t${ix}`} data={state.companies.department[ix].report.zone_t} options={this.state.chart_options.options_zone} className="" height={100} width={100} />

                        </div>
                        <div className="card-body w-lg-20 x5-col">
                                <div className="watermark-chart hidden">&nbsp;</div>
                                    <Bar id={`deparntment_en${ix}`} data={state.companies.department[ix].report.zone_en} options={this.state.chart_options.options_zone} className="" height={100} width={100} />

                        </div>
                    </div></>);
            }
            return <><div class="border-top border-secondary"></div><div className="header-info text-dark text-left">By Department</div>{charts}</>;
        }
        return <></>;
    }
    renderChart_Company(state) {
        let charts = [];
        if (state.companies && state.companies.bycompany && state.companies.bycompany.length > 0) {
            for (var ix in state.companies.bycompany) {
                charts.push(<>
                    <div key={`title${ix}`} id={`company_name${ix}`} className="header-info-sm text-left">{state.companies.bycompany[ix].name}</div>
                    <div key={`rowxharts${ix}`} className="d-flex flex-wrap">
                        <div className="card-body w-lg-20 x5-col">
                                <div className="watermark-chart hidden">&nbsp;</div>
                                    <Bar id={`company_f${ix}`} data={state.companies.bycompany[ix].report.zone_f} options={this.state.chart_options.options_zone} className="" height={100} width={100} />
                        </div>
                        <div className="card-body w-lg-20 x5-col">
                                <div className="watermark-chart hidden">&nbsp;</div>
                                    <Bar id={`company_a${ix}`} data={state.companies.bycompany[ix].report.zone_a} options={this.state.chart_options.options_zone} className="" height={100} width={100} />
                        </div>
                        <div className="card-body w-lg-20 x5-col">
                                <div className="watermark-chart hidden">&nbsp;</div>
                                    <Bar id={`company_s${ix}`} data={state.companies.bycompany[ix].report.zone_s} options={this.state.chart_options.options_zone} className="" height={100} width={100} />
                        </div>
                        <div className="card-body w-lg-20 x5-col">
                                <div className="watermark-chart hidden">&nbsp;</div>
                                    <Bar id={`company_t${ix}`} data={state.companies.bycompany[ix].report.zone_t} options={this.state.chart_options.options_zone} className="" height={100} width={100} />
                        </div>
                        <div className="card-body w-lg-20 x5-col">
                                <div className="watermark-chart hidden">&nbsp;</div>
                                    <Bar id={`company_en${ix}`} data={state.companies.bycompany[ix].report.zone_en} options={this.state.chart_options.options_zone} className="" height={100} width={100} />
                        </div>
                    </div></>);
            }
            return <><div class="border-top border-secondary"></div><div className="header-info text-dark text-left">By Company</div>{charts}</>;
        }
        return <></>;
    }

    renderChart_gender(state) {
        if (state.xdata && state.xdata.gender) {
            return <Bar id="chart_gender" data={state.xdata.gender_percentage} options={state.chart_options.options} className="" height={90} width={90} />

        } else if (state.loading)
            return <h6>Looking for data</h6>;
        else
            return <h6>Data not found</h6>;
    }

    renderCahrt_AllPost(state) {
        if (state.xdata && state.xdata.post && state.xdata.post.length > 0)
        return (<><div class="border-top border-secondary"></div>
            <div className="row">
                <div className="col-sm-4">
                    <div className="header-info text-center">Count of position</div>
                    <div className="relative-elm">
                        <div className="watermark-chart hidden">&nbsp;</div>
                        <div className="chart-container chart-data">
                            {this.renderTable(state.xdata.post)}
                        </div>
                    </div>
                </div>
                <div className="col-sm-8">
                    <div className="header-info text-center">All positions</div>
                    <div className="relative-elm">
                        <div className="watermark-chart hidden">&nbsp;</div>
                            <Bar data={state.xdata.post} options={state.chart_options.options} className="" height={50} width={100} />
                    </div>
                </div>
            </div>
            </>);
        else
            return <></>;
    }
    renderCahrt_AllPost(state) {
        if (state.xdata && state.xdata.dept && state.xdata.dept.length > 0)
            return (<><div class="border-top border-secondary"></div>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="header-info text-center">Count of department</div>
                        <div className="relative-elm">
                            <div className="watermark-chart hidden">&nbsp;</div>
                            <div className="chart-container chart-data">
                                {this.renderTable(state.xdata.dept)}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-8">
                        <div className="header-info text-center">All departments</div>
                        <div className="relative-elm">
                            <div className="watermark-chart hidden">&nbsp;</div>
                                <Bar data={state.xdata.dept} options={state.chart_options.options} className="" height={50} width={100} />
                        </div>
                    </div>
                </div>
            </>);
        else
            return <></>;
    }
    renderChart_OverAll(state) {
        if (state.companies && state.companies.company && state.companies) {
            return (<><div class="border-top border-secondary"></div>
                <div className="header-info text-dark text-left">Over all</div>
                <div className="d-flex flex-wrap">
                    <div className="card-body w-lg-20 x5-col">
                        <div className="header-info-sm text-left hidden">F-zone</div>
                        <div>
                            <div className="watermark-chart hidden">&nbsp;</div>
                                <Bar id="overall_f" data={this.state.companies.company.zone_f} options={this.state.chart_options.options_zone} className="" height={100} width={100} />
                        </div>
                    </div>
                    <div className="card-body w-lg-20 x5-col">
                        <div className="header-info-sm text-left hidden">A-zone</div>
                        <div>
                            <div className="watermark-chart hidden">&nbsp;</div>
                            <div id="" className="">
                                <Bar id="overall_a" data={state.companies.company.zone_a} options={this.state.chart_options.options_zone} className="" height={100} width={100} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body w-lg-20 x5-col">
                        <div className="header-info-sm text-left hidden">S-zone</div>
                        <div>
                            <div className="watermark-chart hidden">&nbsp;</div>
                                <Bar id="overall_s" data={state.companies.company.zone_s} options={this.state.chart_options.options_zone} className="" height={100} width={100} />
                        </div>
                    </div>
                    <div className="card-body w-lg-20 x5-col">
                        <div className="header-info-sm text-left hidden">T-zone</div>
                        <div>
                            <div className="watermark-chart hidden">&nbsp;</div>
                            <div id="" className="">
                                <Bar id="overall_t" data={state.companies.company.zone_t} options={this.state.chart_options.options_zone} className="" height={100} width={100} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body w-lg-20 x5-col">
                        <div className="header-info-sm text-left hidden">En-zone</div>
                        <div>
                            <div className="watermark-chart hidden">&nbsp;</div>
                                <Bar id="overall_en" data={state.companies.company.zone_en} options={this.state.chart_options.options_zone} className="" height={100} width={100} />
                        </div>
                    </div>
                </div>
            </>);
        } else
            return <></>;
    }
 
    render() {
        const { handleSubmit, onChange, pristine, reset, submitting  } = this.props;
        return (
            <div className="card">
                <div className="card-body" id="card_body"><Loading loading={this.state.loading} />
                    <div className="padding-2"></div>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="header-info text-center">Organization info</div>
                            {this.renderOptionComapny(this.state)}
                            <div class="form-group">
                                <label for="select-company">Project</label>
                                <span className="width-100">
                                    <Select ref="search_project" options={this.state.project_selection} id="select-project" name="project" className="custom-input" multi={false} placeholder="เลือก..." onChange={this.selectedProject} value={this.state.project_selected} defaultOptions={true} aria-describedby="input-project" />
                                </span>
                                <small class="form-text hidden text-muted">Projects</small>
                            </div>
                            <div class="form-group">
                                <div class="text-center">
                                    <button type="button" class="btn btn-lg btn-primary mr-2" onClick={() => this.search()}><i className="fa fa-search"></i> Search</button>
                                    <button type="button" class="btn btn-lg btn-secondary" onClick={() => this.clear()}><i className="fa fa-times"></i> Clear</button>
                                </div>
                            </div>
                            <div className="padding-10"></div>
                            <div class="bottom-align-text">
                                <div class="text-center">
                                    <small className="text-info">กรุณากดปุ่ม "Search" เพื่อ filter ข้อมูล</small>
                                    <br />
                                    <button type="button" class="btn btn-outline-secondary" onClick={this.openFrm}><i className="fa fa-envelope"></i> E-mail</button>
                                    <br /><br />
                                    <button type="button" class="btn btn-outline-success mr-2" onClick={() => this.savePdf()}><i className="fa fa-download text-secondary"></i> Download</button>                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="header-info text-center">Count of Gender</div>
                            <div className="relative-elm">
                                <div className="watermark-chart hidden">&nbsp;</div>
                                <div className="chart-container chart-data">
                                    {this.renderTable(this.state.xdata.gender,'table_gender')}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="header-info text-center">Gender chart</div>
                            <div className="relative-elm">
                                <div className="watermark-chart hidden">&nbsp;</div>
                                {this.renderChart_gender(this.state)}
                            </div>
                        </div>
                    </div>
                    
                    {this.renderCahrt_AllPost(this.state)}
                    {this.renderChart_OverAll(this.state)}
                    {this.renderChart_Company(this.state)}
                    {this.renderChart_Department(this.state)}
                    {this.renderChart_Position(this.state)}
                </div>


                {HtmlElement.RenderModal(this.state.modal)}
                <ModalForm form="Form_Modal" error={this.state.frmeror} btnName="ส่งข้อมูล" body={this.renderEmailFrm()} title={`ส่งอีเมล์/Send e-mail`} onSubmit={this.submitMail} />
            </div>
        );
    }
}

export default connect()(ProjectReport);
