import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../Common/Loading';
import $ from 'jquery';
import HtmlSet from '../Common/HtmlSet';
import * as ActionAdmin from '../Common/ActionAdmin';
import Utils from '../Common/Utils';
import ModalForm from '../Common/ModalForm';
import Auth from '../Common/Auth';
import * as DataManager from '../Common/DataManager';
import E404 from '../E404';
import { Field, reduxForm } from 'redux-form';
import * as Actions from '../Common/Actions';
import HtmlElement, {getPageDescription} from '../Common/HtmlElement';
import MetaTags from 'react-meta-tags';
class Pro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true, modal: null, xdata: false, foud: false, page: {}, xIndex: false, msg: false, msg_user: false, msg_admin: false,
        }
    }
    submit = (values) => {
        this.setState({ msg: false, msg_admin: false, msg_user:false });
        const xIndex = this.props.match.params.xIndex ? this.props.match.params.xIndex : (this.state.xIndex ? this.state.xIndex : 0);
        if (!xIndex || !DataManager.checkNotNull(xIndex) || xIndex === 0) {
            return;
        }
        values['id'] = xIndex;
        values['logon'] = this.state.xdata.project_type;
        let is_error = true;
        if (values && values.type) {
            if (values.type === 'admin') {
                if ((values.Username && values.Username.length > 5) && (values.Password && values.Password.length > 5)) {
                    is_error = false;
                }
            } else if (values.type === 'code') {
                if ((values.Code && values.Code.length > 5)) {
                    is_error = false;
                }
            }
        }
        if (is_error === false) {
            this.setState({ loading: true });
            Actions.ProjectAuth(values).then(xdata => {
                if (xdata.status === 200) {
                    //console.log('OK');
                    Auth.Login(xdata.data);
                    if (values.type === 'admin')
                        window.location.href = '/admin';
                    else
                        window.location.href = '/';
                    return;
                } else {
                    if (values.type === 'admin')
                        this.setState({ msg_admin: this.errMsg(xdata.message ? xdata.message : xdata.Message) });
                    else
                        this.setState({ msg_user: this.errMsg(xdata.message ? xdata.message : xdata.Message) });
                    this.setState({ loading: false });
                }
                this.setState({ loading: false });
            });//.then((x) =>this.setState({ loading: false }));
        } else {
            if (values.type === 'admin')
                this.setState({ msg_admin: this.errMsg('ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบข้อมูล'), loading: false });
            else
                this.setState({ msg_user: this.errMsg('ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบข้อมูล'), loading: false });
        }
    }

    componentDidMount() {
        const id = this.props.match.params.xIndex ? this.props.match.params.xIndex : (this.state.xIndex ? this.state.xIndex : 0);
        //console.log(id);
        this.ensureDataFetched(id);
    }

    ensureDataFetched(xIndex = false) {
        if (!xIndex || !DataManager.checkNotNull(xIndex) || xIndex === 0) {
            this.setState({
                foud: false
            });
            return;
        }

        this.setState({
            loading: true
        });

        

        ActionAdmin.Get(ActionAdmin.Models.ProjectClient, { id: xIndex }).then(xdata => {
            //console.log(xdata);
            if (xdata && xdata.status === 200 && xdata.data) {
                this.setState({
                    xdata: xdata.data,
                    foud: true
                });
                if(DataManager.checkNotNull(xdata.data.name))
                    document.title = xdata.data.name;
                ActionAdmin.Get(ActionAdmin.Models.Pro, { id: xdata.data.id, type: 'project' }).then(xdata => {
                    //console.log(xdata);
                    if (xdata && xdata.status === 200 && xdata.data) {
                        this.setState({
                            page: xdata.data
                        });
                    } else {
                        this.setState({
                            page: {}
                        });
                    }
                });

            } else {
                this.setState({
                    xdata: null,
                    foud: false
                });
            }
        }).then((x) => this.setState({
            loading: false
        }));
    }
    errMsg(msg) {
        return (
            <div id="alert-msg" className="alert alert-warning alert-dismissible fade show text-left" role="alert">
                <strong><i className="fa fa-exclamation-circle" aria-hidden="true"></i></strong> {msg}
                {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button> */}
            </div>
        );
    }

    renderPage(state) {
        if (!state.page || !state.page.name) return <></>;
        return <>
            <MetaTags>
                <title>{state.page.name}</title>
                <meta name="description" content={state.page.name+','+ getPageDescription()} />
                <meta property="og:title" content={state.page.name} />
            </MetaTags>
            <div className="row">
            <div className="col-md">
                <div className="padding-left-30 header-info text-secondary"><h2 className="text-secondry">{state.page.name}</h2></div>
                <div className="consent-info-text" dangerouslySetInnerHTML={{ __html: state.page.description }} />
            </div>
        </div>
            <hr/>
            </>;
    }
    renderProjectUserFrm(state) {        
        if (state.xdata) {
            //if(DataManager.checkNotNull(state.xdata.name))
            //    document.title = state.xdata.name;
            if (state.xdata.project_type === 'Project') {
                const { handleSubmit } = this.props;     
                return (<div className="card login-form border-info">
                    <MetaTags>
                        <title>{state.xdata.name}</title>
                        <meta name="description" content={state.xdata.name +', '+ getPageDescription()} />
                        <meta property="og:title" content={state.xdata.name} />
                    </MetaTags>
                    <div className="card-header bg-info"><i className="fa fa-sign-in" aria-hidden="true"></i> Project user</div>
                    <div className="card-body">
                        <div>
                            <label htmlFor="Code">CODE:</label>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="iCode"><i className="fa fa-user-o" aria-hidden="true"></i></span>
                                </div>
                                <Field component="input" id="Code" autoComplete="off" name="Code" type="text" className="form-control" placeholder="XXXXXXXX" aria-describedby="iCode" />
                            </div>

                            <div className="text-center">
                                <button type="button" onClick={handleSubmit(values =>
                                    this.submit({
                                        ...values,
                                        type: 'code'
                                    }))} className="btn btn-dark"><i className="fa fa-unlock-alt" aria-hidden="true"></i> Login</button>
                            </div>

                        </div>
                        <div className="padding-5"></div>
                        <div className="text-danger text-center">{this.state.msg_user}</div>
                    </div>
                </div>);
            } else if (state.xdata.id) {
                return (<div className="card login-form border-info">
                    <MetaTags>
                        <title>Promo - {state.xdata.name}</title>
                        <meta name="description" content={state.xdata.name +', '+ getPageDescription()} />
                        <meta property="og:title" content={state.xdata.name} />
                    </MetaTags>
                    <div className="card-header bg-info"><i className="fa fa-info" aria-hidden="true"></i> Promos info</div>
                    <div className="card-body">
                        <h6><b className="text-secondary">Promos: </b> {state.xdata.name}</h6>
                        <div><b className="text-secondary">Start: </b> {DataManager.dateTimeToInput(state.xdata.start_date, true, false)}</div>
                        <div><b className="text-secondary">End: </b> {DataManager.dateTimeToInput(state.xdata.end_date, true, false)}</div>
                        <div>
                    </div>
                    </div>
                </div>);
            }
        } else {
            return <h6>Looking for data</h6>;
        }
    }
    /*
                     <div className="row">
                    <div className="col-sm">
                        <div className="padding-5"></div>
                        <div className="text-danger text-center">{this.state.msg}</div>
                    </div>
                </div>
     */

    render() {
        const { handleSubmit } = this.props;
        if (this.state.loading)
            return (<div className="container"><Loading loading={this.state.loading} />
                {this.renderPage(this.state)}
                <div className="row">
                    <div className="col-sm empty-content">
                    </div>
                </div>
            </div>);
         else if(this.state.foud !== true)
            return <E404 />;
        else if (this.state.foud)
        return ( 
            <div className="container"><Loading loading={this.state.loading} />
                {this.renderPage(this.state)}

                <form onSubmit={handleSubmit(this.submit)}>                    
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="padding-5"></div>
                            {this.renderProjectUserFrm(this.state)}                           
                        </div>
                        <div className="col-sm-6">
                            <div className="padding-5"></div>
                            <div className="card login-form border-success">
                                <div className="card-header"><i className="fa fa-sign-in" aria-hidden="true"></i> {this.state.xdata.project_type} admin</div>
                                <div className="card-body">
                                    <div>
                                        <label htmlFor="Username">CODE :</label>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="iUsername"><i className="fa fa-user-o" aria-hidden="true"></i></span>
                                            </div>
                                            <Field component="input" id="Username" name="Username" type="text" className="form-control" placeholder="XXXXXXXX" aria-describedby="iUsername" />
                                        </div>
                                        <label htmlFor="Password">Password:</label>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="iPassword"><i className="fa fa-key" aria-hidden="true"></i></span>
                                            </div>
                                            <Field component="input" id="Password" name="Password" type="password" className="form-control" placeholder="Password" aria-describedby="iPassword" />
                                        </div>
                                        <div className="text-center">
                                            <button type="button" onClick={handleSubmit(values =>
                                                this.submit({
                                                    ...values,
                                                    type: 'admin'
                                                }))} className="btn btn-dark"><i className="fa fa-unlock-alt" aria-hidden="true"></i> Login</button>
                                        </div>
                                    </div>
                                    <div className="padding-5"></div>
                                    <div className="text-danger text-center">{this.state.msg_admin}</div>
                                </div>
                            </div>
                        </div>


                    </div>                    
                </form>
                <div className="space-btm"></div>
            </div>
        );
    }
}

Pro = reduxForm({
    form: 'Form_Pro'
})(Pro)
export default Pro;
