import React, { Component } from 'react';
import { connect } from 'react-redux';
import Utils from './Utils';
import { Field, reduxForm } from "redux-form";

class ModalForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false, props: props
        }
    }
    //<form className="form-horizontal" onSubmit={handleSubmit} >
    componentDidMount() {
        //console.log(this.props);
    }
    componentDidUpdate(props) {
        //console.log(props);
    }
 
    render() {
        //console.log(this.props.xerror);
        const { handleSubmit, onChange } = this.props;
        return (                        
            <div className="modal fade" id="common-modal-form" tabIndex="-1" role="dialog" aria-labelledby="comonn-modal-title" aria-hidden="true">
                <div className={`modal-dialog modal-dialog-centered ${this.props.size}`} role="document">
                    <div className="modal-content">
                    <form className="form-horizontal" onSubmit={handleSubmit} >
                        <div className="modal-header">
                            <h5 className="modal-title" id="comonn-modal-title">
                                {this.props.icon ? this.props.icon : <i className="fa edit" aria-hidden="true"></i>}
                                {this.props.title}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                         <div className="modal-body">
                                {this.props.body}

                                {this.props.xerror || (this.props.initialValues && this.props.initialValues.xerror) ? <div className="alert alert-danger alet-err-message" role="alert"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {
                                    this.props.xerror ? this.props.xerror : (this.props.initialValues && this.props.initialValues.xerror ? this.props.initialValues.xerror : '')
                                }</div> : <></>}
                        </div>
                        <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal"><i className="fa fa-times"></i> Close</button>
                                <button type="submit" className="btn btn-primary"><i className="fa fa-check"></i> {this.props.btnName ? this.props.btnName : 'Save & Changes'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>);
    }
}

ModalForm = reduxForm({
    enableReinitialize: true
})(ModalForm);
//ModalForm = connect(
//    (state, props) => ({
//        initialValues: props.modal.values
//    })
//)(ModalForm);

export default ModalForm;

//const mapStateToProps = (state, props) => ({
//    initialValues: props.initialValues, // retrieve name from redux store 
//})
//export default ModalForm(
//    mapStateToProps
//)(reduxForm({
//    form: 'sixxxmple',
//    enableReinitialize: true
//})(ModalForm))