import React, { Component } from 'react';
import E404 from '../E404';
import ApexCharts from 'apexcharts';
import $ from 'jquery';

class Test extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        var options = {
            series: [{
                name: 'Series 1',
                data: [80, 50, 30, 40, 100, 20],
            }],
            chart: {
                height: 350,
                type: 'radar',
            },
            title: {
                text: 'Basic Radar Chart'
            },
            xaxis: {
                categories: [['January', 'ddddd'], 'February', ['March', 'd'], '<h1>April</h1>', ['May','k'], 'June'],
                labels: {
                    show: true,
                    style: {
                        colors: ["#a8a8a8"],
                          fontSize: "15px",
                          fontFamily: 'Arial',
                        textAlign: 'end',
                        align: 'end',
                        textAnchor: 'end',

                    },
                    offsetX: 0,
                    offsetY: 0,
                    format: function (value) {
                        return '<h5>xxx' + value+'</h5>';
                    }
                    //formatter: function (value) {
                    //    return '<h5>xxx' + value+'</h5>';
                    //}
                },
                position: 'bottom',
            }
        };

        var chart = new ApexCharts(document.querySelector("#chart"), options);
        chart.render();

        var text = $('.apexcharts-xaxis>tspan').html();
        console.log(text);
    }

    render(){
        return (
            <div id="chart"></div>
        );
        }
    }

export default Test;
