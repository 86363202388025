import * as React from 'react';
import { withRouter, Switch, Route,useLocation } from 'react-router';
import Layout from './components/admin/Layout/Layout';
import Auth from './components/Common/Auth';
import Loading from './components/Common/Loading';
import Home from './components/admin/Home';

import Figures from './components/admin/Figures';
import BehaviorEighteen from './components/admin/BehaviorEighteen';
import MainElement from './components/admin/MainElement';
import FastenModel from './components/admin/FastenModel';
import PerceptionRegarding from './components/admin/PerceptionRegarding';
import WorkingOthers from './components/admin/WorkingOthers';
import WorkingAlone from './components/admin/WorkingAlone';
import SpecificationSoulmaid from './components/admin/SpecificationSoulmaid';
import RelationshipParents from './components/admin/RelationshipParents';
import Recommendation from './components/admin/Recommendation';
import Ranking from './components/admin/Ranking';
import E404 from './components/E404';
import CompanyInfo from './components/admin/Company/CompanyInfo';
import Department from './components/admin/Company/Department';
import Position from './components/admin/Company/Position';
import Package from './components/admin/Package/PackageInfo';

import Consent from './components/admin/Consent';
import OptionalField from './components/admin/OptionalField';

//--- Project
import Project from './components/admin/Project/ProjectInfo';
import Project_Frm from './components/admin/Project/ProjectForm';
import PromosList from './components/admin/Project/PromosList';
import PromosForm from './components/admin/Project/PromosForm';
import PromoCodes from './components/admin/Project/PromoCodes';
import ProjectUsing from './components/admin/Project/ProjectUsing';
import PageForm from './components/admin/PageForm';
import ProjectUsingForm from './components/admin/Project/ProjectUsingForm';
import Cuzprofile from './components/admin/Project/CuzProfile';
import PersonData from './components/admin/Project/PersonData';

//--- Country
import Province from './components/admin/Country/Province';
import District from './components/admin/Country/District';
import Country from './components/admin/Country/Country'; 

//--- Reports
import ProjectReport from './components/admin/Project/ProjectReport';
import Logout from './components/Logout';

import User from './components/admin/User';

import './custom.css'
import './templates/public/css/slicknav.min.css';
import './templates/public/css/style.css';
import './templates/admin/sidebar.css';
import './templates/admin/style.css';


const Admin = ({ location }) => {
    if (!Auth.isLoggedIn()) { Auth.Logout(); return (<Loading Loading={true} />) }
    let user = Auth.GetUser();
    if (Auth.IsAdmin(user)) {
        return (<Layout>
            <Switch location={location}>
                <Route exact path='/admin' component={Home} />
                <Route path='/admin/figures/:xIndex?/:search?' component={Figures} />
                <Route path='/admin/behavior18y/:xIndex?/:search?' component={BehaviorEighteen} />
                <Route path='/admin/mainelement/:xIndex?/:search?' component={MainElement} />
                <Route path='/admin/fastenmodel/:xIndex?/:search?' component={FastenModel} />
                <Route path='/admin/perceptionre/:xIndex?/:search?' component={PerceptionRegarding} />
                <Route path='/admin/workingothers/:xIndex?/:search?' component={WorkingOthers} />
                <Route path='/admin/workingalone/:xIndex?/:search?' component={WorkingAlone} />
                <Route path='/admin/specsoulmaid/:xIndex?/:search?' component={SpecificationSoulmaid} />
                <Route path='/admin/relationparents/:xIndex?/:search?' component={RelationshipParents} />
                <Route path='/admin/recommend/:xIndex?/:search?' component={Recommendation} />
                <Route path='/admin/ranking/:xIndex?/:search?' component={Ranking} />
                <Route path='/admin/company/:xIndex?/:search?' component={CompanyInfo} />
                <Route path='/admin/department/:xIndex?/:search?' component={Department} />
                <Route path='/admin/position/:xIndex?/:search?' component={Position} />
                <Route path='/admin/package/:xIndex?/:search?' component={Package} />
                <Route path='/admin/projects/:xIndex?/:search?' component={Project} />
                <Route path='/admin/project/form/:xIndex?' component={Project_Frm} />
                <Route path='/admin/promos/:xIndex?/:search?' component={PromosList} />
                <Route path='/admin/promo/form/:xIndex?' component={PromosForm} />
                <Route path='/admin/consent/:xIndex?' component={Consent} />
                <Route path='/admin/optionalfield/:xIndex?' component={OptionalField} />
                <Route path='/admin/province/:xIndex?/:search?' component={Province} />
                <Route path='/admin/district/:xIndex?/:search?' component={District} />
                <Route path='/admin/country/:xIndex?/:search?' component={Country} />
                <Route path='/admin/promocodes/:xIndex?/:search?' component={PromoCodes} />
                <Route path='/admin/projectuse/:xIndex?/:search?' component={ProjectUsing} />
                <Route path='/admin/projreport/:search?' component={ProjectReport} />
                <Route path='/admin/pageform/:xIndex?' component={PageForm} />
                <Route path='/admin/user' component={User} />
                <Route path='/logout' component={Logout} />
                <Route path='/admin/projuser/:xIndex?' component={ProjectUsingForm} />
                <Route path='/admin/cuzprofile/:xIndex?' component={Cuzprofile} />
                <Route path='/admin/persondata/:xIndex?/:search?' component={PersonData} />
                <Route component={E404} />
            </Switch>
        </Layout>);
    } else if (Auth.IsProjectAdmin(user)) {
        return (<Layout>
            <Switch location={location}>
                <Route exact path='/admin' component={Home} />

                <Route path='/admin/projects/:xIndex?/:search?' component={Project} />              
                <Route path='/admin/projectuse/:xIndex?/:search?' component={ProjectUsing} />
                <Route path='/admin/projuser/:xIndex?' component={ProjectUsingForm} />

                <Route path='/admin/projreport/:search?' component={ProjectReport} />
                <Route path='/admin/cuzprofile/:xIndex?' component={Cuzprofile} />
                <Route path='/logout' component={Logout} />

                <Route component={E404} />
            </Switch>
        </Layout>);
    } else if (Auth.IsPromoAdmin(user)) {
        return (<Layout>
            <Switch location={location}>
                <Route exact path='/admin' component={Home} />

                <Route path='/admin/promos/:xIndex?/:search?' component={PromosList} />
                <Route path='/admin/promocodes/:xIndex?/:search?' component={PromoCodes} />

                <Route path='/admin/projreport/:search?' component={ProjectReport} />
                <Route path='/admin/cuzprofile/:xIndex?' component={Cuzprofile} />
                <Route path='/logout' component={Logout} />

                <Route component={E404} />
            </Switch>
        </Layout>);
    }else {
        return (<Layout>
            <Switch location={location}>
                <Route exact path='/admin' component={Home} />
                <Route path='/logout' component={Logout} />

                <Route component={E404} />
            </Switch>
        </Layout>);

    }
}
export default withRouter(Admin)