import * as React from 'react';
import { connect } from 'react-redux';
import Loading from '../../Common/Loading';
import $ from 'jquery';
import HtmlSet from '../../Common/HtmlSet';
import * as ActionAdmin from '../../Common/ActionAdmin';
import Utils from '../../Common/Utils';
import ModalForm from '../../Common/ModalForm';
import { Field } from "redux-form";
import * as DataManager from '../../Common/DataManager';
class PackageInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true, modal: null, xdata: false, search: null, xSearch: DataManager.search_args, frmeror:false
        }
        this.search = () => {
            let input_val = document.getElementById('search_name').value;
            var q = [];
            if (DataManager.checkNotNull(input_val))
                q.push('doc=' + input_val);

            console.log(q);

            this.props.history.push(`/admin/package/1/${q.join('||')}`);
        }
        this.clear = () => {
            this.props.match.params.search = undefined;
            this.setState({ search: {}, xSearch: false });
            $('input').val('');
            this.props.history.push(`/admin/package`);
        }
        this.getQstr = (s = null) => {
            if (DataManager.checkNotNull(s)) {
                let search = DataManager.searchQstr(s);
                if (search) {
                    this.setState({ search: search });
                    return search;
                }
            } else {
                this.setState({ search: {} });
            }
            return false;
        }
    }

    submit = (vals) => {        
        let is_reload = vals && vals.id && vals.id != 0 ? false : true;
        
        if (!vals.packageConfig || vals.packageConfig.length < 1) {
            vals['xerror'] = 'กรุณาระบุ การตั้งค่าการแสดงข้อมูล';
            this.updateFrm(vals);
            return;
        }    
        
        this.setState({ loading: true });
        Utils.HideModalFormTable(vals);
        ActionAdmin.Post_Model(ActionAdmin.Models.Package, vals).then(data => {
            if (data.status === 200) {                
                let xdata = this.state.xdata;
                const i = xdata.data.findIndex((obj => obj.id == vals.id));
                xdata.data[i] = vals;
                this.setState({
                    loading: false,
                    xdata: xdata
                });  
                if (is_reload) this.loadData(this.state.xIndex);
            } else {
                this.setState({ frmeror: data.Message ? data.Massage : data.title });
                this.updateFrm(vals);
            }            
        }).then((x) => this.setState({
            loading: false
        }));
        return;
    }
    deleteData(data) {
        console.log(data)
        let is_reload = false;
        ActionAdmin.Delete_Model(ActionAdmin.Models.Package, data.id).then(xdata => {
            console.log(xdata);
            if (xdata && xdata.status === 200) {
                // ok
                is_reload = true;
            }
            if (is_reload) this.loadData(this.state.xIndex);
        });
    }
    updateFrm(data) {
        console.log(data);
        data['enabled'] = true;
        if (data && data.packageConfig) {
            data.packageConfig.show_elements = '' + data.packageConfig.show_elements;
        }
        let title = data && data.id ? `แก้ไขข้อมูล #${data.id}` : `เพิ่มข้อมูล #New`;
        this.setState({
            modal: <ModalForm form="Form_Modal" xerror={this.state.frmeror} size="modal-xl" initialValues={data} body={this.renderForm(data)} title={title} onSubmit={this.submit} />
        });        
        Utils.ShowModalFormTable(data);        
        this.onCheckboxChange(data.packageConfig ? data.packageConfig.show_birthchart : false, true);
        
            
    }
    linkDept(data) {
        this.props.history.push('/admin/department/1/comp=' + data.id);
    }
    linkPost(data) {
        this.props.history.push('/admin/position/1/comp=' + data.id);
    }    

    renderForm(data) {
        return (
            <div className="row">
                <div className="col-sm">
                <div className="form-group row required">
                        <label htmlFor="water" className="col-sm-4 control-label col-form-label">ชื่อ Package:</label>
                        <div className="col-sm-8">
                            <Field name="name" component="input" type="text" className="form-control" id="name" required />
                    </div>
                </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label"></label>
                        <div className="col-sm-8">
                            <label className="form-check-label" htmlFor="is_active">
                                <Field name="is_active" component="input" type="checkbox" id="is_active"/> &nbsp;Is active</label>                           
                        </div>                        
                    </div>
                    <div className="form-group row required">
                        <label htmlFor="water" className="col-sm-4 control-label col-form-label">ราคา:</label>
                    <div className="col-sm-8">
                        <Field name="price" component="input" type="number" className="form-control" id="name" required />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="wood" className="col-sm-4 col-form-label">รายละเอียด:</label>
                    <div className="col-sm-8">
                        <Field name="description" component="textarea" type="text" className="form-control" id="description" />
                    </div>
                </div>
            </div>
                <div className="col-sm">
                    <div className="form-group form-check">
                        <div className="col-sm-8">
                            <Field name="packageConfig[show_chart]" component="input" type="checkbox" className="form-check-input" id="show_chart"  />
                            <label className="form-check-label" htmlFor="show_chart">Clean FASTEn model</label>
                        </div>
                    </div>
                    
                    <div className="form-group form-check">
                        <div className="col-sm-8">
                            <Field name="packageConfig[show_verification]" component="input" type="checkbox" className="form-check-input" id="show_verification" />
                            <label className="form-check-label" htmlFor="show_verification">Show verification</label>
                        </div>
                    </div>
                    <div className="form-group form-check">
                        <div className="col-sm-8">
                            <Field name="packageConfig[show_character]" component="input" type="checkbox" className="form-check-input" id="show_character" />
                            <label className="form-check-label" htmlFor="show_character">Complete FASTEn model</label>
                        </div>
                    </div>
                    <div className="form-group form-check">
                        <div className="col-sm-8">
                            <Field name="packageConfig[show_factor]" component="input" type="checkbox" className="form-check-input" id="show_factor" />
                            <label className="form-check-label" htmlFor="show_factor">Show factor</label>
                        </div>
                    </div>
                    <div className="form-group form-check">
                        <div className="col-sm-8">
                            <Field name="packageConfig[show_analysis]" component="input" type="checkbox" className="form-check-input" id="show_analysis" />
                            <label className="form-check-label" htmlFor="show_analysis">Show analysis</label>
                        </div>
                    </div>
                    <div className="form-group form-check">
                        <div className="col-sm-8">
                            <Field name="packageConfig[show_recommendation]" component="input" type="checkbox" className="form-check-input" id="show_recommendation" />
                            <label className="form-check-label" htmlFor="show_recommendation">Show recommendation</label>
                        </div>
                    </div>
                    
                    <div className="form-group form-check">
                        <div className="col-sm-8">
                            <Field name="packageConfig[show_birthchart]" component="input" type="checkbox" className="form-check-input" id="show_birthchart" onChange={(e, checked) => this.onCheckboxChange(checked)} />
                            <label className="form-check-label" htmlFor="show_birthchart">Show birthchart</label>
                        </div>
                    </div>

                    <div className="form-group form-check hidden" id="element_config">
                        <div className="col-sm-8" title="Show all">
                            <Field name="packageConfig[show_elements]" component="input" type="radio" value="8" label="8" className="form-check-input" id="show_elements8"/>
                            <label className="form-check-label" htmlFor="show_elements8">แสดงทั้งหมด</label>
                        </div>
                        <div className="col-sm-8" title="Show 1">
                            <Field name="packageConfig[show_elements]" component="input" type="radio" value="1" label="1" className="form-check-input" id="show_elements1" />
                            <label className="form-check-label" htmlFor="show_elements1">Day upper only</label>
                        </div>
                        <div className="col-sm-8">
                            <Field name="packageConfig[show_elements]" component="input" type="radio" value="2" label="2" className="form-check-input" id="show_elements2"/>
                            <label className="form-check-label" htmlFor="show_elements2">Day upper, Month upper</label>
                        </div>
                        <div className="col-sm-8" title="Show 3">
                            <Field name="packageConfig[show_elements]" component="input" type="radio" value="3" label="3" className="form-check-input" id="show_elements3"/>
                            <label className="form-check-label" htmlFor="show_elements3">Day upper, Month upper, Month lower</label>
                        </div>
                        <div className="col-sm-8" title="Show 4">
                            <Field name="packageConfig[show_elements]" component="input" type="radio" value="4" label="4" className="form-check-input" id="show_elements4"/>
                            <label className="form-check-label" htmlFor="show_elements4">Day Upper, Day Lower, Monther Upper, Month Lower</label>
                        </div>
                        <div className="col-sm-8" title="Show 6">
                            <Field name="packageConfig[show_elements]" component="input" type="radio" value="6" label="6" className="form-check-input" id="show_elements6" />
                            <label className="form-check-label" htmlFor="show_elements6">Day Upper, Day Lower, Monther Upper, Month Lower, Year Upper, Year Lower</label>
                        </div>
                    </div>
                </div>
            </div>
                   
        );
        //checked={data && data.packageConfig && data.packageConfig.show_elements == 3 ? 'checked' : false}
    }

    getShowingElement(no) {
        if (!no || no == null)
            return <i className="fa fa-times text-secondry"></i>;
        else if(no == 8)
            return <><i className="fa fa-check text-success"></i> แสดงทั้งหมด</>;
        else if (no == 1)
            return <small>- Day upper only</small>;
        else if (no == 2)
            return <small>- Day upper<br/>- Month uppe</small>;
        else if (no == 3)
            return <small>- Day upper<br />- Month upper<br />- Month lower</small>;
        else if (no == 4)
            return <small>-Day Upper<br />- Day Lower<br />- Monther Upper<br />- Month Lower</small>;
        else if (no == 6)
            return <small>-Day Upper<br />- Day Lower<br />- Monther Upper<br />- Month Lower<br />-Year Upper<br />- Year Lower</small>;
        else
            return <i className="fa fa-times text-secondry"></i>;
    }

    onCheckboxChange(chk, onRender = false) {
        if (chk === true) {
            if (onRender) {
                setTimeout(function () {
                    $('#element_config').removeClass('hidden');
                }, 500);
            }                
            $('#element_config').fadeIn();
        } else if (chk === false)
            $('#element_config').fadeOut();
        return;
    }

//    <div>
//    <div className="form-group row">
//        <label htmlFor="water" className="col-sm-4 col-form-label">ชื่อบริษัท:</label>
//        <div className="col-sm-8">
//            <Field name="name" component="input" type="text" className="form-control" id="name" />
//        </div>
//    </div>
//    <div className="form-group row">
//        <label htmlFor="wood" className="col-sm-4 col-form-label">รายละเอียด:</label>
//        <div className="col-sm-8">
//            <Field name="description" component="textarea" type="text" className="form-control" id="description" />
//        </div>
//    </div>
//</div>    

    componentDidMount() {
        const xIndex = this.state.xIndex || 1;
        let search = this.getQstr(this.props.match.params.search);
        ActionAdmin.Get_List(ActionAdmin.Models.Package, xIndex, search).then(data => {
            if (data.status === 200) {
                this.setState({
                    loading: false,
                    xIndex: xIndex,
                    xdata: data,
                    search: this.props.match.params.search,
                    xSearch: search
                });
            }
        }).then((x) => this.setState({
            loading: false
        }));
    }

    renderDataTable(props) {
        if (props && props.xdata && props.xdata.data) {
            return (<tbody>
                {props.xdata.data.map((data) => {
                    return <tr key={data.id} id={`rowno${data.id}`}>
                        <td>{data.id}</td>
                        <td>{data.name}</td>                        
                        <td className="text-center">{data.is_active === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-secondry"></i>}</td>
                        <td>{data.description}</td>
                        {data.packageConfig ? <>                            
                            <td className="text-center">{data.packageConfig.show_verification === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-secondry"></i>}</td>
                            <td className="text-center">{data.packageConfig.show_chart === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-secondry"></i>}</td>
                            <td className="text-center">{data.packageConfig.show_character === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-secondry"></i>}</td>
                            <td className="text-center">{data.packageConfig.show_factor === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-secondry"></i>}</td>
                            <td className="text-center">{data.packageConfig.show_analysis === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-secondry"></i>}</td>
                            <td className="text-center">{data.packageConfig.show_recommendation === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-secondry"></i>}</td>
                            <td className="text-center">{data.packageConfig.show_birthchart === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-secondry"></i>}</td>
                            <td className="text-center">{this.getShowingElement(data.packageConfig.show_elements)}</td>
                        </>
                            : <>
                                <td className="text-center"><i className="fa fa-times text-secondry"></i></td>
                                <td className="text-center"><i className="fa fa-times text-secondry"></i></td>
                                <td className="text-center"><i className="fa fa-times text-secondry"></i></td>
                                <td className="text-center"><i className="fa fa-times text-secondry"></i></td>
                                <td className="text-center"><i className="fa fa-times text-secondry"></i></td>
                                <td className="text-center"><i className="fa fa-times text-secondry"></i></td>
                                <td className="text-center"><i className="fa fa-times text-secondry"></i></td>
                                <td className="text-center"><i className="fa fa-times text-secondry"></i></td>
                            </>}
                        <td className="text-center center">
                            <div className="btn-toolbar center">
                            <button type="button" className="btn btn-info btn-sm mr-1" onClick={() => this.updateFrm(data)}><i className="fa fa-edit"></i> Edit</button>
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => { if (window.confirm('คุณต้องการลบข้อมูล หรือไม่?')) { this.deleteData(data); } }}><i className="fa fa-times"></i> Del</button>
                            </div>
                        </td>
                    </tr>
                })}
            </tbody>);
        } else {
            return (<tbody>
                <tr>
                    <th colSpan="10"><p>Not found</p></th>
                </tr>
            </tbody>);
        }
    }

    loadData(xIndex, s = false) {
        ActionAdmin.Get_List(ActionAdmin.Models.Package, xIndex, s).then(data => {
            if (data.status === 200) {
                this.setState({
                    xdata: data,
                    xIndex: xIndex
                    //xSearch: nextProps.match.params.search
                });
            }
        }).then((x) => this.setState({ loading: false }));
    }

    componentWillReceiveProps(nextProps) {
        const xIndex = nextProps.match.params.xIndex || 1;
        if ((xIndex === 0 || nextProps.match.params.xIndex === this.state.xIndex) && !Utils.isEmptyObject(nextProps.match.params.search) && (nextProps.match.params.search === this.state.search)) {
            this.setState({
                search: nextProps.match.params.search
            });
            return;
        } else if ((xIndex === 0 || nextProps.match.params.xIndex === this.state.xIndex) && Utils.isEmptyObject(nextProps.match.params.search) && (nextProps.match.params.search === this.state.search))
            return;

        this.setState({
            loading: true
        });
        ActionAdmin.Get_List(ActionAdmin.Models.Package, xIndex, this.getQstr(nextProps.match.params.search)).then(data => {
            if (data.status === 200) {
                this.setState({
                    xdata: data,
                    xIndex: xIndex,
                    search: nextProps.match.params.search,
                    xSearch: this.getQstr(nextProps.match.params.search)
                });
            }
        }).then((x) => this.setState({ loading: false }));
     
    }

    render() {
        return(
            <div className="card">                
                <div className="card-body"><Loading loading={this.state.loading} txt={true}/>
                    <h5 className="card-title card-title-border text-secondary"><i className="fa fa-cog" aria-hidden="true"></i> Package managements</h5>
                    <div className="row">
                        <div className="col-sm border-right margin-bottom-20">
                            <div className="row">
                                <div className="col-12">
                                    <button type="button" className="btn btn-primary mb-2 mr-1" onClick={() => this.updateFrm({})}><i className="fa fa-plus"></i> เพิ่มข้อมูล</button>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-sm margin-bottom-20">
                            <div className="form-inline">
                                <div className="form-group mx-sm-3 mb-2">
                                    <label htmlFor="search_name" className="sr-only">Search</label>
                                    <input type="text" className="form-control" id="search_name" placeholder="Search..." defaultValue={this.state.xSearch.doc} />
                                </div>
                                <button type="button" className="btn btn-primary mb-2 mr-1" onClick={this.search}><i className="fa fa-search"></i> Search</button>
                                <button type="button" className="btn btn-secondary mb-2" onClick={this.clear}><i className="fa fa-refresh"></i> Clear</button>
                            </div>
                        </div>
                    </div>


                    {this.state.modal}
                    

                    <table className="table table-sm table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>                                
                                <th scope="col">Is Active</th>
                                <th scope="col">Description</th>                                
                                <th scope="col">Show verification</th>
                                <th scope="col">Clean FASTEn model</th>
                                <th scope="col">Complete FASTEn Model</th>
                                <th scope="col">Show factor</th>
                                <th scope="col">Show analysis</th>
                                <th scope="col">Show recommendation</th>
                                <th scope="col">Show birthchart</th>
                                <th scope="col">Show elements</th>
                                
                                <th scope="col" className="btn-col-2"></th>

                            </tr>
                        </thead>
                        {this.state.loading && !this.state.xdata ? <tbody><tr><td colSpan="10"><p>Loading...</p></td></tr></tbody> : this.renderDataTable(this.state)}
                    </table>
                    <div className="row">
                        <div className="col-sm-12 float-right">
                            <div className="dataTables_paginate paging_simple_numbers float-right padding-right" id="zero_config_paginate">
                                {this.state.loading && !this.state.xdata ? 'Looking for data...' : HtmlSet.renderPagination(this.state, '/admin/package/', this.props.match.params.search)}                                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default connect()(PackageInfo);