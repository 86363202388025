import * as React from 'react';
import { connect } from 'react-redux';
import Loading from '../Common/Loading';
import $ from 'jquery';
import HtmlSet from '../Common/HtmlSet';
import * as ActionAdmin from '../Common/ActionAdmin';
import Utils from '../Common/Utils';
import ModalForm from '../Common/ModalForm';
import { Field } from "redux-form";
import * as DataManager from '../Common/DataManager';
class FastenModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true, modal: null, xdata: false, search: null, xSearch: DataManager.search_args
        }
        this.upload_field = null;

        this.Upload = () => {
            //Upload = (vals)
            //const files_name = Array.from(vals.target.files);
            //files_name[0]
            //console.log(vals);
            //console.log(files_name);
            if (!this.upload_field || !this.upload_field.files || this.upload_field.files.length < 1) {
                alert('No file selected'); return;
            }
            this.setState({ loading: true });
            ActionAdmin.Upload_file(this.upload_field.files[0], ActionAdmin.Models.FastenModel).then(xdata => {
                if (xdata && xdata.status === 200) {
                    this.upload_field.value = null;
                    alert('Upload file completed');
                    this.clear();
                } else {
                    alert('Error: Connot upload');
                }
                this.setState({ loading: false });
            });

        }
        //--- added
        this.search = () => {
            let input_val = document.getElementById('search_name').value;
            var q = [];
            if (DataManager.checkNotNull(input_val))
                q.push('doc=' + input_val);

            console.log(q);

            this.props.history.push(`/admin/fastenmodel/1/${q.join('||')}`);
        }
        this.clear = () => {
            this.props.match.params.search = undefined;
            this.setState({ search: {}, xSearch: false });
            $('input').val('');
            this.props.history.push(`/admin/fastenmodel`);
        }
        this.getQstr = (s = null) => {
            if (DataManager.checkNotNull(s)) {
                let search = DataManager.searchQstr(s);
                if (search) {
                    this.setState({ search: search });
                    return search;
                }
            } else {
                this.setState({ search: {} });
            }
            return false;
        }
    }

    submit = (vals) => {
        Utils.HideModalFormTable(vals);
        this.setState({ loading: true });      
        ActionAdmin.Update_Model(ActionAdmin.Models.FastenModel, vals).then(data => {
            if (data.status === 200) {
                let xdata = this.state.xdata;
                const i = xdata.data.findIndex((obj => obj.id == vals.id));
                xdata.data[i] = vals;
                this.setState({
                    loading: false,
                    xdata: xdata
                });                
            } else {                
                this.setState({ frmeror: data.Message });
                Utils.ShowModalFormTable(vals);
            }
        }).then((x) => this.setState({
            loading: false
        }));        
        return;
    }
    Download() {
        let model_name = ActionAdmin.Models.FastenModel;
        let file_name = model_name + '_' + DataManager.Guid() + '.xlsx';
        this.setState({ loading: true });
        ActionAdmin.Get_file(model_name, file_name).then(xdata => {
            console.log(xdata.size);
            if (xdata && xdata.size > 5000) {
                let url = window.URL.createObjectURL(xdata);
                //console.log(url);
                let a = document.createElement('a');
                a.href = url;
                a.download = file_name;
                a.click();
            } else {
                alert('ไม่สามารภดาวน์โหลดได้');
            }
        }).then(x => { this.setState({ loading: false }); });
    }
    updateFrm(data) {
        this.setState({
            modal: <ModalForm form="Form_Modal" error={this.state.frmeror} initialValues={data} body={this.renderForm(data)} title={`แก้ไขข้อมูล #${data.id}`} onSubmit={this.submit} />
        });
        Utils.ShowModalFormTable(data);
    }

    renderFormTable(data){
        return (<table className="table table-sm table-bordered table-active">
            <thead>
                <tr>                    
                    <th scope="col">F</th>
                    <th scope="col">A</th>
                    <th scope="col">S</th>
                    <th scope="col">T</th>
                    <th scope="col">En</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{data.f}</td>
                    <td>{data.a}</td>
                    <td>{data.s}</td>
                    <td>{data.t}</td>
                    <td>{data.en}</td>
                </tr>
            </tbody>
        </table>);
    }

    renderForm(data) {
        return (
            <div> 
                {this.renderFormTable(data)}
                <hr/>
                <div className="form-group row">
                    <label htmlFor="description_th" className="col-sm-4 col-form-label">Description TH:</label>
                    <div className="col-sm-8">
                        <Field name="description_th" component="textarea" type="text" className="form-control" id="description_th" />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="description_en" className="col-sm-4 col-form-label">Description EN:</label>
                    <div className="col-sm-8">
                        <Field name="description_en" component="textarea" type="text" min="0" max="999" className="form-control" id="description_en" />
                    </div>
                </div>
            </div>           
            );
    }

    componentDidMount() {
        const xIndex = this.state.xIndex || 1;
        let search = this.getQstr(this.props.match.params.search);
        ActionAdmin.Get_List(ActionAdmin.Models.FastenModel, xIndex, search).then(data => {
            if (data.status === 200) {
                this.setState({
                    loading: false,
                    xIndex: xIndex,
                    xdata: data,
                    search: this.props.match.params.search,
                    xSearch: search
                });
            }
        }).then((x) => this.setState({
            loading: false
        }));
    }

    renderDataTable(props) {
        if (props && props.xdata && props.xdata.data) {
            return (<tbody>
                {props.xdata.data.map((data) => {
                    return <tr key={data.id} id={`rowno${data.id}`}>
                        <td>{data.id}</td>
                        <td>{data.f}</td>
                        <td>{data.a}</td>
                        <td>{data.s}</td>
                        <td>{data.t}</td>
                        <td>{data.en}</td>
                        <td>{data.code}</td>
                        <td className="text-danger">{data.description_th}</td>
                        <td className="text-danger">{data.description_en}</td>
                        <td className="text-center"><button className="btn btn-info btn-sm" onClick={() => this.updateFrm(data)}><i className="fa fa-edit"></i> Edit</button></td>
                    </tr>
                })}
            </tbody>);
        } else {
            return (<tbody>
                <tr>
                    <th colSpan="10"><p>Not found</p></th>
                </tr>
            </tbody>);
        }
    }

    componentWillReceiveProps(nextProps) {
        const xIndex = nextProps.match.params.xIndex || 1;
        if ((xIndex === 0 || nextProps.match.params.xIndex === this.state.xIndex) && !Utils.isEmptyObject(nextProps.match.params.search) && (nextProps.match.params.search === this.state.search)) {
            this.setState({
                search: nextProps.match.params.search
            });
            return;
        } else if ((xIndex === 0 || nextProps.match.params.xIndex === this.state.xIndex) && Utils.isEmptyObject(nextProps.match.params.search) && (nextProps.match.params.search === this.state.search))
            return;

        this.setState({
            loading: true
        });


        ActionAdmin.Get_List(ActionAdmin.Models.FastenModel, xIndex, this.getQstr(nextProps.match.params.search)).then(data => {
            if (data.status === 200) {
                this.setState({
                    xdata: data,
                    xIndex: xIndex,
                    search: nextProps.match.params.search,
                    xSearch: this.getQstr(nextProps.match.params.search)
                });
            }
        }).then((x) => this.setState({ loading: false }));
    }

    render() {
        return(
            <div className="card">                
                <div className="card-body"><Loading loading={this.state.loading} txt={true}/>
                    <h5 className="card-title text-secondary card-title-border">8. FASTEn Model</h5>
                    <div className="row">
                        <div className="col-sm border-right margin-bottom-20">
                            <div className="row">
                                <div className="col-8">
                                    <div className="input-group">
                                        <input type="file" name="file" ref={(ref) => this.upload_field = ref} className="form-control" aria-label="Select excel file" placeholder="Upload excel" aria-describedby="basic-addon1" />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary input-group-text" onClick={this.Upload}><i className="fa fa-upload"></i>&nbsp; Upload</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <button type="button" className="btn btn-secondary" onClick={this.Download.bind(this)}><i className="fa fa-download"></i> Download</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm margin-bottom-20">
                            <div className="form-inline">
                                <div className="form-group mx-sm-3 mb-2">
                                    <label for="search_name" className="sr-only">Search</label>
                                    <input type="text" className="form-control" id="search_name" placeholder="Search..." defaultValue={this.state.xSearch.doc} />
                                </div>
                                <button type="button" className="btn btn-primary mb-2 mr-1" onClick={this.search}><i className="fa fa-search"></i> Search</button>
                                <button type="button" className="btn btn-secondary mb-2" onClick={this.clear}><i className="fa fa-refresh"></i> Clear</button>
                            </div>
                        </div>
                    </div>
                    {this.state.modal}
                    <table className="table table-sm table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">F</th>
                                <th scope="col">A</th>
                                <th scope="col">S</th>
                                <th scope="col">T</th>
                                <th scope="col">En</th>
                                <th scope="col">Code</th>
                                <th scope="col" className="text-danger">Description (Thai)</th>
                                <th scope="col" className="text-danger">Description (English)</th>
                                <th scope="col" className="btn-col-1"></th>
                            </tr>
                        </thead>
                        {this.state.loading && !this.state.xdata ? <tbody><tr><td colSpan="10"><p>Loading...</p></td></tr></tbody> : this.renderDataTable(this.state)}
                    </table>
                    <div className="row">
                        <div className="col-sm-12 float-right">
                            <div className="dataTables_paginate paging_simple_numbers float-right padding-right" id="zero_config_paginate">
                                {this.state.loading && !this.state.xdata ? 'Looking for data...' : HtmlSet.renderPagination(this.state, '/admin/fastenmodel/', this.props.match.params.search)}                                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(FastenModel);