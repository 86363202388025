import * as React from 'react';
import { connect } from 'react-redux';
import Loading from '../../Common/Loading';
import $ from 'jquery';
import HtmlSet from '../../Common/HtmlSet';
import * as ActionAdmin from '../../Common/ActionAdmin';
import Utils from '../../Common/Utils';
import ModalForm from '../../Common/ModalForm';
import { Field } from "redux-form";
import * as DataManager from '../../Common/DataManager';
class CompanyInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true, modal: null, xdata: false, search: null, xSearch: DataManager.search_args
        }
        this.search = () => {
            let input_val = document.getElementById('search_name').value;
            var q = [];
            if (DataManager.checkNotNull(input_val))
                q.push('doc=' + input_val);

            console.log(q);

            this.props.history.push(`/admin/company/1/${q.join('||')}`);
        }
        this.clear = () => {
            this.props.match.params.search = undefined;
            this.setState({ search: {}, xSearch: false });
            $('input').val('');
            this.props.history.push(`/admin/company`);
        }
        this.getQstr = (s = null) => {
            if (DataManager.checkNotNull(s)) {
                let search = DataManager.searchQstr(s);
                if (search) {
                    this.setState({ search: search });
                    return search;
                }
            } else {
                this.setState({ search: {} });
            }
            return false;
        }
    }

    submit = (vals) => {
        Utils.HideModalFormTable(vals);

        let is_reload = vals && vals.id && vals.id != 0 ? false : true;

        this.setState({ loading: true });
        ActionAdmin.Post_Model(ActionAdmin.Models.Company, vals).then(data => {
            if (data.status === 200) {
                let xdata = this.state.xdata;
                const i = xdata.data.findIndex((obj => obj.id == vals.id));
                xdata.data[i] = vals;
                this.setState({
                    loading: false,
                    xdata: xdata
                });  
                if (is_reload) this.loadData(this.state.xIndex);
            } else {                
                this.setState({ frmeror: data.Message });
                Utils.ShowModalFormTable(vals);
            }
            
        }).then((x) => this.setState({
            loading: false
        }));
        return;
    }
    deleteData(data) {
        console.log(data)
        let is_reload = false;
        ActionAdmin.Delete_Model(ActionAdmin.Models.Company, data.id).then(xdata => {
            console.log(xdata);
            if (xdata && xdata.status === 200) {
                // ok
                is_reload = true;
            }
            if (is_reload) this.loadData(this.state.xIndex);
        });
    }
    updateFrm(data) {
        let title = data && data.id ? `แก้ไขข้อมูล #${data.id}` : `เพิ่มข้อมูล #New`;
        this.setState({
            modal: <ModalForm form="Form_Modal" error={this.state.frmeror} initialValues={data} body={this.renderForm(data)} title={title} onSubmit={this.submit} />
        });
        Utils.ShowModalFormTable(data);
    }
    linkDept(data) {
        this.props.history.push('/admin/department/1/comp=' + data.id);
    }
    linkPost(data) {
        this.props.history.push('/admin/position/1/comp=' + data.id);
    }    

    renderForm(data) {
        return (
            <div>                 
                <div className="form-group row">
                    <label htmlFor="water" className="col-sm-4 col-form-label">ชื่อบริษัท:</label>
                    <div className="col-sm-8">
                        <Field name="name" component="input" type="text"  className="form-control" id="name" />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="wood" className="col-sm-4 col-form-label">รายละเอียด:</label>
                    <div className="col-sm-8">
                        <Field name="description" component="textarea" type="text" className="form-control" id="description" />
                    </div>
                </div>
                
            </div>           
            );
    }

    componentDidMount() {
        const xIndex = this.state.xIndex || 1;
        let search = this.getQstr(this.props.match.params.search);
        ActionAdmin.Get_List(ActionAdmin.Models.Company, xIndex, search).then(data => {
            if (data.status === 200) {
                this.setState({
                    loading: false,
                    xIndex: xIndex,
                    xdata: data,
                    search: this.props.match.params.search,
                    xSearch: search
                });
            }
        }).then((x) => this.setState({
            loading: false
        }));
    }

    renderDataTable(props) {
        if (props && props.xdata && props.xdata.data) {
            return (<tbody>
                {props.xdata.data.map((data) => {
                    return <tr key={data.id} id={`rowno${data.id}`}>
                        <td>{data.id}</td>
                        <td>{data.name}</td>
                        <td>{data.description}</td>
                        <td><button className="btn btn btn-outline-info btn-sm" onClick={() => this.linkDept(data)}><i className="fa fa-eye"></i> แผนก</button> {data.count_department}</td>
                        <td><button className="btn btn btn-outline-info btn-sm" onClick={() => this.linkPost(data)}><i className="fa fa-eye"></i> ตำแหน่ง</button> {data.count_position}</td>
                        <td className="text-center center">
                            <div className="btn-toolbar center">
                            <button type="button" className="btn btn-info btn-sm mr-1" onClick={() => this.updateFrm(data)}><i className="fa fa-edit"></i> Edit</button>
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => { if (window.confirm('คุณต้องการลบข้อมูล หรือไม่?')) { this.deleteData(data); } }}><i className="fa fa-times"></i> Delete</button>
                            </div>
                        </td>
                    </tr>
                })}
            </tbody>);
        } else {
            return (<tbody>
                <tr>
                    <th colSpan="10"><p>Not found</p></th>
                </tr>
            </tbody>);
        }
    }

    loadData(xIndex, s = false) {
        ActionAdmin.Get_List(ActionAdmin.Models.Company, xIndex,s).then(data => {
            if (data.status === 200) {
                this.setState({
                    xdata: data,
                    xIndex: xIndex
                    //xSearch: nextProps.match.params.search
                });
            }
            console.log(data);
        }).then((x) => this.setState({ loading: false }));
    }

    componentWillReceiveProps(nextProps) {
        const xIndex = nextProps.match.params.xIndex || 1;
        if ((xIndex === 0 || nextProps.match.params.xIndex === this.state.xIndex) && !Utils.isEmptyObject(nextProps.match.params.search) && (nextProps.match.params.search === this.state.search)) {
            this.setState({
                search: nextProps.match.params.search
            });
            return;
        } else if ((xIndex === 0 || nextProps.match.params.xIndex === this.state.xIndex) && Utils.isEmptyObject(nextProps.match.params.search) && (nextProps.match.params.search === this.state.search))
            return;

        this.setState({
            loading: true
        });

        ActionAdmin.Get_List(ActionAdmin.Models.Company, xIndex, this.getQstr(nextProps.match.params.search)).then(data => {
            if (data.status === 200) {
                this.setState({
                    xdata: data,
                    xIndex: xIndex,
                    search: nextProps.match.params.search,
                    xSearch: this.getQstr(nextProps.match.params.search)
                });
            }
        }).then((x) => this.setState({ loading: false }));

     
    }

    render() {
        return(
            <div className="card">                
                <div className="card-body"><Loading loading={this.state.loading} txt={true}/>
                    <h5 className="card-title text-secondary"><i className="fa fa-cog" aria-hidden="true"></i> Organization managements</h5>
                    <div className="row">
                        <div className="col-sm border-right margin-bottom-20">
                            <div className="row">
                                <div className="col-12">
                                    <button type="button" className="btn btn-primary mb-2 mr-1" onClick={() => this.updateFrm({})}> <i className="fa fa-plus"> </i> เพิ่มข้อมูล</button>
                                </div>

                            </div>
                        </div>
                        <div className="col-sm margin-bottom-20">
                            <div className="form-inline">
                                <div className="form-group mx-sm-3 mb-2 text-right">
                                    <label for="search_name" className="sr-only">Search</label>
                                    <input type="text" className="form-control" id="search_name" placeholder="Search..." defaultValue={this.state.xSearch.doc} />
                                </div>
                                <button type="button" className="btn btn-primary mb-2 mr-1" onClick={this.search}><i className="fa fa-search"></i> Search</button>
                                <button type="button" className="btn btn-secondary mb-2" onClick={this.clear}><i className="fa fa-refresh"></i> Clear</button>
                            </div>
                        </div>
                    </div>
                    {this.state.modal}
                   
                    <table className="table table-sm table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                                <th scope="col">Department</th>
                                <th scope="col">Position</th>
                                <th scope="col" className="btn-col-2"></th>
                            </tr>
                        </thead>
                        {this.state.loading && !this.state.xdata ? <tbody><tr><td colSpan="10"><p>Loading...</p></td></tr></tbody> : this.renderDataTable(this.state)}
                    </table>
                    <div className="row">
                        <div className="col-sm-12 float-right">
                            <div className="dataTables_paginate paging_simple_numbers float-right padding-right" id="zero_config_paginate">
                                {this.state.loading && !this.state.xdata ? 'Looking for data...' : HtmlSet.renderPagination(this.state, '/admin/company/', this.props.match.params.search)}                                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



export default connect()(CompanyInfo);