import React, { Component } from 'react';
import { connect } from 'react-redux';
import Utils from '../../Common/Utils';
import $ from 'jquery';
import { ApplicationState } from '../../../store';
import { Field, reduxForm } from "redux-form";
import * as PromosStore from '../../../store/Promos';
import * as HtmlElement from '../../Common/HtmlElement';
import * as Actions from '../../Common/Actions';
import * as ActionAdmin from '../../Common/ActionAdmin';
import * as DataManager from '../../Common/DataManager';
import selectOption from '../../Common/selectOption';
import Inputmask from "inputmask";
import Loading from '../../Common/Loading';

class PromosForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            xIndex: false,
            loading: false, props: props, package_selection: [], company_selection: [], company_selected: false,
            modal: { title: '', msg: '' }, users_selection: []
        }
        //this.getUsers = (input) => {
        //    let r = this.state.company_selected && this.state.company_selected.value ? { company_id: this.state.company_selected.value } : '';
        //    return Actions.DropDown_List(ActionAdmin.Models.Users, input, DataManager.serializeToQstr(r)).then(xdata => {
        //        return xdata
        //    });
        //}
        this.selectedPackage = (e, val) => {
            console.log(val);
        }
        this.selectedCompany = (e, val) => {
            this.setState({ company_selected: val });
            this.loadUsers('company_id=' + val.value);
            this.props.change('user', false);
        }
        this.selectedUser = (e, val) => {
            console.log(val);
        }
        this.loadPackage();
        this.loadCompany();
        this.loadUsers();


        this.submit = (vals)=> {
            var data = DataManager.Project_FormToModel(vals,false);
            this.setState({ modal: { title: '', msg: '' } });
            console.log(data);
            if (data.error && data.error.length > 0) {
                this.setState({ modal: { title: '', msg: <ul className="err-msg text-danger">{data.error}</ul> } });
                $(".modal").modal({ show: true });
                return;
            }
            this.setState({ loading: true });
            ActionAdmin.Post_Model(ActionAdmin.Models.Promos, data).then(xdata => {
                vals.start_date = data.dates.start;
                vals.end_date = data.dates.end;
                if (xdata && xdata.status === 200) {
                    
                    vals['id'] = xdata.data.id;      
                    vals['project_type'] = xdata.data.project_type;
                    //vals['key'] = xdata.data.key;
                    this.props.dispatch({
                        type: 'UPDATE_PROMOS_DATA',
                        xIndex: xdata.data.id,
                        data: vals
                    });                
                    //console.log(xdata);
                    //this.setState({ modal: { title: '', msg: 'บันทึกข้อมูลสำเร็จ' } });
                    //$(".modal").modal({ show: true });
                    //this.props.history.push('/admin/promo/form/' + xdata.data.id);
                    this.props.history.push('/admin/promos');
                } else {
                    this.setState({ frmeror: xdata.Message ? xdata.Message : xdata.message, loading: false });
                    this.setState({ modal: { title: 'ไม่สามารถบันททึกข้อมูลได้', msg: <ul className="err-msg text-danger">กรุณาลองใหม่อีกครั้ง<li>{xdata.Message ? xdata.Message : xdata.message}</li></ul> } });
                    $(".modal").modal({ show: true });
                }
                data = {};
            }).then((x) => this.setState({
                loading: false
            }));
        }
    }

    
    componentDidMount() {
        this.ensureDataFetched();    
        Inputmask("99/99/9999 99:99").mask(document.getElementsByClassName("imask-datetime"));
        //Inputmask("99:99").mask(document.getElementsByClassName("imask-time"));
    }

    // This method is called when the route parameters change
    componentDidUpdate(prevProps) {
        if (this.props.pristine && !prevProps.pristine) {
            this.setState({ company_selected: false });
        }
        if (prevProps.match.params.xIndex && prevProps.match.params.xIndex != this.state.xIndex)
            this.ensureDataFetched();
    }
    ensureDataFetched() {
        const xIndex = this.props.match.params.xIndex || 0;
        if (xIndex && xIndex != 0) {
            this.setState({ xIndex: xIndex });
            this.props.requestData(xIndex);
        } else {
            this.props.dispatch({
                type: 'UPDATE_PROMOS_DATA',
                xIndex: null,
                data: {}
            });  
        }
    }
    loadPackage() {
        Actions.DropDown_List(ActionAdmin.Models.Package).then(xdata => {
            this.setState({ package_selection: xdata });       
        });
    }
    loadCompany() {
        Actions.DropDown_List(ActionAdmin.Models.Company).then(xdata => {
            this.setState({ company_selection: xdata, company_selected: false });
        });
    }
    loadUsers() {
        Actions.DropDown_List(ActionAdmin.Models.UserPromo).then(xdata => {
            this.setState({ users_selection: xdata });
        });
    }

    renderQuntity(props) {
        console.log('xxxx--xxx');
        console.log(props.initialValues.id);
        console.log(this.props.match.params.xIndex);
        console.log(this.state);
        if (props.initialValues && props.initialValues.id) {
            if (this.props.match.params.xIndex === props.initialValues.id) {
                return <div className="form-group row">
                    <label htmlFor="quantity" className="col-sm-4 text-right control-label col-form-label">Quantity:</label>
                    <div className="col-sm-8">
                        {props.initialValues.quantity}
                    </div>
                </div>
            } else
                return <div className="form-group row">
                    <label htmlFor="quantity" className="col-sm-4 text-right control-label col-form-label">Quantity:</label>
                    <div className="col-sm-8">
                        -
                    </div>
                </div>
        }else
            return <div className="form-group row required">
                <label htmlFor="quantity" className="col-sm-4 text-right control-label col-form-label">Quantity:</label>
                <div className="col-sm-8">
                    <Field name="quantity" component="input" type="number" className="form-control" id="quantity" required min="1" />
                </div>
            </div>
        
    }
    
 
    render() {
        const { handleSubmit, onChange, pristine, reset, submitting  } = this.props;
        return (
            <>
            <form className="form-horizontal" onSubmit={handleSubmit(this.submit)} >
                    <div class="card"><Loading loading={this.state.loading} txt={true} />
                        <div class="card-body">
                            <h5 className="card-title card-title-border text-secondary"><i className="fa fa-pencil" aria-hidden="true"></i> Promos form</h5>
                <div class="row">
                    <div class="col-sm">                    
                        
                            <div className="form-group row required">
                                        <label htmlFor="name" className="col-sm-4 text-right control-label col-form-label">Name:</label>
                                <div className="col-sm-8">
                                    <Field name="name" component="input" type="text" className="form-control" id="name" required />
                                </div>
                                    </div>
                                    {this.renderQuntity(this.props)}

                                    <div className="form-group row required">
                                        <label htmlFor="start_date" className="col-sm-4 text-right control-label col-form-label">Start:</label>
                                        <div className="col-sm-8">
                                            <Field name="start_date" component="input" type="text" className="form-control imask-datetime" id="start_date" required placeholder="00/00/0000 00:00" />
                                        </div>
                                    </div>
                                    <div className="form-group row required">
                                        <label htmlFor="end_date" className="col-sm-4 text-right control-label col-form-label">End:</label>
                                        <div className="col-sm-8">
                                            <Field name="end_date" component="input" type="text" className="form-control imask-datetime" id="end_date" required placeholder="00/00/0000 00:00"/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="path" className="col-sm-4 text-right control-label col-form-label">Path:</label>
                                        <div className="col-sm-8">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon3">{document.location.protocol + '//' + document.location.hostname + (document.location.port ? ':' + document.location.port : '')+'/pro/'}</span>
                                                </div>
                                                <Field name="path" component="input" type="text" className="form-control" id="path" aria-describedby="basic-addon3" maxLength="32" />
                                            </div>                                            
                                        </div>
                                    </div>
                            <div className="form-group row">
                                        <label htmlFor="wood" className="col-sm-4 text-right control-label col-form-label">Description:</label>
                                <div className="col-sm-8">
                                    <Field name="description" component="textarea" type="text" className="form-control" id="description" />
                                </div>
                            </div>
                            </div>

                                <div class="col-sm">
                                <div className="form-group row  required">
                                        <label className="col-lg-4 text-right control-label control-label col-form-label">Package:</label>
                                    <div className="col-lg-8">
                                        <span className="width-100">
                                            <Field id="select-package" name="package" className="custom-dropdown" required="required"
                                            onChange={this.selectedPackage} multi={false} options={this.state.package_selection}
                                            component={HtmlElement.RenderSelectInput} aria-describedby="input-package" />
                                        </span>
                                    </div>
                                </div> 
                                <div className="form-group row hidden  required">
                                        <label className="col-lg-4 text-right control-label control-label col-form-label">Company:</label>
                                    <div className="col-lg-8">
                                        <span className="width-100">
                                            <Field id="select-company" name="company" className="custom-company" required="required"
                                                onChange={this.selectedCompany} multi={false} options={this.state.company_selection}
                                                component={HtmlElement.RenderSelectInput} aria-describedby="input-company" />
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group row required">
                                        <label className="col-lg-4 text-right control-label control-label col-form-label">Admin:</label>
                                    <div className="col-lg-8">
                                        <span className="width-100">
                                                <Field id="select-user" name="user" className="custom-user" required="required" defaultOptions
                                                    onChange={this.selectedUser} multi={false} options={this.state.users_selection}
                                                component={HtmlElement.RenderSelectInputCreateable} aria-describedby="input-user" />
                                        </span>
                                    </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="remark" className="col-sm-4 text-right control-label col-form-label">Remark:</label>
                                        <div className="col-sm-8">
                                            <Field name="remark" component="textarea" type="text" className="form-control" id="remark" />
                                        </div>
                                    </div>
                        </div>                        
                    </div>
                    <div className="row">
                        {this.props.xerror ? <div className="alert alert-danger" role="alert"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.props.xerror}</div> : <></>}
                            </div>
                            <hr className="border-bottom" />
                            <div className="row">
                                
                                <div className="col-sm text-center">
                                    <button type="button" className="btn btn-danger mr-2" data-dismiss="modal" disabled={pristine || submitting} onClick={reset}><i className="fa fa-times"></i> Clear</button>
                                    <button type="submit" className="btn btn-primary" disabled={pristine || submitting}><i className="fa fa-check"></i> {this.props.btnName ? this.props.btnName : 'Save & Changes'}</button>
                                </div>
                            </div>
                    </div>
                </div>
            </form>
            { HtmlElement.RenderModal(this.state.modal) }
        </>
        );
    }
}

PromosForm = reduxForm({
    form:"Frm_Promos",
    enableReinitialize: true
})(PromosForm);
PromosForm = connect(
    (state, props) => ({
        initialValues: state.promos.data,
        state: { xdata: state.promos.data}
    }), PromosStore.actionCreators
)(PromosForm);

export default PromosForm;

//const mapStateToProps = (state, props) => ({
//    initialValues: props.initialValues, // retrieve name from redux store 
//})
//export default ModalForm(
//    mapStateToProps
//)(reduxForm({
//    form: 'sixxxmple',
//    enableReinitialize: true
//})(ModalForm))