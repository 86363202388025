import React from 'react';
import Select from 'react-select';
import Async, { makeAsyncSelect } from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Modal } from 'bootstrap';
import { Field} from 'redux-form';
import CKEditor from '@ckeditor/ckeditor5-react';
//import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Guid, checkNotNull} from './DataManager';
//import * as Editor from '@ckeditor/ckeditor5-build-classic';
//import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
//import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
//import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
//import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';

//window.ClassicEditor = ClassicEditor;
//window.SimpleUploadAdapter = SimpleUploadAdapter;

export default function HtmlElement() {

}

export function renderDescription(data, padding = 0,seperate = <br/>) {
    if (!data) return null;
    let desc_style = { 'color': '#999', 'paddingLeft': padding + 'px' };
    //if (!bold)
    //    desc_style['font-weight'] = 'normal !important';
    if (checkNotNull(data.description_th) && checkNotNull(data.description_en)) {
        return (<>{data.description_th}{seperate}<span style={desc_style}>{data.description_en}</span></>);
    } else if (checkNotNull(data.description_th))
        return data.description_th;
    else if (checkNotNull(data.description_en))
        return data.description_en;
    return null;
}
export function getPageDescription() {
    return 'FASTEn Model™ - a concise and reliable self-assessment tool registered by Dr. Waranya Atchariyachanvanich, founder of Wintegrate 99, to reflect the five motivations related to work.';
}
export function renderDescriptionQA(data, padding = 0) {
    if (!data) return null;
    let desc_style = { 'color': '#555', 'paddingLeft': padding + 'px' };
    //if (!bold)
    //    desc_style['font-weight'] = 'normal !important';
    if (checkNotNull(data.description_th) && checkNotNull(data.description_en,true)) {
        return (<><b>{data.description_th}?</b><span style={{ color: '#CCC' }}> / </span><span style={desc_style}>{data.description_en}?</span></>);
    } else if (checkNotNull(data.description_th))
        return <b>{data.description_th}?</b>;
    else if (checkNotNull(data.description_en))
        return <b>{data.description_en}?</b>;
    return null;
}

export function RenderSelectInputCreateableAsync({ input, options, name, id, multi, loadOptions, defaultValue, autoLoad, required, defaultOptions }) {
    return (
        <AsyncCreatableSelect
            {...input}
            id={id}
            name={name}
            options={options}
            value={input.value}
            autoLoad={autoLoad}
            placeholder="Select... or create new item here"
            arrowRenderer={arrowRenderer}
            defaultOptions={defaultOptions}
            defaultValue={defaultValue}
            cache={true}
            clearable={false}
            loadOptions={loadOptions}
            validate={required}
            onChange={(value) => input.onChange(value)}
            //onBlur={(value) => input.onBlur(value)}
            async
            multi={multi}
            onBlur={() => input.onBlur()}
        />
    );
}

export function RenderSelectInputCreateable({ input, options, name, id, loadOptions, defaultValue, required }) {
    return (
        <CreatableSelect
            {...input}
            id={id}
            name={name}
            placeholder="Select... or create new item here"
            options={options}
            value={input.value}
            loadOptions={loadOptions}
            autoLoad={true}
            defaultOptions={true}
            defaultValue={defaultValue}
            isClearable
            validate={required}
            cache={true}
            //onChange={(...args) => this.onChange(...args)}
            onChange={(value) => input.onChange(value)}
            //onBlur={(value) => input.onBlur(value)}
            onBlur={() => input.onBlur()}
        />
    );
}



//export const RenderSelectInput = ({ input, options, name, id }) => (
export function RenderSelectInput({ input, options, name, id, loadOptions, defaultValue, required }) {
    return (<Select
        {...input}
        id={id}
        name={name}
        placeholder="เลือก..."
        options={options}
        value={input.value}
        loadOptions={loadOptions}
        autoLoad={true}
        defaultOptions={true}
        defaultValue={defaultValue}
        required
        cache={true}
        //onChange={(...args) => this.onChange(...args)}
        onChange={(value) => input.onChange(value)}
        //onBlur={(value) => input.onBlur(value)}
        onBlur={() => input.onBlur()}
    />
    );
}

function arrowRenderer() {
    return (
        <span>+</span>
    );
}

export function RenderSelectAsync({ input, options, name, id, multi, loadOptions, defaultValue, autoLoad, defaultOptions, defaultInputValue, defaultMenuIsOpen }) {
    //https://github.com/JedWatson/react-select/issues/2010
    //https://github.com/JedWatson/react-select/issues/2233
    //https://github.com/JedWatson/react-select/issues/1718
    return (<Async
        {...input}
        id={id}
        name={name}
        options={options}
        value={input.value}
        autoLoad={autoLoad}
        defaultInputValue={defaultInputValue}
        placeholder="ค้นหา..."
        arrowRenderer={arrowRenderer}
        defaultOptions={defaultOptions}
        defaultValue={defaultValue}
        defaultMenuIsOpen={defaultMenuIsOpen}
        cache={true}
        clearable={false}
        loadOptions={loadOptions}
        onChange={(value) => input.onChange(value)}
        //onBlur={(value) => input.onBlur(value)}
        async
        multi={multi}
        onBlur={() => input.onBlur()}
    />
    );
}


export function renderField(props) {
    const { input, placeholder, label, value, type, name, className, meta: { touched, error } } = props;
    return (
        <div className="form-group row">
            <label htmlFor={name} className="col-sm-4 text-right control-label col-form-label">{label}</label>
            <div className="col-sm-8">
                <input className={className} {...input} name={name} id={name} type={type} placeholder={placeholder} />
            </div>
        </div>
    );
}

export function renderFieldMini(props) {
    const { input, placeholder, label, value, type, name, className, meta: { touched, error } } = props;
    return (
        <div className="form-group row">
            <label htmlFor={name} className="col-sm-3 text-right control-label col-form-label">{label}</label>
            <div className="col-sm-9">
                <input className={className} {...input} name={name} id={name} type={type} placeholder={placeholder} />
            </div>
        </div>
    );
}
export function RenderModal(props, ico = <i className="fa fa-bell-o text-danger" aria-hidden="true"></i>, size = '') {
    //console.log(props);
    return (
        <div className="modal fade" id="comonn-modal" tabIndex="-1" role="dialog" aria-labelledby="comonn-modal-title" aria-hidden="true">
            <div className={`modal-dialog ${size} modal-dialog-centered" role="document`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="comonn-modal-title">{ico} {props.title}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {props.msg}
                    </div>
                    <div className="modal-footer">
                        {props.btn}
                        <button type="button" className="btn btn-danger" data-dismiss="modal"><i className="fa fa-times"></i> Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}


export function OptionalFields(xdata) {
    let elm = [];
    for (var i = 1; i <= 10; i++) {
        if (xdata.data['is_field_' + i] === true) {
            elm.push(<div key={`colf${i}`} className="col-md-4">
                <div className="form-group row">
                    <label className="col-lg-4 text-right control-label col-form-label">{xdata.data['field_' + i]}</label>
                    <div className="col-lg-8">
                        <Field className="form-control" name={`optional_Field.field_${i}`} placeholder={`${xdata.data['field_' + i]}...`} component="input" type="text" aria-describedby="input-lastname" />
                    </div>
                </div>
            </div>);
        }
    }
    let cols = [];
    let rows = [];
    let x = 0, s = 0;
    if (elm.length > 0) {
        if (elm.length > 3) {
            for (var col in elm) {
                if (cols.length === 3) {
                    s = 0;
                    rows[x] = cols;
                    cols = [];
                    cols[s] = elm[col];
                    s++;
                    x++;
                } else {
                    cols[s] = elm[col];
                    s++;
                }
            }
            if (cols.length > 0)
                rows[x] = cols;
        } else {
            rows[0] = elm;
        }
        let elem_render = [];
        for (var r in rows) {
            elem_render.push(<div key={`rowoptional${r}`} className="row">{rows[r]}</div>);
        }
        return elem_render;//this.setState({ optional_fields: <>{elem_render}</> });
    }
    return false;
}

export function ContentEditor(field) {
    const props = Object.assign({}, field);
    //console.log(field.input.value);
    return (
        <CKEditor //{...props}
            data={field.input.value}
            content={field.input.value}
            editor={ClassicEditor}
            //plugins={[SimpleUploadAdapter]}
            //simpleUpload={{
            //    // The URL that the images are uploaded to.
            //    uploadUrl: 'http://example.com/upload/xxxxx',

            //    // Headers sent along with the XMLHttpRequest to the upload server.
            //    headers: {
            //        'X-CSRF-TOKEN': 'CSFR-Token',
            //        Authorization: 'Bearer <JSON Web Token>'
            //    }
            //}}
            onInit={editor => {
                // You can store the "editor" and use when it is needed.
                //console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
                //const data = editor.getData();
                //console.log({ event, editor, data });
            }}
            onBlur={(event, editor) => {
                //console.log('Blur.', editor);
                //console.log('Blur Event.', event);
                field.input.onChange(editor.getData());
            }}
            onFocus={(event, editor) => {
                //console.log('Focus.', editor);
            }}
            events={{
                change: event => field.onChange(event.editor.getData())
            }}
            config={{
                //plugins: [Essentials, Bold, Italic, Paragraph],
                toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable',
                    'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']
                //filebrowserImageUploadUrl: '/api/utils/upload?command=QuickUpload&type=Images&responseType=json'
                //toolbar: [
                //    { name: 'document', groups: ['mode', 'document', 'doctools'] },
                //    { name: 'clipboard', groups: ['clipboard', 'undo'] },
                //    { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
                //    { name: 'forms', groups: ['forms'] },
                //    '/',
                //    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                //    { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
                //    { name: 'links', groups: ['links'] },
                //    { name: 'insert', groups: ['insert'] },
                //    '/',
                //    { name: 'styles', groups: ['styles'] },
                //    { name: 'colors', groups: ['colors'] },
                //    { name: 'tools', groups: ['tools'] },
                //    { name: 'others', groups: ['others'] },
                //    { name: 'about', groups: ['about'] }
                //]
            }, {
                image: {
                    toolbar: [
                        'imageStyle:full',
                        'imageStyle:side',
                        '|',
                        'imageTextAlternative'
                    ]
                }
            },
            {
                //filebrowserImageUploadUrl: '/api/utils/upload?command=QuickUpload&type=Images&responseType=json',
                ckfinder: {
                    options: {
                        resourceType: 'Images'
                    },
                    uploadUrl: '/api/utils/uploadImage?xref=' + Guid(true)
                }
            }
            }
        />
    );
}
/*
CKEDITOR.editorConfig = function( config ) {
	config.toolbarGroups = [
		{ name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
		{ name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
		{ name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
		{ name: 'forms', groups: [ 'forms' ] },
		'/',
		{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
		{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
		{ name: 'links', groups: [ 'links' ] },
		{ name: 'insert', groups: [ 'insert' ] },
		'/',
		{ name: 'styles', groups: [ 'styles' ] },
		{ name: 'colors', groups: [ 'colors' ] },
		{ name: 'tools', groups: [ 'tools' ] },
		{ name: 'others', groups: [ 'others' ] },
		{ name: 'about', groups: [ 'about' ] }
	];
	config.removeButtons = 'Source,NewPage,Save,Templates,Cut,SelectAll,Form,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Checkbox,Superscript,Subscript,Flash,About';
};
*/