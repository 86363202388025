import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as ActionsAdmin from '../components/Common/ActionAdmin';
import { Project_ModelToForm } from '../components/Common/DataManager';
import Utils from '../components/Common/Utils';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface OptionalFielState {
    isLoading: boolean;
    xIndex?: string;
    data: any;
}

interface RequestOptionalFieldAction {
    type: 'REQUEST_OPTIONALFIELD_DATA';
    xIndex: string;
}

interface ReceiveOptionalFieldAction {
    type: 'RECEIVE_OPTIONALFIELD_DATA';
    xIndex: string;
    data: any;
}
interface UpdateOptionalFieldAction {
    type: 'UPDATE_OPTIONALFIELD_DATA';
    xIndex: string;
    data: any;
}

type KnownAction = RequestOptionalFieldAction | ReceiveOptionalFieldAction | UpdateOptionalFieldAction;

export const actionCreators = {
    requestData: (xIndex: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        console.log(xIndex);
        if (appState && appState.optionalField && xIndex !== appState.optionalField.xIndex) {
            fetch(`/api/${ActionsAdmin.Models.OptionalField}/GetAsync`,
                {
                    method: 'GET',
                    headers: Utils.headerXRequest()
                })
                .then(response => response.json() as Promise<any>)
                .then(xdata => {
                    dispatch({ type: 'RECEIVE_OPTIONALFIELD_DATA', xIndex: xIndex, data: xdata.data ? xdata.data : {} });
                });

            dispatch({ type: 'REQUEST_OPTIONALFIELD_DATA', xIndex: xIndex });
        }
    }
};

const unloadedState: OptionalFielState = { data: {}, isLoading: false };

export const reducer: Reducer<OptionalFielState> = (state: OptionalFielState | undefined, incomingAction: Action): OptionalFielState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'UPDATE_OPTIONALFIELD_DATA':
            return {
                xIndex: action.xIndex,
                data: action.data,
                isLoading: false
            };   
        case 'REQUEST_OPTIONALFIELD_DATA':
            return {
                xIndex: action.xIndex,
                data: state.data,
                isLoading: true
            };
        case 'RECEIVE_OPTIONALFIELD_DATA':
            if (action.xIndex === state.xIndex) {
                return {
                    xIndex: action.xIndex,
                    data: action.data,
                    isLoading: false
                };
            }
              
            break;
    }

    return state;
};
