import React, { Component } from 'react';
import { connect } from 'react-redux';
import Utils from '../../Common/Utils';
import { Field, reduxForm } from "redux-form";
import * as HtmlElement from '../../Common/HtmlElement';
import * as Actions from '../../Common/Actions';
import * as ActionAdmin from '../../Common/ActionAdmin';
import * as DataManager from '../../Common/DataManager';
import selectOption from '../../Common/selectOption';
import Inputmask from "inputmask";
import Step4 from '../../public/Step4';
import Loading from '../../Common/Loading';
import E404 from '../../E404';

class CuzProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false, xIndex: false, rendering: <h2><br/>Looking for data...</h2>, GuestData: {}
        }
        
    }

    submit4 = (values) => {
        //console.log(values);
        return;
    }

    goBack = (values) => {
        this.props.history.goBack();
    }

    cancelProc = () => {
        this.props.history.goBack();
    }
    
    componentDidMount() {
        const xIndex = this.props.match.params.xIndex || 0;
        this.ensureDataFetched(xIndex);    
    }

    // This method is called when the route parameters change
    componentDidUpdate(prevProps) {
        let xIndex = prevProps.match.params.xIndex || 0;
        this.ensureDataFetched(xIndex);
    }
    ensureDataFetched(xIndex) {
        if (xIndex && xIndex != 0 && xIndex !== this.state.xIndex) {
            Actions.GuestDataById(xIndex).then(xdata => {
                if (xdata.status === 200 && xdata.data && xdata.data.person_Info) {
                    //xdata.data['']
                    let iniVals = xdata.data.person_Info;
                    let dd = xdata.data.person_Info.dob;
                    iniVals['is_complete'] = true;
                    iniVals['date_of_birth'] = Utils.dateTimeToStringNumber(dd, 'th-TH');
                    iniVals['country'] = { value: xdata.data.person_Info.birth_Place.country_code, label: xdata.data.person_Info.birth_Place.country_name };
                    iniVals['province'] = { value: xdata.data.person_Info.birth_Place.province_id, label: xdata.data.person_Info.birth_Place.province_name };
                    iniVals['district'] = { value: xdata.data.person_Info.birth_Place.district_id, label: xdata.data.person_Info.birth_Place.district_name };
                    this.setState({
                        xIndex: xIndex,
                        GuestData: xdata.data, rendering: <Step4 key="profile_render" values={iniVals} onRef={true} GuestData={xdata.data} onStepFour={this.stepFour} onSubmit={this.submit4} onGoBack={this.goBack} onCancelProc={this.cancelProc} />
                    });
                    return;
                } else {
                    this.setState({
                        rendering: <E404/> });
                }
                this.setState({ loading: false });
            });
            this.setState({ xIndex: xIndex });
        }          
        return;
    }

 
    render() {

        return (
            <div className="row">
                <div className="col-md-12"><Loading loading={this.state.loading} />
                    <div className="card">
                        <div className="card-body">
                            {this.state.rendering}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default connect()(CuzProfile);

//const mapStateToProps = (state, props) => ({
//    initialValues: props.initialValues, // retrieve name from redux store 
//})
//export default ModalForm(
//    mapStateToProps
//)(reduxForm({
//    form: 'sixxxmple',
//    enableReinitialize: true
//})(ModalForm))