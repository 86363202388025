import * as React from 'react';
import { connect } from 'react-redux';
import Loading from '../../Common/Loading';
import $ from 'jquery';
import HtmlSet from '../../Common/HtmlSet';
import * as ActionAdmin from '../../Common/ActionAdmin';
import Utils from '../../Common/Utils';
import ModalForm from '../../Common/ModalForm';
import { Field, reduxForm, change, onChange, submit } from 'redux-form';
import * as DataManager from '../../Common/DataManager';
import * as HtmlElement from '../../Common/HtmlElement';
import Auth from '../../Common/Auth';
import * as Actions from '../../Common/Actions';
import * as ProjectStore from '../../../store/Project';
import Inputmask from "inputmask";
import { Link } from 'react-router-dom';
let country = '';
let province = 0;
let district = null;

class ProjectUsing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: props.values, ctvalue: 'THA', 
            user: Auth.GetUser(),
            loading: true, modal: [], xdata: false, search: null, xSearch: DataManager.search_args, province_selection: [], district_selection: [], company_selection: [], company_selected: false,
            project_selection: [], project_selected: false, age_selected: false, gender_selected: false, position_selected: false, department_selected: false, position_selection: [], department_selection: []
        }
        this.selectedCountry = (e, val) => {
            this.loadProvince(val.value);
        }
        this.selectedProvince = (e, val) => {
            this.loadDistrict(val.value);

        }
        this.selectedDistrict = (e, val) => {
            district = val;
        }
        this.upload_field = null;
        
        this.selectedCompany = (e, val) => {
            this.setState({ company_selected: val });
            this.loadDepartment('r=' + val.value);
            this.loadPosition('r=' + val.value);
            this.loadProject('r=' + val.value);
        }
        this.selectedProject = (e, val) => {
            this.setState({ project_selected: val });
            //this.refs.select_user.props.loadOptions(' ');
            //this.refs.select_user.props.defaultOptions = true;
            console.log(val);
        }
        this.selectedAges = (e, val) => {
            this.setState({ age_selected: val });
            //this.refs.select_user.props.loadOptions(' ');
            //this.refs.select_user.props.defaultOptions = true;
            //console.log(this.refs.select_user);
        }
        this.selectedGender = (e, val) => {
            this.setState({ gender_selected: val });
            //this.refs.select_user.props.loadOptions(' ');
            //this.refs.select_user.props.defaultOptions = true;
            console.log(this.refs.select_user);
        }
        this.selectedDepartment = (e, val) => {
            this.setState({ department_selected: val });
            //this.refs.select_user.props.loadOptions(' ');
            //this.refs.select_user.props.defaultOptions = true;
            
        }
        this.selectedPosition = (e, val) => {
            this.setState({ position_selected: val });
            //this.refs.select_user.props.loadOptions(' ');
            //this.refs.select_user.props.defaultOptions = true;

        }

        this.loadAllProject = (val) => {
            return Actions.DropDown_List(ActionAdmin.Models.Project, val).then(xdata => {
                return xdata;
            });
        }

        this.uploadFrm = (data = {}) =>{
            this.setState({
                modal: <ModalForm form="Form_Modal" xerror={this.state.frmeror} size="modal-lg" initialValues={data} body={this.uploadFrmRender()} title="อัปโหลดข้อมูล/Profile data" onSubmit={this.submit} />
            });
            Utils.ShowModalFormTable(data);
        }
        
        this.search = () => {
            let input_val = document.getElementById('search_name').value;
            var q = [];
            if (DataManager.checkNotNull(input_val))
                q.push('doc=' + input_val);
            if (this.state.company_selected && this.state.company_selected.value > 0) {
                q.push('comp=' + this.state.company_selected.value);
            }
            if (this.state.age_selected && this.state.age_selected.value > 0) {
                q.push('age=' + this.state.age_selected.value);
            }
            if (this.state.gender_selected && DataManager.checkNotNull(this.state.gender_selected.value)) {
                q.push('gen=' + this.state.gender_selected.value);
            }
            if (this.state.department_selected && this.state.department_selected.value > 0) {
                q.push('dep=' + this.state.department_selected.value);
            }
            if (this.state.position_selected && this.state.position_selected.value > 0) {
                q.push('pos=' + this.state.position_selected.value);
            }
            if (this.state.project_selected && !DataManager.isGuidEmpty(this.state.project_selected.value)) {
                q.push('proj=' + this.state.project_selected.value);
            }
            this.props.history.push(`/admin/projectuse/1/${q.join('||')}`);
        }
        this.clear = () => {
            this.props.match.params.search = undefined;
            this.setState({
                search: {}, xSearch: false,
                company_selected: false, 
                age_selected: false,
                gender_selected: false,
                department_selected: false,
                position_selected: false,
                project_selected: false,


            });
            this.props.change('department', false);
            this.props.change('ages', false);
            this.props.change('position', false);
            this.props.change('gender', false);
            this.props.change('project', false);
            this.props.change('company', false);
            $('input').val('');            

            if (Auth.IsAdmin(this.state.user)) {
                this.loadCompany();
                this.loadProject();
                this.loadDepartment();
                this.loadPosition();
            } else if (this.state.user.company_Employee && this.state.user.company_Employee.company_Info) {
                this.loadProject('r=' + this.state.user.company_Employee.company_Info.id);
                //this.loadProject('r='+this.state.user.company_Employee.company_Info.id);
                this.loadDepartment('r=' + this.state.user.company_Employee.company_Info.id);
                this.loadPosition('r=' + this.state.user.company_Employee.company_Info.id);
            }

            this.props.history.push(`/admin/projectuse`);
            
        }
        this.getQstr = (s = null) => {
            if (DataManager.checkNotNull(s)) {
                let search = DataManager.searchQstr(s);
                if (search) {
                    this.setState({ search: search });
                    return search;
                }
            } else {
                this.setState({ search: {} });
            }
            return false;
        }
    }
    loadCompany() {
        Actions.DropDown_List(ActionAdmin.Models.Company).then(xdata => {
            this.setState({ company_selection: xdata, company_selected: false });
        });
    }
    loadProject(val) {
        Actions.DropDown_List(ActionAdmin.Models.Project, null, val).then(xdata => {
            this.setState({ project_selection: xdata, project_selected: false });
        });
    }

    loadDepartment(val) {
        Actions.DropDown_List(ActionAdmin.Models.Department, null, val).then(xdata => {
            this.setState({ department_selection: xdata, department_selected: false });
        });
    }
    loadPosition(val) {
        Actions.DropDown_List(ActionAdmin.Models.Position, null, val).then(xdata => {
            this.setState({ position_selection: xdata, position_selected: false });
        });
    }
    getAges() {
        let ages = [{value:false,label:'All..'}];
        for (var i = 1; i <= 10; i++) {
            ages.push({ value: i, label: ((i - 1) * 10)+'-'+(i*10) });
        }

        return ages;
    } 
    
    loadProvince(v) {
        if (v && !Utils.isEmptyOrSpaces(v)) {
            Actions.DropDown_Country('Province', v).then(xdata => {
                if (xdata.status === 200) {
                    this.setState({ province_selection: xdata.data });
                } else {
                    this.setState({ province_selection: [] });
                }
            }).then((xdata) => { });
        }
    }
    

    loadDistrict(v) {
        if (v) {
            Actions.DropDown_Country('District', v).then(xdata => {
                if (xdata.status === 200) {
                    this.setState({ district_selection: xdata.data });
                } else {
                    this.setState({ district_selection: [] });
                }
            }).then((xdata) => { });
        }
    }

    getCountry(input) {
        //loadOptions={this.getCountry}
        return Actions.DropDown_List('Country', input);
    }
    getProvince(input) {
        return Actions.DropDown_List('Province', input, country);
    }
    getDistrict(input) {
        return Actions.DropDown_List('District', input, province);
    }

    setHH = (e, v) => {
        if (v && v.value)
            this.setState({ HH: v.value });
        else
            this.setState({ HH: 0 });
    }

    submit = (vals) => {
        console.log(vals);

        vals['xerror'] = null;
        if (!vals.xproject) {
            vals['xerror'] = 'กรุณาเลือก Project ก่อนทำการ upload ข้อมูล';
            this.uploadFrm(vals);
            return;
        }

        if (!this.upload_field || !this.upload_field.files || this.upload_field.files.length < 1) {
            vals['xerror'] = 'กรุณาเลือก File excel ที่จะทำการ upload';
            this.uploadFrm(vals);
            return;
        }

        if (!Utils.isExtension_Excel(this.upload_field.files[0].name)) {
            vals['xerror'] = 'กรุณาเลือก File .xlsx เท่านั้น';
            this.uploadFrm(vals);
            return;
        }
        Utils.HideModalFormTable(vals);
        this.setState({ loading: true });
        ActionAdmin.Upload_Profile_data(this.upload_field.files[0], vals.xproject.value).then(xdata => {
            if (xdata && xdata.status === 200) {
                this.upload_field.value = null;
                alert('Upload file completed');
            } else {                
                if (xdata.data && xdata.data.length > 0) {
                    let xerr = [];
                    for (var i = 0; i < xdata.data.length; i++) {
                        xerr.push(<li key={`xerrs${i}`}>{xdata.data[i]}</li>);
                    }
                    vals['xerror'] = <>{xdata.message ? xdata.message : xdata.Message}<ul>{xerr}</ul></>;
                } else
                    vals['xerror'] = xdata.message ? xdata.message : xdata.Message;
                this.uploadFrm(vals);
            }
        }).then(x => { this.setState({ loading: false }); });

        //Utils.HideModalFormTable(vals);

        //let is_reload = vals && vals.id && vals.id != 0 ? false : true;

        //this.setState({ loading: true });
        //ActionAdmin.Post_Model(ActionAdmin.Models.Project, vals).then(data => {
        //    if (data.status === 200) {
        //        let xdata = this.state.xdata;
        //        const i = xdata.data.findIndex((obj => obj.id == vals.id));
        //        xdata.data[i] = vals;
        //        this.setState({
        //            loading: false,
        //            xdata: xdata
        //        });  
        //        if (is_reload) this.loadData(this.state.xIndex);
        //    } else {
        //        this.setState({ frmeror: data.Message ? data.Message : data.title });
        //        Utils.ShowModalFormTable(vals);
        //    }
            
        //}).then((x) => this.setState({
        //    loading: false
        //}));
        return;
    }
    Download() {
        let search = this.getQstr(this.props.match.params.search);
        let model_name = ActionAdmin.Models.ProjectUsing;
        let file_name = model_name + '_' + DataManager.Guid() + '.xlsx';
        this.setState({ loading: true });
        ActionAdmin.Get_file(model_name, file_name, search).then(xdata => {
            console.log(xdata.size);
            if (xdata && xdata.size > 2500) {
                let url = window.URL.createObjectURL(xdata);
                //console.log(url);
                let a = document.createElement('a');
                a.href = url;
                a.download = file_name;
                a.click();
            } else {
                alert('ไม่สามารภดาวน์โหลดได้');
            }
        }).then(x => { this.setState({ loading: false }); });
    }
    deleteData(data) {
        console.log(data)
        let is_reload = false;
        ActionAdmin.Delete_ProjectPerson(data.id).then(xdata => {
            console.log(xdata);
            if (xdata && xdata.status === 200) {
                // ok
                is_reload = true;
            }
            if (is_reload) this.loadData(this.state.xIndex);
        });
    }
   
    updateFrm(data) {
        //console.log('---------data-------->')
        //console.log(data)
        this.props.history.push('/admin/projuser/' + (data && data.dataModel ? data.dataModel.person_info_id : ''));
        /*
        this.setState({
            modal: <ModalForm form="Form_Modal" xerror={this.state.frmeror} initialValues={data} body={this.renderForm(data)} title={`แก้ไขข้อมูล #${data.id}`} onSubmit={this.submit} />
        });
        Utils.ShowModalFormTable(data);*/
    }

    linkDept(data) {
        this.props.history.push('/admin/department/1/comp=' + data.id);
    }
    linkPost(data) {
        this.props.history.push('/admin/position/1/comp=' + data.id);
    }    

   
  
    componentDidMount() {
        Inputmask("99/99/9999").mask(document.getElementsByClassName("imask-date"));
        Inputmask("99:99").mask(document.getElementsByClassName("imask-time"));
        this.loadProvince('THA');
        const xIndex = this.state.xIndex || 1;
        let search = this.getQstr(this.props.match.params.search);
       
        ActionAdmin.Get_List_Using(ActionAdmin.Models.Project, xIndex, search).then(data => {
            if (data.status === 200) {
                this.setState({
                    loading: false,
                    xIndex: xIndex,
                    xdata: data,
                    search: this.props.match.params.search,
                    xSearch: search

                });
            }
        }).then((x) => this.setState({
            loading: false
        }));
        if (Auth.IsAdmin(this.state.user)) {
            this.loadCompany();
            this.loadProject();
            this.loadDepartment();
            this.loadPosition();            
        } else if (this.state.user.company_Employee && this.state.user.company_Employee.company_Info) {            
            this.loadProject('r='+this.state.user.company_Employee.company_Info.id);
            //this.loadProject('r='+this.state.user.company_Employee.company_Info.id);
            this.loadDepartment('r='+this.state.user.company_Employee.company_Info.id);
            this.loadPosition('r='+this.state.user.company_Employee.company_Info.id);
        }

        
    }
    updateShowData(data) {
        if (!data.dataModel || !data.dataModel.person_info_id || DataManager.isGuidEmpty(data.dataModel.person_info_id)) {
            alert('ข้อมูลไม่สมบูรณ์'); return;
        }
        this.props.history.push('/admin/cuzprofile/' + data.dataModel.person_info_id);        
    }
    uploadFrmRender() {
        return (
            <div className="row">
                <div className="col-sm">
                    <div className="form-group row">
                        
                    </div>                    
                    <div className="form-group row">
                        <label htmlFor="select-xproject" className="col-sm-4 col-form-label text-right">Project :</label>
                        <div className="col-sm-8">
                            <span className="width-100">
                                <Field id="select-xproject" name="xproject" className="custom-select" required="required"
                                    multi={false} loadOptions={this.loadAllProject} defaultOptions
                                    component={HtmlElement.RenderSelectAsync} aria-describedby="input-xproject" />
                            </span>
                            <small class="form-text text-muted">- เลือก project ที่ต้องการ upload ข้อมูลเข้า</small>
                            <small class="form-text text-muted">- จำนวนข้อมูลต้องไม่เกินที่ project ระบุไว้</small>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label  text-right">File upload :</label>
                        <div className="col-sm-8">
                            <div className="input-group">
                                <input type="file" name="file" ref={(ref) => this.upload_field = ref} className="form-control" id="file-upload" aria-label="Select excel file" placeholder="Upload excel" aria-describedby="basic-addon1" accept=".xlsx" />
                                <div className="input-group-append">
                                    <label htmlFor="file-upload" className="btn btn-secondary input-group-text"><i className="fa fa-file"></i>&nbsp; Select</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            );
    }

    renderAdminBtn(data, is_admin, current_project) {
        //console.log(current_project.id + ':' + data.projectInfo_Min.id);
        if (is_admin) {
            return (<div className="btn-toolbar center">
                <button type="button" className="btn btn-success btn-sm mr-1" onClick={() => this.updateShowData(data)}><i className="fa fa-eye"></i> ดู</button>
                <button type="button" className="btn btn-info btn-sm mr-1" onClick={() => this.updateFrm(data)}><i className="fa fa-edit"></i> Edit</button>
                <button type="button" className="btn btn-danger btn-sm" onClick={() => { if (window.confirm('คุณต้องการลบข้อมูล หรือไม่?')) { this.deleteData(data); } }}><i className="fa fa-times"></i> Del</button>
            </div>);
        } else if (current_project) {
            if (current_project.id === data.projectInfo_Min.id) {
                return (<div className="btn-toolbar center">
                    <button type="button" className="btn btn-success btn-sm mr-1" onClick={() => this.updateShowData(data)}><i className="fa fa-eye"></i> ดู</button>
                    <button type="button" className="btn btn-info btn-sm mr-1" onClick={() => this.updateFrm(data)}><i className="fa fa-edit"></i> Edit</button>
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => { if (window.confirm('คุณต้องการลบข้อมูล หรือไม่?')) { this.deleteData(data); } }}><i className="fa fa-times"></i> Del</button>
                </div>);
            } else {
                return (<div className="btn-toolbar center">
                    <button type="button" className="btn btn-success btn-sm mr-1" onClick={() => this.updateShowData(data)}><i className="fa fa-eye"></i> ดู</button>                    
                </div>);
            }
        } else {
            return <></>;
        }
    }
   
    
    renderDataTable(props) {
        let is_admin = Auth.IsAdmin(props.user);
        let current_project = null;
        if (!is_admin) {
            current_project = props.user.packageInfo.projectInfo;
        }
        if (props && props.xdata && props.xdata.data) {
            return (<tbody>
                {props.xdata.data.map((data) => {
                    
                    return <tr key={data.id} id={`rowno${data.id}`}>
                        <td>{data.first_name} {data.last_name}</td>
                        <td>{data.gender}</td>
                        <td>{DataManager.dateTimeToInput(data.dob,true,false)}</td>
                        <td>{(data.distric && data.distric.name) ? data.distric.name+'/' : ''}{data.province.name} / {data.country.name}</td>
                        <td>{data.department.name}</td>
                        <td>{data.position ? data.position.name : data.position_id}</td>
                        {is_admin ? <td>{data.company.name}</td> : <></>}
                        <td>{data.projectInfo_Min.name}</td>
                        <td className="text-center center">
                            {this.renderAdminBtn(data, is_admin, current_project)}
                        </td>
                    </tr>
                })}
            </tbody>);
        } else {
            return (<tbody>
                <tr>
                    <th colSpan="10"><p>Not found</p></th>
                </tr>
            </tbody>);
        }
    }

    loadData(xIndex, s = false) {
        ActionAdmin.Get_List_Using(ActionAdmin.Models.Project, xIndex,s).then(data => {
            if (data.status === 200) {
                this.setState({
                    xdata: data,
                    xIndex: xIndex
                    //xSearch: nextProps.match.params.search
                });
            }
            console.log(data);
        }).then((x) => this.setState({ loading: false }));
    }

    componentWillReceiveProps(nextProps) {
        const xIndex = nextProps.match.params.xIndex || 0;
        if ((xIndex === 0 || nextProps.match.params.xIndex === this.state.xIndex) && (nextProps.match.params.search === this.state.search)) {
            this.setState({
                search: nextProps.match.params.search
            });
            return;
        } else if ((xIndex === 0 || nextProps.match.params.xIndex === this.state.xIndex) && Utils.isEmptyObject(nextProps.match.params.search) && (nextProps.match.params.search === this.state.search))
            return;

        this.setState({
            loading: true
        });
        ActionAdmin.Get_List_Using(ActionAdmin.Models.Project, xIndex, this.getQstr(nextProps.match.params.search)).then(data => {
            if (data.status === 200) {
                this.setState({
                    xdata: data,
                    xIndex: xIndex,
                    search: nextProps.match.params.search,
                    xSearch: this.getQstr(nextProps.match.params.search)
                });
            }
        }).then((x) => this.setState({ loading: false }));

     
    }

    renderOptionComapny(state) {
        if (Auth.IsAdmin(state.user)) {
            return (
                <div className="col-sm-6">
                    <div className="form-group">
                        <label className="col-lg-12 text-left control-label col-form-label">Organization</label>
                        <div className="col-lg-12">
                            <span className="width-100">
                                <Field id="select-company" name="company" className="custom-company" required="required"
                                    onChange={this.selectedCompany} multi={false} options={this.state.company_selection}
                                    component={HtmlElement.RenderSelectInput} aria-describedby="input-company" />
                            </span>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (<div className="col-sm-6">
                <div className="form-group">
                    <label className="col-lg-12 text-left control-label col-form-label">Organization:</label>
                    <div className="col-lg-12">               
                        <b className="text-info">{state.user.company_Employee ? state.user.company_Employee.company_Info.name : '-'}</b>
                    </div>
                </div>
            </div>);
        }
    }


    render() {
        return (
            <>
            <div className="card">                
                <div className="card-body"><Loading loading={this.state.loading} txt={true}/>
                        <h5 className="card-title card-title-border text-secondary"><i className="fa fa-cog" aria-hidden="true"></i> Project Using </h5>

                        <div className="row">
                            <div className="col-sm-2 border-right margin-bottom-20">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <button type="button" className="btn btn-primary mb-2 mr-1" onClick={() => this.updateFrm({})}><i className="fa fa-plus"></i> เพิ่มข้อมูล</button>
                                        <button type="button" className="btn btn-secondary mb-2 mr-1" onClick={() => this.Download({})} data-toggle="tooltip" data-placement="right" title="กรุณาค้นหาก่อนทำการดาวน์โหลด"> <i className="fa fa-download">  </i>  Download </button>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-12 text-center">                                        
                                        <div className="padding-bottom-10">
                                            {Auth.IsAdmin(this.state.user) ? <button type="button" className="btn btn-success" onClick={this.uploadFrm}><i className="fa fa-upload"></i>  Upload data </button> : <></>}
                                         </div>
                                        <a href="/templates/excel/Clients_Template.xlsx" download><i className="fa fa-download"></i> Download template</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 margin-bottom-20">
                                <div className="row">
                                    {this.renderOptionComapny(this.state)}
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="col-lg-12 text-left control-label col-form-label">Department</label>
                                            <div className="col-lg-12">
                                                <span className="width-100">
                                                    <Field id="select-department" name="department" className="custom-department" required="required"
                                                        onChange={this.selectedDepartment} multi={false} options={this.state.department_selection}
                                                        component={HtmlElement.RenderSelectInput} aria-describedby="input-department" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="col-lg-12 text-left control-label col-form-label">Project</label>
                                            <div className="col-lg-12">
                                                <span className="width-100">
                                                    <Field id="select-project" name="project" className="custom-project" required="required"
                                                        onChange={this.selectedProject} multi={false} options={this.state.project_selection}
                                                        component={HtmlElement.RenderSelectInput} aria-describedby="input-project" />
                                                </span>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="col-lg-12 text-left control-label col-form-label">Position</label>
                                            <div className="col-lg-12">
                                                <span className="width-100">
                                                    <Field id="select-position" name="position" className="custom-position"
                                                        onChange={this.selectedPosition} multi={false} options={this.state.position_selection} def={this.state.position_selected ? this.state.position_selected.value : false}
                                                        component={HtmlElement.RenderSelectInput} aria-describedby="input-position" />
                                                </span>
                                            </div>

                                        </div>
                                      
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">

                                            <label className="col-lg-12 text-left control-label col-form-label"> กลุ่มอายุ </label>
                                            <div className="col-lg-12">
                                                <span className="width-100">
                                                    <Field id="select-ages" name="ages" className="custom-ages" required="required"
                                                        onChange={this.selectedAges} multi={false} options={this.getAges()}
                                                        component={HtmlElement.RenderSelectInput} aria-describedby="input-ages" />

                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="col-lg-12 text-left control-label col-form-label"> เพศ </label>
                                            <div className="col-lg-12">
                                                <span className="width-100">
                                                    <Field id="select-gender" name="gender" className="custom-gender" required="required"
                                                        onChange={this.selectedGender} multi={false} options={DataManager.getGender()}
                                                        component={HtmlElement.RenderSelectInput} aria-describedby="input-gender" />

                                                </span>
                                            </div>

                                        </div>
                                        </div>
                                   
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="row">
                                <div className="col-sm-12 text-right">
                                    <div className="form-group">
                                        <label className="col-lg-12 text-left control-label col-form-label">Search</label>
                                        <div className="col-lg-12">
                                            <div className="mb-2">
                                                <label htmlFor="search_name" className="sr-only">Search</label>
                                                <input type="text" className="form-control" id="search_name" placeholder="Search..." defaultValue={this.state.xSearch.doc} />
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                </div>
                            <div className="row">
                                <div className="col-sm-12 text-right">
                                    <div className="form-group">
                                        <label className="col-lg-12 text-left control-label col-form-label">  </label>
                                        <div className="col-lg-12">
                                            <button type="button" className="btn btn-primary mb-2 mr-1" onClick={this.search}><i className="fa fa-search"></i> Search</button>
                                            <button type="button" className="btn btn-secondary mb-2" onClick={this.clear}><i className="fa fa-refresh"></i> Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        </div>

               
                    {this.state.modal}
                    
                    <table className="table table-sm table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Alias name</th>
                                <th scope="col">Gender</th>
                                <th scope="col">Birthdate</th>
                                <th scope="col">Address</th>
                                <th scope="col">Department</th>
                                    <th scope="col">Position</th>
                                    {Auth.IsAdmin(this.state.user) ? <th scope="col">Organization</th> : <></>}
                                <th scope="col">Project</th>
                                <th scope="col" className="btn-col-3"></th>
                            </tr>
                        </thead>
                        {this.state.loading && !this.state.xdata ? <tbody><tr><td colSpan="10"><p>Loading...</p></td></tr></tbody> : this.renderDataTable(this.state)}
                    </table>
                    <div className="row">
                        <div className="col-sm-12 float-right">
                            <div className="dataTables_paginate paging_simple_numbers float-right padding-right" id="zero_config_paginate">
                                {this.state.loading && !this.state.xdata ? 'Looking for data...' : HtmlSet.renderPagination(this.state, '/admin/projectuse/', this.props.match.params.search)}                                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                {/*HtmlElement.RenderModal(this.state.modal, <i className="fa fa-info text-primary" aria-hidden="true"></i>, 'modal-xl')*/}
        </>
        );
    }
}

ProjectUsing = reduxForm({
    form: "Project_Using",
    enableReinitialize: true
})(ProjectUsing);
ProjectUsing = connect(
    (state, props) => ({
        initialValues: state.project.data
    }), ProjectStore.actionCreators
)(ProjectUsing);




export default connect()(ProjectUsing);