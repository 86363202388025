import React, { Component } from 'react';


class E404 extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render(){
        return(
                <div className="container">
                    <div className="row">      
                        <div className="col-sm-12">
                            <div className="text-center">
                                <h1 className="err-text text-danger">404 :(</h1>
                                <h2 className="err-text">Page not found</h2>
                                <div className="space-btm"></div>
                            </div>
                        </div>
                    </div>
            </div>
        );
        }
    }

export default E404;
