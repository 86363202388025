import * as React from 'react';

class Home extends React.PureComponent {
    public render() {
        return (
            <React.Fragment>
                <div className="card">
                    <div className="card-body">
                        <h1>Welcome...</h1>

                        <p>to administration site.</p>
                        
                    </div>
                </div>                
            </React.Fragment>
        );
    }
};

export default Home;
