import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from '../Common/Actions';
import $ from 'jquery';
import { Field, reduxForm, change, onChange,submit,getFormValues } from 'redux-form';
import Loading from '../Common/Loading';
import Utils from '../Common/Utils';
import Auth from '../Common/Auth';
import * as DataManager from '../Common/DataManager';
class Step3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: Auth.GetUser(),xerror:false,
            loading: true, values: props.initialValues, xdata: false, GuestData: props.GuestData, maine: {}
        }
        //this.goBack = this.goBack.bind(this);
    }

    //goBack(e) {
    //    this.props.onGoBack(1);
    //}
    isUnknowPlace() {
        if (typeof this.state.values.unknow_place != 'undefined')
            return this.state.values.unknow_place;
        else if (this.state.values.person_Info && typeof this.state.values.person_Info.unknow_place != 'undefined')
            return this.state.values.person_Info.unknow_place;
        else if (this.state.values.Person_Info && typeof this.state.values.Person_Info.unknow_place != 'undefined')
            return this.state.values.Person_Info.unknow_place;
        else if (this.state.GuestData && this.state.GuestData.person_Info && typeof this.state.GuestData.person_Info.unknow_place != 'undefined')
            return this.state.GuestData.person_Info.unknow_place;
        else if (this.state.GuestData && this.state.GuestData.Person_Info && typeof this.state.GuestData.Person_Info.unknow_place != 'undefined')
            return this.state.GuestData.Person_Info.unknow_place;
        else
            return false;
    }
    renderPlace(state) {
        if (this.isUnknowPlace())
            return <> -</>;
        else
            return <>: {state.values.district && state.values.district.value ? state.values.district.label + ', ' : ''}
                {state.values.province ? state.values.province.label + ',' : ''}
                {state.values.country ? state.values.country.label : ''}</>;
    }

    componentDidMount() {
        //console.log(this.state.GuestData.Person_Info);
        //if (this.state.GuestData.Request_Chindate && this.state.GuestData.Request_Chindate.id && this.state.GuestData.Request_Chindate.chindate_Objects && this.state.GuestData.Request_Chindate.chindate_Objects.length > 2
        //    && Utils.Get_StoreDOB() === this.state.GuestData.Person_Info.dob,) {
        //    this.setState({
        //        loading: false, maine: this.state.GuestData.Request_Chindate.chindate_Objects.find(x => x.type === 'Element')
        //    });
        //} else {

        let req_data = {};
        if (this.state.GuestData.Person_Info && this.state.GuestData.Person_Info.dob) {

            req_data = {
                date: this.state.GuestData.Person_Info.dob,
                Country: this.state.GuestData.Person_Info.country,
                Province: this.state.GuestData.Person_Info.province,
                District: this.state.GuestData.Person_Info.district,
                Users: this.state.user,
                Person_Info: this.state.GuestData.Person_Info
            };
        } else if (this.state.GuestData.person_Info && this.state.GuestData.person_Info.dob) {
            req_data = {
                date: this.state.GuestData.person_Info.dob,
                Country: this.state.GuestData.person_Info.country,
                Province: this.state.GuestData.person_Info.province,
                District: this.state.GuestData.person_Info.district,
                Users: this.state.user,
                Person_Info: this.state.GuestData.person_Info
            };
        }
        //} else if (this.state.GuestData.Person_Info && this.state.GuestData.Person_Info.dob){
   
        //    req_data = {
        //        date: this.state.GuestData.Person_Info.dob,
        //        Country: this.state.GuestData.Person_Info.country,
        //        Province: this.state.GuestData.Person_Info.province,
        //        District: this.state.GuestData.Person_Info.district,
        //        Users: this.state.user,
        //        Person_Info: this.state.GuestData.Person_Info
        //    };
        //}
        if (!req_data.Country || DataManager.isEmptyObject(req_data.Country) || DataManager.checkNotNull(req_data.Country.value) || req_data.Province.label) {
            req_data.Country = this.state.values.country;
            req_data.Province = this.state.values.province;
            req_data.District = this.state.values.district;
        }

        if (req_data.Person_Info.birth_Place && req_data.Person_Info.birth_Place.person_Info)
            req_data.Person_Info.birth_Place.person_Info = null;
        if (req_data.Person_Info.birth_Place && !DataManager.checkNotNull(req_data.Person_Info.birth_Place.country_code)) {
            delete req_data.Person_Info.birth_Place;
        }

        if (req_data.Person_Info && req_data.Person_Info.Users)
            req_data.Person_Info.Users = null;

        if (req_data.Users && req_data.Users.packageInfo && req_data.Users.packageInfo.promo_Code) {
            req_data.Users.packageInfo.promo_Code.person_Info = null;
            req_data.Users.packageInfo.promo_Code.projectInfo = null;
        }
        if (req_data.Person_Info && req_data.Person_Info.promo_Code && req_data.Person_Info.promo_Code.data_Model) {
            req_data.Person_Info.promo_Code.data_Model = null;
            req_data.Person_Info.promo_Code.projectInfo = null;
        }

        var uid = sessionStorage.getItem('personxi');
        if (DataManager.checkNotNull(uid) && !DataManager.isGuidEmpty(uid)) {
            Actions.Get_Step(uid,2).then(xdata => {
                let state_data = this.state.GuestData;
                state_data['Request_Chindate'] = xdata.data.request_Chindate;
                this.setState({
                    GuestData: state_data,
                    maine: state_data.Request_Chindate.chindate_Objects.find(x => x.type === 'Element')
                });
                //Utils.StoreDOB(state_data.person_Info ? state_data.person_Info.dob : state_data.Person_Info.dob);
                    this.props.onStepThree(state_data);
                    if (this.state.user && this.state.user.packageInfo.packageConfig && this.state.user.packageInfo.packageConfig.show_birthchart === false) {
                        this.props.onSubmit(this.state.values);
                    }
            }).then((x) => this.setState({ loading: false }));
        } else {
            this.setState({ loading: false });
        }

        //console.log(req_data);
        //SavePerson
        //Actions.SavePerson(req_data).then(xdata => {
        //    if (xdata.status === 200 && xdata.person_Info) {
        //        alert(xdata.person_Info.id);
        //    } else {
        //        this.setState({ xerror: xdata.message ? xdata.message : xdata.Message});
        //    }
        //});
        //Actions.Get_Chindate_4p(req_data).then(xdata => {                
        //        if (xdata.status === 200) {
        //            let state_data = this.state.GuestData;
        //            state_data.Request_Chindate = xdata.data.request_Chindate;
        //            if (xdata.data.person_Info)
        //                state_data.Person_Info = xdata.data.person_Info;
        //            if (xdata.data.users)
        //                state_data.user = xdata.data.users;
        //            //let state_data = data.guestData;
        //            //state_data = data.data.request_Chindate;
        //            this.setState({
        //                loading: false,
        //                //GuestData: state_data,
        //                GuestData: state_data,
        //                maine: this.state.GuestData.Request_Chindate.chindate_Objects.find(x => x.type === 'Element')
        //            });
        //            //Utils.StoreDOB(this.state.GuestData.Person_Info.dob
        //            Utils.StoreDOB(state_data.person_Info ? state_data.person_Info.dob : state_data.Person_Info.dob);

        //            this.props.onStepTwo(state_data);

        //            if (this.state.user && this.state.user.packageInfo.packageConfig && this.state.user.packageInfo.packageConfig.show_birthchart === false) {
        //                this.props.onSubmit(this.state.values);
        //            }
        //        }
                
                
        //    }).then((x) => this.setState({ loading: false }));
        //}
        //$(window).on('resize', function () {
        //    window.watermarkSize('.watermark-table', $('#table-info').width(), $('#table-info').height());
        //});
    }

    render() {
        const { handleSubmit, onChange } = this.props;    
        //$(window).on('resize', function () {
        //    window.watermarkSize('.watermark-table', $('#table-info').width(), $('#table-info').height());
        //});        
        //window.watermarkSizeDelay('.watermark-table', '#table-info');
        return (
            <form className="form-horizontal" onSubmit={handleSubmit} >
                <div className="card-body"><Loading loading={this.state.loading} />
                    <div className="row">
                        <div className="col-sm">      
                            <div className="header-info">Personal info</div>                            
                            <table className="table table-hover">
                                <tbody>
                                    <tr>
                                        <td colSpan="2" rowSpan="4">
                                            <img width="100" src={this.state.maine.bd_day ? '/img/icons/Element/' + this.state.maine.bd_day + '.png?v=1' : '/img/icons/empty.png?v=1'} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><b>ชื่อ/Short Name</b>: {this.state.values.first_name}</td>
                                    </tr>
                                    <tr>
                                        <td><b>หมายเลขอ้างอิง<br/>Reference Code</b>: {DataManager.checkNotNull(this.state.values.last_name) ? this.state.values.last_name : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td><b>เพศ/Gender</b>: <span className="first-upper">{this.state.values.gender}</span></td>
                                    </tr>

                                    <tr>
                                        <th scope="row">วว/ดด/ปปปป เกิด<br/>DD/MM/YYYY of Birth</th>
                                        <td colSpan="2">: {this.state.values.date_of_birth}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">เวลาเกิด/Time of Birth</th>
                                        <td colSpan="2">: xx:xx{/*this.state.values.birth_time*/}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">สถานที่เกิด/Place of Birth</th>
                                        <td colSpan="2">:{this.renderPlace(this.state)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-sm">
                            <div className="header-info">Your Birth Chart</div>
                            <div className="relative-elm">
                                <div className="watermark-table">&nbsp;</div>
                                <table id="table-info" className="table table-bordered table-active text-center table-elm">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="text-danger">Hour</th>
                                            <th scope="col">Day</th>
                                            <th scope="col" className="text-danger">Month</th>
                                            <th scope="col">Year</th>
                                        </tr>
                                    </thead>
                                    {this.state.loading ? <tbody><tr><td colSpan="4"><p>Loading...</p></td></tr></tbody> : renderDataTable(this.state)}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-top">
                    <div className="card-body">
                        <div className="form-group btn-center text-center">
                            <button type="submit" className="btn btn-dark btn-lg"> Next <i className="fa fa-check text-success" aria-hidden="true"></i></button>             
                    </div>
                    </div>
                </div>                
            </form>
                );
    }
}
//<span className="margin-5">
//    <button onClick={this.goBack} className="btn btn-dark btn-lg"><i className="fa fa-fast-backward text-secondary" aria-hidden="true"></i> Back </button>
//</span>
//    <span className="margin-5">
//        <button type="submit" className="btn btn-dark btn-lg"> Next <i className="fa fa-check text-success" aria-hidden="true"></i></button>
//    </span>      

function renderElements(state, step) {
    if (state.user && state.user.packageInfo && state.user.packageInfo.packageConfig && state.user.packageInfo.packageConfig.show_elements) {
        if (state.user.packageInfo.packageConfig.show_elements === 8) return true;
        if (!state.user.packageInfo.packageConfig.configElements || state.user.packageInfo.packageConfig.configElements == '')
            return false;
        let conf = state.user.packageInfo.packageConfig.configElements;
        return conf.indexOf(step) >= 0;
    } else
        return false;
}


function renderDataTable(props) {
    const type = ['Element', 'Year'];
    //props.configElements
    if (props && props.GuestData.Request_Chindate && props.GuestData.Request_Chindate.chindate_Objects) {
        //console.log(props.GuestData.Request_Chindate.chindate_Objects);
        
        return (<tbody>
            {props.GuestData.Request_Chindate.chindate_Objects.map((data, i) => {
                let is_type = type.indexOf(data.type) >= 0;
                if (is_type) {
                    return <tr key={data.id}>
                        <td className="text-danger">{is_type ? (renderElements(props, data.type === 'Element' ? 'hu' : 'hl') ? <img src={`/img/icons/${data.type}/${data.bd_hour}.png?v=1`} className="elm-img" /> : <img src="/img/icons/empty.png" className="elm-img"/>) : data.bd_hour}</td>
                        <td>{is_type ? (renderElements(props, data.type === 'Element' ? 'du' : 'dl') ? <img src={`/img/icons/${data.type}/${data.bd_day}.png?v=1`} className="elm-img"/> : <img src="/img/icons/empty.png" className="elm-img"/>) : data.bd_day}</td>
                        <td className="text-danger">{is_type ? (renderElements(props, data.type === 'Element' ? 'mu' : 'ml') ? <img src={`/img/icons/${data.type}/${data.bd_month}.png?v=1`} className="elm-img"/> : <img src="/img/icons/empty.png" className="elm-img"/>) : data.bd_month}</td>
                        <td>{is_type ? (renderElements(props, data.type === 'Element' ? 'yu' : 'yl') ? <img src={`/img/icons/${data.type}/${data.bd_year}.png?v=1`} className="elm-img"/> : <img src="/img/icons/empty.png" className="elm-img"/>): data.bd_year}</td>
                    </tr>
                } else
                    return <td></td>;
            })}
        </tbody>);
    } else {
        return (<tbody>
            <tr>
                <th colSpan="4"><p>Not found</p></th>
            </tr>
        </tbody>);
    }
    
}


Step3 = reduxForm({
    form: 'Step_2'
})(Step3);
Step3 = connect(
    (state, props) => ({
       state: { values: props.values, GuestData: props.GuestData },
       initialValues: props && props.values ? props.values : {start : true}
   })
)(Step3);
export default Step3;