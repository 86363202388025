import Utils from './Utils';
import * as DataManager from './DataManager';

const page_size = 20;

export default function ActionAdmin() {

}
export async function Get(model, s = false) {
    if (!model || Object.values(Models).indexOf(model) < 0) return;
    s = s ? '?' + DataManager.serializeToQstr(s) : '';
    try {        
        let resp = await fetch(`/api/${model}/GetAsync${s}`, {
            method: 'GET',
            headers: Utils.headerXRequest()
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export async function Get_Report(model, s = false) {
    if (!model || Object.values(Models).indexOf(model) < 0) return;
    s = s ? '?' + DataManager.serializeToQstr(s) : '';
    try {
        let resp = await fetch(`/api/Report/${model}${s}`, {
            method: 'GET',
            headers: Utils.headerXRequest()
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}
export async function Get_PackageConfigByProject(xIndex) {
    if (!xIndex) return;
    try {
        let resp = await fetch(`/api/${Models.Package}/GetConfig?project_id=${xIndex}`, {
            method: 'GET',
            headers: Utils.headerXRequest()
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export async function Get_List(model,xIndex, s = false) {
    if (!model || Object.values(Models).indexOf(model) < 0) return;
    if (!xIndex) xIndex = 1;
    s = s ? '&' + DataManager.serializeToQstr(s) : '';
    try {
        let resp = await fetch(`/api/${model}/List?page_numb=${xIndex}&page_size=${page_size}${s}`, {
            method: 'GET',
            headers: Utils.headerXRequest()
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export async function Get_List_Using(model, xIndex, s = false) {
    if (!model || Object.values(Models).indexOf(model) < 0) return;
    if (!xIndex) xIndex = 1;
    s = s ? '&' + DataManager.serializeToQstr(s) : '';
    try {
        let resp = await fetch(`/api/${model}/Using?page_numb=${xIndex}&page_size=${page_size}${s}`, {
            method: 'GET',
            headers: Utils.headerXRequest()
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export async function Update_Model(model,data) {
    if (!model || Object.values(Models).indexOf(model) < 0) return false;
    if (!data) return false;
    try {
        let resp = await fetch(`/api/${model}/Update`, {
            method: 'PUT',
            headers: Utils.headerXRequest(),
            body: JSON.stringify(data)
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export async function Post_Model(model, data) {
    if (!model || Object.values(Models).indexOf(model) < 0) return false;
    if (!data) return false;
    try {
        let resp = await fetch(`/api/${model}/SaveAsync`, {
            method: 'POST',
            headers: Utils.headerXRequest(),
            body: JSON.stringify(data)
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
} export async function ChangePassword(data) {
    if (!data) return false;
    try {
        let resp = await fetch(`/api/Users/ChangePassword`, {
            method: 'POST',
            headers: Utils.headerXRequest(),
            body: JSON.stringify(data)
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}


export async function Report(model, data, is_json = false, f = null) {
    if (!data) return false;
    f = DataManager.checkNotNull(f) ? ('?filename=' + f) : ''; 
    try {
        let resp = await fetch(`/api/ReportFile/${model}${f}`, {
            method: 'POST',
            headers: Utils.headerXRequest(),
            body: JSON.stringify(data)
        });
        if (is_json) {
            let resp_data = await resp.json();
            return resp_data;
        } else {
            let resp_data = await resp.blob();
            return resp_data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}


export async function Post(model, data) {
    //if (!model || Object.values(Models).indexOf(model) < 0) return false;
    if (!data) return false;
    try {
        let resp = await fetch(`/api/${model}`, {
            method: 'POST',
            headers: Utils.headerXRequest(),
            body: JSON.stringify(data)
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export async function Delete_Model(model, data) {
    if (!model || Object.values(Models).indexOf(model) < 0) return false;
    if (!data) return false;
    data = '?id=' + data;
    try {
        let resp = await fetch(`/api/${model}/DeleteAsync${data}`, {
            method: 'DELETE',
            headers: Utils.headerXRequest()
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}
export async function Delete_ProjectPerson(data) {
    if (!data) return false;
    data = '?id=' + data;
    try {
        let resp = await fetch(`/api/${Models.Project}/DeletePerson${data}`, {
            method: 'DELETE',
            headers: Utils.headerXRequest()
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}


export async function Upload_file(file, model = false) {
    try {
        var formData = new FormData();
        formData.append('file', file);
        model = model ? '?type=' + model : '';
        let resp = await fetch(`/api/${Models.Utils}/UploadFile${model}`, {
            method: 'POST',
            headers: Utils.headerXFromData(),
            body: formData
        });
        let resp_json = await resp.json();
        return resp_json;
    } catch (err) {
        console.log(err);
        throw err;
    }
}
export async function Upload_Profile_data(file,proj) {
    try {
        let formData = new FormData();
        formData.append('file', file);
        let model = `?proj=${proj}&type=profile`;
        let resp = await fetch(`/api/${Models.Utils}/UploadProfile${model}`, {
            method: 'POST',
            headers: Utils.headerXFromData(),
            body: formData
        });
        let resp_json = await resp.json();
        return resp_json;
    } catch (err) {
        console.log(err);
        throw err;
    }
}



export async function Upload_files(files, model = false) {
    try {
        var formData = new FormData();
        var ins = files.length;
        for (var x = 0; x < ins; x++) {
            formData.append('uploadfiles', files[x], files[x].name);
        }
        model = model ? '/' + model : '';
        let resp = await fetch(`${Models.Utils}/UploadFiles${model}`, {
            method: 'POST',
            //headers: DataManager.headerXFromData(),
            body: formData
        });
        let resp_json = await resp.json();
        return resp_json;
    } catch (err) {
        console.log(err);
        throw err;
    }
}
export async function Get_file(model, file_name = '', s = false) {
    s = s ? '&' + DataManager.serializeToQstr(s) : '';
    try {
        let resp = await fetch(`/api/${Models.Utils}/GetExcel?model=${model}&file_name=${file_name}${s}`, {
            method: 'GET',
            headers: Utils.headerXRequest()
        });
        let resp_json = await resp.blob();
        return resp_json;
    } catch (err) {
        console.log(err);
    }
    return;
}

export async function Create_Guest(data) {
    if (!data) return false;
    try {
        let resp = await fetch('/api/Guest/CreateGuest', {
            method: 'POST',
            headers: Utils.headerXRequest(),
            body: JSON.stringify(data)
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}
export async function GetList_Project_Using(model, xIndex, s = false) {
    if (!model || Object.values(Models).indexOf(model) < 0) return;
    if (!xIndex) xIndex = 1;
    s = s ? '&' + DataManager.serializeToQstr(s) : '';
    try {
        let resp = await fetch(`/api/${model}/Using?page_numb=${xIndex}&page_size=${page_size}${s}`, {
            method: 'GET',
            headers: Utils.headerXRequest()
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}												    
export async function GetList_Project_Using_Data(model, xIndex, s = false) {
    if (!model || Object.values(Models).indexOf(model) < 0) return;
    if (!xIndex) xIndex = 1;
    s = s ? '&' + DataManager.serializeToQstr(s) : '';
    try {
        let resp = await fetch(`/api/${model}/UsedData?page_numb=${xIndex}&page_size=${page_size}${s}`, {
            method: 'GET',
            headers: Utils.headerXRequest()
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const Models = {
        BehaviorEighteen:'BehaviorEighteen',
        FastenModel: 'FastenModel',
        Figures: 'Figures',
        Guest: 'Guest',
        MainElement: 'MainElement',
        PerceptionRegarding: 'PerceptionRegarding',
        Ranking: 'Ranking',
        Recommendation: 'Recommendation',
        RelationshipParents: 'RelationshipParents',
        SpecificationSoulmaid: 'SpecificationSoulmaid',
        Users: 'Users',
        UserPromo: 'UserPromo',
        WorkingAlone: 'WorkingAlone',
        WorkingOthers: 'WorkingOthers',
        Company: 'Company',
        Department: 'Department',
        Position: 'Position',
        Package: 'Package',
        Project: 'Project',
		ProjectUsing: 'ProjectUsing',			 
        ProjectClient: 'ProjectClient',
							 PersonData: 'PersonData',
        Promos: 'Promos',
        PromoCode: 'PromoCode',
        Consent: 'Consent',
        Utils: 'Utils',
        OptionalField: 'OptionalField',
        Pro: 'Pro',
        Province: 'Province',
		PageForm:'PageForm',				
        Country: 'Country',
    District: 'District',
    Report: 'Report',
    Ages: 'Ages',
    FASTEn_Company: 'FASTEn_Company',
    ProjectPromo: 'ProjectPromo',
    CompanyByProject:'CompanyByProject'
}


