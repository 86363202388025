import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Utils from '../Common/Utils';
import $ from 'jquery';
import { ApplicationState } from '../../store';
import { Field, reduxForm } from "redux-form";
import * as PageFormStore from '../../store/PageForm';
import * as HtmlElement from '../Common/HtmlElement';
import * as Actions from '../Common/Actions';
import * as ActionAdmin from '../Common/ActionAdmin';
import * as DataManager from '../Common/DataManager';
import Loading from '../Common/Loading';
import selectOption from '../Common/selectOption';
import Inputmask from "inputmask";
import { stat } from 'fs';

class PageForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false, props: props, package_selection: [], company_selection: [], company_selected: false,
            modal: { title: '', msg: '' }, xdata: props.xdata
        }
        this.getUsers = (input) => {
            let r = this.state.company_selected && this.state.company_selected.value ? { company_id: this.state.company_selected.value } : '';
            return Actions.DropDown_List(ActionAdmin.Models.Users, input, DataManager.serializeToQstr(r)).then(xdata => {
                return xdata
            });
        }
        this.selectedPackage = (e, val) => {
            console.log(val);
        }
        this.selectedCompany = (e, val) => {
            this.setState({ company_selected: val });
            console.log(val);
        }
        this.selectedUser = (e, val) => {
            console.log(val);
        }
        this.loadPackage();
        this.loadCompany();


        this.submit = (vals) => {
            //console.log('---------subbbbbbbbb-------->')
            //console.log(vals);
            //let data = [];
            //data[0] = { id: vals.id, name: vals.name, description: vals.description, is_active: vals.is_active , is_first: true };
            
            this.setState({ loading: true });
            //alert('ss');
            ActionAdmin.Post(ActionAdmin.Models.Project + '/SavePage', vals).then(xdata => {
                if (xdata.status === 200) {
                    //let tmp = {};
                    //vals['id'] = xdata.data[0]['id'];
                    
                    this.props.dispatch({
                        type: 'UPDATE_PAGE_DATA',
                        xIndex: null,
                        data: xdata.data
                    });
                    //this.setState({ modal: { title: 'บันทึกสำเร็จ', msg: 'บันทึกสำเร็จ' } });
                    //$('.modal').modal('show');
                    this.props.history.goBack();
                } else {
                    this.setState({ modal: { title: 'ไม่สามารถบันทึกข้อมูลได้', msg: xdata.Message ? xdata.Message : xdata.message } });
                    $('.modal').modal('show');                   
                }
            }).then((x) => this.setState({
                
                loading: false
                
                
            })
            );
        }
    }

    
    componentDidMount() {
        this.ensureDataFetched();    
        //Inputmask("99:99").mask(document.getElementsByClassName("imask-time"));
    }

    // This method is called when the route parameters change
    componentDidUpdate(prevProps) {
        if (this.props.pristine && !prevProps.pristine) {
            this.setState({ company_selected: false });
        }
        this.ensureDataFetched();
    }
    ensureDataFetched() {
        const xIndex = this.props.match.params.xIndex || 0;
        //alert(xIndex);
        this.props.requestData(xIndex);
        //ActionAdmin.Get(ActionAdmin.Models.PageForm).then(xdata => {
        //    //this.props.form.
        
        //});
        //console.log(this.props.);
    }
    loadPackage() {
        Actions.DropDown_List(ActionAdmin.Models.Package).then(xdata => {
            this.setState({ package_selection: xdata });       
        });
    }
    loadCompany() {
        Actions.DropDown_List(ActionAdmin.Models.Company).then(xdata => {
            this.setState({ company_selection: xdata, company_selected: false });
        });
    }
    
 
    render() {
        const { handleSubmit, onChange, pristine, reset, submitting  } = this.props;
        
        return (
            <>
            <form className="form-horizontal" onSubmit={handleSubmit(this.submit)} >
                    <div class="card"><Loading loading={this.state.loading} txt={true} />
                        <div class="card-body">

                <div class="row">
                    <div class="col-sm-8 height_500">
                                    <label htmlFor="name" className="col-sm-12 col-form-label">Name:</label>
                            <div className="form-group row">                                
                                        <div className="col-sm-12">
                                            <Field  name="name" component="input" type="text" className="form-control" id="name" required />
                                </div>
                                    </div>
                                    <div class="form-group row">
                                        <div className="col-sm-12">
                                            <label class="form-check-label" for="is_active">
                                                <Field name="is_active" component="input" type="checkbox" id="is_active" /> &nbsp;Is active</label>
                                        </div>
                                    </div>

                                    <label htmlFor="description0" className="col-sm-12 col-form-label">Description :</label>
                            <div className="form-group row">
                                        <div className="col-sm-12">
                                            <Field name="description" component={HtmlElement.ContentEditor} type="text" className="form-control" id="description" />
                                </div>
                            </div>
                                </div>

                                <div class="col-sm-4">
                                    <h4>    Project/Promos Info</h4>
                                    
                                    <table class="table table-hover table-borderless table-data-list">
                                        <tbody>
                                            
                                            <tr>
                                                <th scope="row"> Name :</th>
                                                <td>{this.props.xdata.project_Info ? this.props.xdata.project_Info.name: '-'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row"> Path :</th>
                                                <td>{this.props.xdata.project_Info ? this.props.xdata.project_Info.path : '-'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Description:</th>
                                                <td>{this.props.xdata.project_Info ? this.props.xdata.project_Info.description : '-'}</td>
                                            </tr>

                                           

                                        </tbody>
                                    </table>
                                    
                                </div>
                                              
                    </div>
                    <div className="row">
                        {this.props.xerror ? <div className="alert alert-danger" role="alert"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.props.xerror}</div> : <></>}
                    </div>
                    <div className="row">
                                <hr />
                                <div className="col-sm text-center">
                        <button type="button" className="btn btn-danger mr-2" onClick={reset}><i className="fa fa-times"></i> Clear</button>
                        <button type="submit" className="btn btn-primary"><i className="fa fa-check"></i> {this.props.btnName ? this.props.btnName : 'Save & Changes'}</button>
                                </div>
                            </div>
                    </div>
                </div>
            </form>
            { HtmlElement.RenderModal(this.state.modal) }
        </>
        );
    }
}

//const mapDispatchToProps = { RequestPageFormAction, updateData };




PageForm = reduxForm({
    form: "Frm_PageForm",
    enableReinitialize: true    
})(PageForm);

PageForm = connect(
    (state, props) => ({
        //props: props,
        //state: state,
        //PageForm: state.PageForm,
        xdata: state.pageForm.data,
        initialValues: state.pageForm.data,
    }), PageFormStore.actionCreators
)(PageForm);
//export default connect(
//    (state, props) => ({
//        props: props,
//        state: state,
//        PageForm: state.PageForm,
//        xdata: state.PageForm.data,
//        initialValues: state.PageForm.data
//    }),// actionCreators
//    dispatch => bindActionCreators(actionCreators, dispatch)
//)(PageForm);
export default PageForm;

//const mapStateToProps = (state, props) => ({
//    initialValues: props.initialValues, // retrieve name from redux store 
//})
//export default ModalForm(
//    mapStateToProps
//)(reduxForm({
//    form: 'sixxxmple',
//    enableReinitialize: true
//})(ModalForm))