import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormInfo from './Step1';
import $ from 'jquery';
import '../../templates/public/css/wizard.css';
import Utils from '../Common/Utils';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import * as Actions from '../Common/Actions';
import * as HtmlElement from '../Common/HtmlElement';
import * as DataManager from '../Common/DataManager';
import Loading from '../Common/Loading';
import Auth from '../Common/Auth';
import MetaTags from 'react-meta-tags';
const guest_data = {Request_Chindate: null,Person_Info: null,Data_Model: null,is_complete:false};
const is_agreed_x = 'is_agreed_x';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            values: false, component: 0, GuestData: guest_data, line_connect: 0, is_agreed: false,
            modal: { title: '', msg: '' }, loading: false, user: Auth.GetUser()
         };
    }
    submit0 = (values) => {
        if (!values) return;
        if (!values.is_agreed || values.is_agreed !== true) {
            this.setState({ modal: { title: 'Warning', msg: 'กรุณากดยอมรับใรับเงื่อนไขและข้อตกลงการใช้บริการ' } });
            $(function () {
                $(".modal").appendTo("body").modal({ show: true });
            });
            return;
        }
        if (!this.state.user || !this.state.user.packageInfo || !this.state.user.packageInfo.projectInfo) {
            if (!values.Code || !DataManager.checkNotNull(values.Code) || values.Code.length < 6) {
                this.setState({ modal: { title: 'Warning', msg: 'กรุณาระบุ Code ให้ถูกต้อง เพื่อเข้าใช้งาน' } });
                $(function () {
                    $(".modal").appendTo("body").modal({ show: true });
                });
                return;
            }

            values['logon'] = 'Promo';
            values['type'] = 'code';
            this.setState({ loading: true });
            Actions.ProjectAuth(values).then(xdata => {
                if (xdata.status === 200) {
                    //console.log('---> Who im I <----');
                    //console.log(xdata);

                    Auth.Login(xdata.data);
                    if (xdata.data.packageInfo && xdata.data.packageInfo.promo_Code && xdata.data.packageInfo.promo_Code.person_info_id) {
                        sessionStorage.setItem('personxi', xdata.data.packageInfo.promo_Code.person_info_id);
                        //alert(xdata.data.dataModel.person_info_id);
                    }
                    window.location.reload(false);
                    return;
                } else {
                    this.setState({ modal: { title: 'Warning', msg: xdata.message ? xdata.message : xdata.Message } });
                    $(".modal").appendTo("body").modal({ show: true });
                    this.setState({ loading: false });
                    return;
                }
                //this.setState({ loading: false });
            });//.then((x) => this.setState({ loading: false }));
            return;
        }
        sessionStorage.setItem(is_agreed_x, 'y');
        this.setState({ is_agreed: true });
        this.setState({ component: 1 });
        this.renderConnecting(1);
    }

    submit1 = (values) => {
        //console.log(values);        
        //Actions.IsProject_Available(this.state.user.packageInfo.projectInfo.id).then(xdata => {
            //if (xdata && xdata.status === 200 && xdata && xdata.data.status) {
                const validate = Utils.step1validate(values, values.unknow_time, values.unknow_place, ((this.state.user && this.state.user.packageInfo) && (this.state.user.packageInfo.projectInfo && this.state.user.packageInfo.projectInfo) && (this.state.user.packageInfo.projectInfo.project_type === 'Project')
                    && this.state.user.packageInfo.company_Info));
                if (validate !== true) {
                    this.setState({ modal: { title: 'Warning', msg: validate }, loading: false });
                    $(function () {
                        $(".modal").appendTo("body").modal({ show: true });
                    });
                    return;
                }
                this.setState({ loading: true });

                let places = {
                    country: { label: 'Thailand', value: 'THA' },
                    province: { id: 10, label: "Bangkok", name: "THA", value: "10" },
                    district: { id: 1001, label: "Phra Nakhon", name: "10", value: "1001" }
                };
                let data = this.state.GuestData;
                data.Person_Info = values;
                if (values.unknow_place && values.unknow_place === true) {
                    data.Person_Info['Birth_Place'] = places;
                    data['country'] = places.country;
                    data['province'] = places.province;
                    data['district'] = places.district;

                    values['country'] = places.country;
                    values['province'] = places.province;
                    values['district'] = places.district;
                    //data.Person_Info['country'] = places.country;
                    //data.Person_Info['province'] = places.province;
                    //data.Person_Info['district'] = places.district;

                } else {
                    data.Person_Info['Birth_Place'] = { country: values.country, province: values.province, district: values.district };
                    //data.Person_Info['country'] = values.country;
                    //data.Person_Info['province'] = values.province;
                    //data.Person_Info['district'] = values.district;
                }
                var uid = sessionStorage.getItem('personxi');
                if (DataManager.checkNotNull(uid) && !DataManager.isGuidEmpty(uid)) {
                    data.Person_Info['id'] = uid;
                }

                if (values.unknow_time && values.unknow_time === true)
                    values['birth_time'] = '01:00';
                data.Person_Info['dob'] = Utils.InputToDateFormat(values.date_of_birth, values.birth_time);

                values['Users'] = this.state.user ? this.state.user : null;
                data['Users'] = this.state.user ? this.state.user : null;

                let req_data = {};
                if (data.Person_Info && data.Person_Info.dob) {

                    req_data = {
                        date: data.Person_Info.dob,
                        Country: data.Person_Info.country,
                        Province: data.Person_Info.province,
                        District: data.Person_Info.district,
                        Users: this.state.user,
                        Person_Info: data.Person_Info
                    };
                } else if (data.person_Info && data.person_Info.dob) {
                    req_data = {
                        date: data.person_Info.dob,
                        Country: data.person_Info.country,
                        Province: data.person_Info.province,
                        District: data.person_Info.district,
                        Users: this.state.user,
                        Person_Info: data.person_Info
                    };
                }
                if (!req_data.Country || DataManager.isEmptyObject(req_data.Country) || DataManager.checkNotNull(req_data.Country.value) || req_data.Province.label) {
                    req_data.Country = values.country;
                    req_data.Province = values.province;
                    req_data.District = values.district;
                }

                if (req_data.Person_Info.birth_Place && req_data.Person_Info.birth_Place.person_Info)
                    req_data.Person_Info.birth_Place.person_Info = null;
                if (req_data.Person_Info.birth_Place && !DataManager.checkNotNull(req_data.Person_Info.birth_Place.country_code)) {
                    delete req_data.Person_Info.birth_Place;
                }

                if (req_data.Person_Info && req_data.Person_Info.Users)
                    req_data.Person_Info.Users = null;

                if (req_data.Users && req_data.Users.packageInfo && req_data.Users.packageInfo.promo_Code) {
                    req_data.Users.packageInfo.promo_Code.person_Info = null;
                    req_data.Users.packageInfo.promo_Code.projectInfo = null;
                }
                if (req_data.Person_Info && req_data.Person_Info.promo_Code && req_data.Person_Info.promo_Code.data_Model) {
                    req_data.Person_Info.promo_Code.data_Model = null;
                    req_data.Person_Info.promo_Code.projectInfo = null;
                }

                if (req_data.Users.dataModel && req_data.Users.dataModel.person_Info)
                    req_data.Users.dataModel.person_Info = null;
                if (req_data.Users.dataModel && req_data.Users.dataModel.packageMain)
                    req_data.Users.dataModel.packageMain = null;
                if (req_data.Users.dataModel && req_data.Users.dataModel.promo_Code)
                    req_data.Users.dataModel.promo_Code = null;
                
                //console.log(req_data);
                Actions.SavePerson(req_data).then(xdata => {
                    if (xdata.status === 200) {
                        if (xdata.data.person_Info && !DataManager.isGuidEmpty(xdata.data.person_Info.id)) {
                            sessionStorage.setItem('personxi', xdata.data.person_Info.id);
                        }
                        
                        this.setState({ values: values, component: 2, GuestData: xdata.data });
                        this.renderConnecting(2);
                    } else {                        
                        this.setState({ modal: { title: 'Warning', msg: xdata.message ? xdata.message : xdata.Message } });
                        $(function () {
                            $(".modal").appendTo("body").modal({ show: true });
                        });
                    }
                }).then(x => {this.setState({ loading: false });});
                
        //    } else {
        //        this.setState({ loading: false });
        //        this.setState({ modal: { title: 'Warning', msg: xdata.message ? xdata.message : xdata.Message } });
        //        $(function () {
        //            $(".modal").appendTo("body").modal({ show: true });
        //        }); 
        //    }   
            
        //});
        
    }
    submit2 = (values) => {      

        //return;
        let v = values.verify;
        //console.log(v);
        //console.log(v);
        //return;
        if (this.state.user && this.state.user.packageInfo.packageConfig && this.state.user.packageInfo.packageConfig.show_verification === false) {
            //---
        } else {
            if (!values || !values.verify) {
                this.setState({ modal: { title: 'Warning', msg: <p>กรุณาตอบคำถามให้ครบ เพื่อไปขั้นตอนถัดไป</p> } });
                $(function () {
                    $(".modal").appendTo("body").modal({ show: true });
                });
                return;
            }
            let q = this.IsShowLast() ? 7 : 6;
            //console.log('this.IsShowLast()' + this.IsShowLast())
            if (Object.keys(v).length < q) {
                let msg = <>{Object.keys(v).length}/{q}</>;
                //if (q === 6)
                //   msg = <>{Object.keys(v).length - 1}/{q}</>;
                this.setState({ modal: { title: 'Warning', msg: <p>กรุณาตอบคำถามให้ครบ <span className="font-size-26 text-inline-ans">{msg}</span></p> } });
                $(function () {
                    $(".modal").appendTo("body").modal({ show: true });
                });
                return;
            }
        }
        this.setState({
            loading: true,
        });

        //Actions.IsProject_Available(this.state.user.packageInfo.projectInfo.id, true).then(xdata => {
            //if (xdata && xdata.status === 200 && xdata && xdata.data.status) {

                Actions.SaveAnswer(v).then(xdata => {
                    if (xdata.status === 200) {
                        this.setState({
                            loading: false,
                            component: 3
                        });
                        this.renderConnecting(3);
                    } else {
                        this.setState({
                            loading: false,
                        });
                        this.setState({ modal: { title: 'Error', msg: <p>{xdata.message ? xdata.message : xdata.Message} #3</p> } });
                        $(function () {
                            $(".modal").appendTo("body").modal({ show: true });
                        });
                    }
                    return;
                }).then((x) => this.setState({ loading: false }));

        //    } else {
        //        this.setState({ modal: { title: 'Warning', msg: xdata.message ? xdata.message : xdata.Message } });
        //        $(function () {
        //            $(".modal").appendTo("body").modal({ show: true });
        //        });
        //        this.setState({ loading: false });
        //    }
        //});
        
    }

    submit3 = (values) => {
        if (!values) return;        
        //Actions.IsProject_Available(this.state.user.packageInfo.projectInfo.id, true).then(xdata => {
        //    if (xdata && xdata.status === 200 && xdata && xdata.data.status) {
                var uid = sessionStorage.getItem('personxi');
                if (DataManager.checkNotNull(uid) && !DataManager.isGuidEmpty(uid)) {
                    this.setState({ loading: true });
                    Actions.Get_Step(uid, 3).then(xdata => {
                    }).then((x) => this.setState({ loading: false }));
                }
                this.setState({ component: 4 });
                this.renderConnecting(4);
        //    } else {
        //        this.setState({ modal: { title: 'Warning', msg: xdata.message ? xdata.message : xdata.Message } });
        //        $(function () {
        //            $(".modal").appendTo("body").modal({ show: true });
        //        });
        //    }
        //    this.setState({ loading: false });
        //});
       
    }
    submit4 = (values) => {
        //console.log(values);
        return;
    }

    goBack = (values) => {
        //console.log('Back to: ' + values);
        if (!values || values < 1 || values > 4) return;
        this.setState({ component: values });
        this.renderConnecting(values);
    }

    stepTwo = (v) => {
        //console.log('step 2() ....');
        //console.log(v);
        v['Users'] = this.state.user ? this.state.user : null;
        this.setState({ GuestData: v });
    }

    stepThree = (v) => {
        //console.log('step 3() ....');
        //console.log(v);
        
        if (!v) return;
        let data = this.state.GuestData;
        if (v.person_Info) {
            data.Person_Info = v.person_Info;
            data['person_Info'] = v.person_Info;
        }
        if (v.request_Chindate)
            data.Request_Chindate = v.request_Chindate;
        this.setState({ GuestData: data });
    }

    stepFour = (v) => {
        //console.log('step 4() ....');
        //console.log(v);
    }

    cancelProc = ()=>{
        this.setState({ component: 1, GuestData: guest_data, line_connect: 0, values:false });
        this.renderConnecting(1,true);
    }


    renderConnecting(s, is_reset = false) {
        if (is_reset) {
            $('.round-tab').removeClass('active').removeClass('current');
            $('#step1').addClass('active');
            return;
        }
        $('.round-tab').removeClass('current');
        if (s > 1){
           if (s-1 > this.state.line_connect) {
               this.setState({ line_connect:s - 1});
           }
        }
        if ($(`#step${s}`).hasClass("active"))
            $(`#step${s}`).addClass('current');

        $(`#step${s}`).addClass('active');
        if (s === 1 && this.state.line_connect > 0){
           $('#step1').addClass('current');
        }else if(s > 1){
           let onstep = this.state.line_connect + 1;
            if (onstep > s || this.state.GuestData.is_complete) {
                $(`#step${s}`).addClass('current');                
            }else if (this.state.line_connect == 2 && s === 3) {
                $(`#step2`).addClass('active');
                $(`#step3`).addClass('current');
            }
        }
        return;
    }
    IsShowLast() {
        if (this.isUnknowTime() || this.isUnknowPlace())
            return false;
        return true;
    }

    isUnknowTime() {
        if (typeof this.state.values.unknow_time != 'undefined')
            return this.state.values.unknow_time;
        else if (this.state.values.person_Info && typeof this.state.values.person_Info.unknow_time != 'undefined')
            return this.state.values.person_Info.unknow_time;
        else if (this.state.values.Person_Info && typeof this.state.values.Person_Info.unknow_time != 'undefined')
            return this.state.values.Person_Info.unknow_time;
        else if (this.state.GuestData && this.state.GuestData.person_Info && typeof this.state.GuestData.person_Info.unknow_time != 'undefined')
            return this.state.GuestData.person_Info.unknow_time;
        else if (this.state.GuestData && this.state.GuestData.Person_Info && typeof this.state.GuestData.Person_Info.unknow_time != 'undefined')
            return this.state.GuestData.Person_Info.unknow_time;
        else
            return false;
    }
    isUnknowPlace() {
        
        if (typeof this.state.values.unknow_place != 'undefined')
            return this.state.values.unknow_place;
        else if (this.state.values.person_Info && typeof this.state.values.person_Info.unknow_place != 'undefined')
            return this.state.values.person_Info.unknow_place;
        else if (this.state.values.Person_Info && typeof this.state.values.Person_Info.unknow_place != 'undefined')
            return this.state.values.Person_Info.unknow_place;
        else if (this.state.GuestData && this.state.GuestData.person_Info && typeof this.state.GuestData.person_Info.unknow_place != 'undefined')
            return this.state.GuestData.person_Info.unknow_place;
        else if (this.state.GuestData && this.state.GuestData.Person_Info && typeof this.state.GuestData.Person_Info.unknow_place != 'undefined')
            return this.state.GuestData.Person_Info.unknow_place;
        else
            return false;
    }

    renderComponent() {
        if (!this.state.is_agreed)
            return <Step0 values={this.state.values} onSubmit={this.submit0} />;
        switch(this.state.component){
            case 1:
                return <Step1 values={this.state.values} onSubmit={this.submit1}/>;
            case 2:                
                return <Step2 values={this.state.values} GuestData={this.state.GuestData} onStepTwo={this.stepTwo} onSubmit={this.submit2} onGoBack={this.goBack}/>;
            case 3:
                return <Step3 values={this.state.values} GuestData={this.state.GuestData} onStepThree={this.stepThree} onSubmit={this.submit3} onGoBack={this.goBack} onCancelProc={this.cancelProc}/>;
            case 4:
                return <Step4 values={this.state.values} GuestData={this.state.GuestData} onStepFour={this.stepFour} onSubmit={this.submit4} onGoBack={this.goBack} onCancelProc={this.cancelProc} onBody={true}/>;
            default:
                return <Step0 values={this.state.values} onSubmit={this.submit0} />;
        }        
    }

    componentDidMount() {
        document.title = "FASTEn Model ™";
        if (this.state.user && this.state.user.packageInfo && this.state.user.packageInfo.projectInfo && this.state.user.packageInfo.projectInfo.project_type === 'Promo') {
            this.setState({ component: 1, is_agreed: true });
            //this.renderConnecting(1);
        } else if (this.state.user && this.state.user.packageInfo && this.state.user.packageInfo.projectInfo && this.state.user.packageInfo.projectInfo.project_type === 'Project') {
            let is_skip = sessionStorage.getItem(is_agreed_x);
            if (is_skip && is_skip === 'y')
                this.setState({ component: 1, is_agreed: true });
        } else
            sessionStorage.setItem(is_agreed_x, 'n');
        if (this.state.user && Auth.isLoggedIn()) {
            //const xIndex = this.props.xIndex || 0;     
            var xi = sessionStorage.getItem('personxi');
            if (xi && !DataManager.isGuidEmpty(xi)) {
                this.getPersonInfo(xi);
            }
        } else {
            sessionStorage.removeItem('personxi');
        }

        if (this.state.user && this.state.user.dataModel && this.state.user.dataModel.person_Info) {
            let person_data = DataManager.Profile_ToForm(this.state.user.dataModel);
            let vals = this.state.values ? [...this.state.values, person_data.person_Info] : person_data.person_Info;
            //console.log(vals);
            vals['Data_Model'] = this.state.user.dataModel;
            vals['data_Model'] = this.state.user.dataModel;            
            this.setState({ values: vals });
        }
    }

    getPersonInfo(id) {
        this.setState({ loading: true });
        Actions.GuestDataById(id).then(xdata => {
            if (xdata.status === 200 && xdata.data && xdata.data.person_Info) {
                //console.log('----------- GuestDataById ----------');
                //console.log(xdata);
                let iniVals = xdata.data.person_Info;
                let dd = xdata.data.person_Info.dob;
                iniVals['is_complete'] = true;
                iniVals['date_of_birth'] = DataManager.dateTimeToInput(dd, false, false).split(' ')[0];
                iniVals['country'] = { value: xdata.data.person_Info.birth_Place.country_code, label: xdata.data.person_Info.birth_Place.country_name };
                iniVals['province'] = { value: xdata.data.person_Info.birth_Place.province_id, label: xdata.data.person_Info.birth_Place.province_name, name: xdata.data.person_Info.birth_Place.country_code };
                iniVals['district'] = { value: xdata.data.person_Info.birth_Place.district_id, label: xdata.data.person_Info.birth_Place.district_name, name: xdata.data.person_Info.birth_Place.province_id };
                iniVals['birth_time'] = Utils.dateTimeToStringNumber(dd, 'th-TH').split(' ')[1];


                if (xdata.data.person_Info.company_Employee && xdata.data.person_Info.company_Employee.id) {
                    iniVals['department'] = { value: xdata.data.person_Info.company_Employee.department.id, id: xdata.data.person_Info.company_Employee.department.id, label: xdata.data.person_Info.company_Employee.department.name };
                    iniVals['position'] = { value: xdata.data.person_Info.company_Employee.position.id, id: xdata.data.person_Info.company_Employee.position.id, label: xdata.data.person_Info.company_Employee.position.name };
                }

                //console.log(iniVals);
                this.setState({
                    values: iniVals,
                    GuestData: xdata.data//, rendering: <Step4 values={iniVals} onRef={true} GuestData={xdata.data} onStepFour={this.stepFour} onSubmit={this.submit4} onGoBack={this.goBack} onCancelProc={this.cancelProc} />
                });
                if (xdata.data.person_Info && xdata.data.person_Info.promo_Code &&  xdata.data.person_Info.promo_Code.code) {
                    if (xdata.data.person_Info.promo_Code.step === 3) {
                        this.setState({ component: 4, is_agreed: true, loading: false });
                        this.renderConnecting(4);
                        $('#step1').removeClass('active');
                    } else if (xdata.data.person_Info.promo_Code.step === 2) {
                        this.setState({ component: 3, is_agreed: true, loading: false });
                        this.renderConnecting(3);
                    } else if (xdata.data.person_Info.promo_Code.step === 1) {
                        this.setState({ component: 2, is_agreed: true, loading: false });
                        this.renderConnecting(2);
                    } else
                        this.setState({ component: 1, is_agreed: true, loading: false });
                } else if (xdata.data.data_Model && xdata.data.data_Model.step > 0) {
                    if (xdata.data.data_Model.step === 3) {
                        this.setState({ component: 4, is_agreed: true, loading: false });
                        this.renderConnecting(4);
                        $('#step1').removeClass('active');
                    } else if (xdata.data.data_Model.step === 2) {
                        this.setState({ component: 3, is_agreed: true, loading: false });
                        this.renderConnecting(3);
                    } else if (xdata.data.data_Model.step === 1) {
                        this.setState({ component: 2, is_agreed: true, loading: false });
                        this.renderConnecting(2);
                    } else
                        this.setState({ component: 1, is_agreed: true, loading: false });
                }else
                    this.setState({ component: 1, is_agreed: true, loading: false });
            } else
                this.setState({ component: 1, is_agreed: true, loading: false });
        });
    }


    renderStepMenu(state) {
        if (state.is_agreed === true) {
            return (
                <div className="row">
                    <div className="col-sm-12">
                        <div className="wizard">
                            <div className="connecting-line">
                                <div className={`connecting-line-step-${this.state.line_connect}`}></div>
                            </div>
                            <span className="wizard-col">
                                <span className="round-tab active" id="step1"><i className="fa fa-user"></i></span>
                            </span>
                            <span className="wizard-col">
                                <span className="round-tab" id="step2"><i className="fa fa-list"></i></span>
                            </span>
                            <span className="wizard-col">
                                <span className="round-tab" id="step3"><i className="fa fa-question"></i></span>
                            </span>
                            <span className="wizard-col">
                                <span className="round-tab" id="step4"><i className="fa fa-check"></i></span>
                            </span>
                        </div>
                    </div>
                </div>
            );
        } else
            return <></>;
    }
    render(){
        return(
            <div className="container" id="client-container">
                <MetaTags>
                    <title>FASTEn Model ™</title>
                    <meta name="description" content={HtmlElement.getPageDescription()} />
                    <meta property="og:title" content="FASTEn Model ™" />
                </MetaTags>
                {this.renderStepMenu(this.state)}
                <div className="row">
                    <div className="col-sm-12"><Loading loading={this.state.loading} />
                            <div className="padding-10"></div>
                            {this.renderComponent()}                
                        </div>
                </div>
                {HtmlElement.RenderModal(this.state.modal)}
            </div>
                    

        );
        }
    }

export default connect()(Main);
