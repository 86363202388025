import * as React from 'react';
import { connect } from 'react-redux';
import Loading from '../../Common/Loading';
import $ from 'jquery';
import HtmlSet from '../../Common/HtmlSet';
import * as ActionAdmin from '../../Common/ActionAdmin';
import * as Actions from '../../Common/Actions';
import Utils from '../../Common/Utils';
import ModalForm from '../../Common/ModalForm';
import { Field } from "redux-form";
import * as DataManager from '../../Common/DataManager';
import * as HtmlElement from '../../Common/HtmlElement';

class Province extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true, modal: null, xdata: false, search: null, xSearch: DataManager.search_args, frmeror: false
        }
        this.selectCountry = (selected) => {           
            console.log(selected);
        }


        this.search = () => {
            let input_val = document.getElementById('search_name').value;
            var q = [];
            if (DataManager.checkNotNull(input_val))
                q.push('doc=' + input_val);

            console.log(q);

            this.props.history.push(`/admin/province/1/${q.join('||')}`);
        }
        this.clear = () => {
            this.props.match.params.search = undefined;
            this.setState({ search: {}, xSearch: false });
            $('input').val('');
            this.props.history.push(`/admin/province`);
        }
        this.getQstr = (s = null) => {
            if (DataManager.checkNotNull(s)) {
                let search = DataManager.searchQstr(s);
                if (search) {
                    this.setState({ search: search });
                    return search;
                }
            } else {
                this.setState({ search: {} });
            }
            return false;
        }
    }

    submit = (vals) => {
        console.log(vals);
        if (!vals || DataManager.isEmptyObject(vals) || !vals.name_en) {            
            return;
        }
        let spare_country = {};
        if (vals.country) {
            spare_country = vals.country;
        }
        if ((!vals.country_selection || !vals.country_selection.value) && (!vals.id || vals.id === ''))
            return;
        else if (vals.country_selection && vals.country_selection.value) {
            spare_country = { code: vals.country_selection.value, name_en: vals.country_selection.label };
            vals['country_code'] = vals.country_selection.value;
            vals.country = null;
        }
        Utils.HideModalFormTable(vals);

        let is_reload = vals && vals.id && vals.id != 0 ? false : true;
        console.log(is_reload);
        

        this.setState({ loading: true });
        ActionAdmin.Post_Model(ActionAdmin.Models.Province, vals).then(data => {
            if (data.status === 200) {
                let xdata = this.state.xdata;
                if (!vals.country)
                    vals.country = spare_country;
                const i = xdata.data.findIndex((obj => obj.id == vals.id));
                xdata.data[i] = vals;
                this.setState({
                    loading: false,
                    xdata: xdata
                });  
                if (is_reload) this.loadData(this.state.xIndex);
            } else {                
                this.setState({ frmeror: data.Message });
                Utils.ShowModalFormTable(vals);
            }
            
        }).then((x) => this.setState({
            loading: false
        }));
        return;
    }
    deleteData(data) {
        console.log(data)
        let is_reload = false;
        ActionAdmin.Delete_Model(ActionAdmin.Models.Province, data.id).then(xdata => {
            console.log(xdata);
            if (xdata && xdata.status === 200) {
                // ok
                is_reload = true;
            }
            if (is_reload) this.loadData(this.state.xIndex);
        });
    }
    updateFrm(data) {
        let title = data && data.id ? `แก้ไขข้อมูล #${data.id}` : `เพิ่มข้อมูล #New`;
        this.setState({
            modal: <ModalForm form="Form_Modal" xerror={this.state.frmeror} initialValues={data} body={this.renderForm(data)} title={title} onSubmit={this.submit} />
        });
        Utils.ShowModalFormTable(data);
        
    }
    linkDept(data) {
        this.props.history.push('/admin/department/1/comp=' + data.id);
    }
    linkPost(data) {
        this.props.history.push('/admin/position/1/comp=' + data.id);
    }    

    renderForm(data) {
        return (
            <div>                 
                <div className="form-group row">
                    <label htmlFor="name_en" className="col-sm-4 col-form-label">English:</label>
                    <div className="col-sm-8">
                        <Field name="name_en" component="input" type="text" className="form-control" id="name_en" />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="name_th" className="col-sm-4 col-form-label">ไทย:</label>
                    <div className="col-sm-8">
                        <Field name="name_th" component="input" type="text" className="form-control" id="name_th" />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="name_local" className="col-sm-4 col-form-label">Local lang:</label>
                    <div className="col-sm-8">
                        <Field name="name_local" component="input" type="text" className="form-control" id="name_local" />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="latitude" className="col-sm-4 col-form-label">Latitude:</label>
                    <div className="col-sm-8">
                        <Field name="latitude" component="input" type="decimal" className="form-control imask-decimal" id="latitude" />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="longitude" className="col-sm-4 col-form-label">Longitude:</label>
                    <div className="col-sm-8">
                        <Field name="longitude" component="input" type="decimal" className="form-control imask-decimal" id="longitude" />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-lg-4 text-right control-label col-form-label">Country:</label>
                    <div className="col-lg-8">
                        <span className="width-100">
                            <Field id="select-country" name="country_selection" className="custom-dropdown" required="required"
                                onChange={this.selectCountry} multi={false} loadOptions={this.getCountry} autoLoad={true} defaultOptions
                                component={HtmlElement.RenderSelectAsync} aria-describedby="input-country" />
                        </span>
                    </div>
                </div> 
                
            </div>           
            );
    }


    getCountry(input) {
        return Actions.DropDown_List(ActionAdmin.Models.Country, input).then(xdata => {
            return xdata
        });
    }
    
    componentDidMount() {
        const xIndex = this.state.xIndex || 1;
        let search = this.getQstr(this.props.match.params.search);
        this.loadData(xIndex, search);

        
    }


    renderDataTable(props) {
        if (props && props.xdata && props.xdata.data) {
            return (<tbody>
                {props.xdata.data.map((data) => {
                    return <tr key={data.id} id={`rowno${data.id}`}>
                        <td>{data.id}</td>
                        <td>{data.name_en}</td>
                        <td>{data.name_th}</td>
                        <td>{data.name_local}</td>
                        <td>{data.latitude}</td>
                        <td>{data.longitude}</td>
                        <td>{data.country ? `${data.country.name_en} (${data.country.code})` : ''}</td>
                        <td className="text-center center">
                            <div className="btn-toolbar center">
                            <button type="button" className="btn btn-info btn-sm mr-1" onClick={() => this.updateFrm(data)}><i className="fa fa-edit"></i> Edit</button>
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => { if (window.confirm('คุณต้องการลบข้อมูล หรือไม่?')) { this.deleteData(data); } }}><i className="fa fa-times"></i> Delete</button>
                            </div>
                        </td>
                    </tr>
                })}
            </tbody>);
        } else {
            return (<tbody>
                <tr>
                    <th colSpan="10"><p>Not found</p></th>
                </tr>
            </tbody>);
        }
    }

    loadData(xIndex, s = false) {
        ActionAdmin.Get_List(ActionAdmin.Models.Province, xIndex, s).then(xdata => {
            if (xdata.status === 200) {
                this.setState({
                    xdata: xdata,
                    xIndex: xIndex
                    //xSearch: nextProps.match.params.search
                });
            }
            console.log(xdata);
        }).then((x) => this.setState({ loading: false }));
    }
    Download() {
        let search = this.getQstr(this.props.match.params.search);
        let model_name = "Province";
        let file_name = model_name + '_' + DataManager.Guid() + 'province.xlsx';
        this.setState({ loading: true });
        ActionAdmin.Get_file(model_name, file_name, search).then(xdata => {
            console.log(xdata.size);
            if (xdata && xdata.size > 2500) {
                let url = window.URL.createObjectURL(xdata);
                //console.log(url);
                let a = document.createElement('a');
                a.href = url;
                a.download = file_name;
                a.click();
            } else {
                alert('ไม่สามารภดาวน์โหลดได้');
            }
        }).then(x => { this.setState({ loading: false }); });
    }
    componentWillReceiveProps(nextProps) {
        const xIndex = nextProps.match.params.xIndex || 1;
        if ((xIndex === 0 || nextProps.match.params.xIndex === this.state.xIndex) && !Utils.isEmptyObject(nextProps.match.params.search) && (nextProps.match.params.search === this.state.search)) {
            this.setState({
                search: nextProps.match.params.search
            });
            return;
        } else if ((xIndex === 0 || nextProps.match.params.xIndex === this.state.xIndex) && Utils.isEmptyObject(nextProps.match.params.search) && (nextProps.match.params.search === this.state.search))
            return;

        this.setState({
            loading: true
        });

        ActionAdmin.Get_List(ActionAdmin.Models.Province, xIndex, this.getQstr(nextProps.match.params.search)).then(data => {
            if (data.status === 200) {
                this.setState({
                    xdata: data,
                    xIndex: xIndex,
                    search: nextProps.match.params.search,
                    xSearch: this.getQstr(nextProps.match.params.search)
                });
            }
        }).then((x) => this.setState({ loading: false }));

     
    }

    render() {
        return(
            <div className="card">                
                <div className="card-body"><Loading loading={this.state.loading} txt={true}/>
                    <h5 className="card-title card-title-border text-secondary"><i className="fa fa-cog" aria-hidden="true"></i> Province</h5>
                    <div className="row">
                        <div className="col-sm border-right margin-bottom-20">
                            <div className="row">
                                <div className="col-12">
                                    <button type="button" className="btn btn-primary mb-2 mr-1" onClick={() => this.updateFrm({})}><i className="fa fa-plus"></i> เพิ่มข้อมูล</button>
                                    <button type="button" className="btn btn-secondary mb-2 mr-1" onClick={() => this.Download({})}> <i className="fa fa-download">  </i>  Download </button>
                                </div>

                            </div>
                        </div>
                        <div className="col-sm margin-bottom-20">
                            <div className="form-inline">
                                <div className="form-group mx-sm-3 mb-2">
                                    <label for="search_name" className="sr-only">Search</label>
                                    <input type="text" className="form-control" id="search_name" placeholder="Search..." defaultValue={this.state.xSearch.doc} />
                                </div>
                                <button type="button" className="btn btn-primary mb-2 mr-1" onClick={this.search}><i className="fa fa-search"></i> Search</button>
                                <button type="button" className="btn btn-secondary mb-2" onClick={this.clear}><i className="fa fa-refresh"></i> Clear</button>
                            </div>
                        </div>
                    </div>
                    {this.state.modal}
                   
                    <table className="table table-sm table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">English</th>
                                <th scope="col">ไทย</th>
                                <th scope="col">Local name</th>
                                <th scope="col">Latitude</th>
                                <th scope="col">Longitude</th>
                                <th scope="col">ประเทศ</th>
                                <th scope="col" className="btn-col-2"></th>
                            </tr>
                        </thead>
                        {this.state.loading && !this.state.xdata ? <tbody><tr><td colSpan="10"><p>Loading...</p></td></tr></tbody> : this.renderDataTable(this.state)}
                    </table>
                    <div className="row">
                        <div className="col-sm-12 float-right">
                            <div className="dataTables_paginate paging_simple_numbers float-right padding-right" id="zero_config_paginate">
                                {this.state.loading && !this.state.xdata ? 'Looking for data...' : HtmlSet.renderPagination(this.state, '/admin/province/', this.props.match.params.search)}                                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



export default connect()(Province);