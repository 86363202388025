import React from 'react';
import $ from 'jquery'

const user_key_store = "fastent_user";
const is_logged = 'is_logged';
const sss_token = 'model_token';

class Auth extends React.Component {
   
    static isAdminPage(location) {
        var first = location.pathname;
        try {
            first.indexOf(1);
            first.toLowerCase();
            first = first.split("/")[1];
        } catch (e) {
            return false;
        }
        return first === 'admin';
    }

    static GetToken() {
        try {
            if (this.isLoggedIn()) {
                var CryptoJS = require("crypto-js");
                var bytes = this.Dencrypt(sessionStorage.getItem(sss_token), user_key_store + sss_token);
                return bytes.toString(CryptoJS.enc.Utf8);
            }
            return false;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    static Logout() {
        try {
            sessionStorage.removeItem(is_logged);
            sessionStorage.removeItem(user_key_store);
            sessionStorage.removeItem(sss_token);
            sessionStorage.clear();
        } catch (e) {
            console.error(e);
        }
        window.location.href = '/login';
    }
    static Encrypt(value, key) {
        var CryptoJS = require("crypto-js");
        return CryptoJS.AES.encrypt(value, `nPr0c-${key}FASTEn`);
    }
    static Dencrypt(ciphertext, key) {
        var CryptoJS = require("crypto-js");
        var bytes = CryptoJS.AES.decrypt(ciphertext, `nPr0c-${key}FASTEn`);
        return bytes.toString(CryptoJS.enc.Utf8);
    }

    static isLoggedIn() {
        try {
            let isl = sessionStorage.getItem(is_logged);
            if (isl) {
                isl = this.Dencrypt(isl, user_key_store + is_logged);
                return isl === 'Y';
            }
            return false;
        } catch (e) { console.error(e); return false }
    }
    static Login(user) {
        sessionStorage.setItem(sss_token, this.Encrypt(user.token, user_key_store + sss_token));
        delete user.token;
        sessionStorage.setItem(is_logged, this.Encrypt('Y', user_key_store + is_logged));
        sessionStorage.setItem(user_key_store, this.Encrypt(JSON.stringify(user), user_key_store));
    }

    static GetUser() {
        let data = false;
        try {
            data = sessionStorage.getItem(user_key_store);
            
            if (data && data.length > 5) {
                data = this.Dencrypt(data, user_key_store);
                //console.log(data); return;
                // extract saved object from JSON encoded string
                data = JSON.parse(data);
                //data.tokenExpirationTime
                // calculate expiration time for content,
                // to force periodic refresh after 600 minutes
                const now = new Date();
                const expiration = new Date(data.timestamp);
                expiration.setMinutes(expiration.getMinutes() + 600);

                // ditch the content if too old
                if (now.getTime() > expiration.getTime()) {
                    data = false;
                    sessionStorage.removeItem(is_logged);
                    sessionStorage.removeItem(user_key_store);
                    sessionStorage.removeItem(sss_token);
                }
            } else {
                //console.log('Cannot read user...');
                //window.location.replace('/logout');
            }
        } catch (e) {
            data = false;
            //window.location.replace('/logout');
            //console.error(e);
        }
        return data;
    }

    

    static IsAdmin(user = false) {
        const allowed = ['SystemAdmin','Admin'];
        if (!user) user = this.GetUser();
        if (user && user.user_Role && user.user_Role.is_active === true) {
            return allowed.indexOf(user.user_Role.code) > -1;
        }
        return false;
    }

    static IsProjectAdmin(user = false) {
        const allowed = ['ProjectAdmin'];
        if (!user) user = this.GetUser();
        if (user && user.user_Role && user.user_Role.is_active === true && this.getAdminType(user) === 'Project') {
            return allowed.indexOf(user.user_Role.code) > -1;
        }
        return false;
    }
    static IsPromoAdmin(user = false) {
        const allowed = ['ProjectAdmin'];
        if (!user) user = this.GetUser();
        if (user && user.user_Role && user.user_Role.is_active === true && this.getAdminType(user) === 'Promo') {
            return allowed.indexOf(user.user_Role.code) > -1;
        }
        return false;
    }

    static getAdminType(user = false) {
        if (!user) user = this.GetUser();
        if (user.packageInfo && user.packageInfo.projectInfo) {
            return user.packageInfo.projectInfo.project_type;
        }
        return false;
    }

    static IsProjectUser(user = false) {
        const allowed = ['ProjectUser'];
        if (!user) user = this.GetUser();
        if (user && user.user_Role && user.user_Role.is_active === true) {
            return allowed.indexOf(user.user_Role.code) > -1;
        }
        return false;
    }

    static IsGeneralUser(user = false) {
        const allowed = ['General'];
        if (!user) user = this.GetUser();
        if (user &&  user.user_Role && user.user_Role.is_active === true) {
            return allowed.indexOf(user.user_Role.code) > -1;
        }
        return false;
    }




//------------
}
export default Auth;