import React, { Component } from 'react';


class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = this.state = {
            txt: this.props.txt
        }
         }
    componentDidMount() {
        // if (this.props.loading === true) 
        //     this.start();        
    }

    render() {
        let content = <section></section>;
        if (this.props.loading === false) {
            return (content);
        } else {
            content = <div className="loading-container"><div className="lds-ripple"><div></div><div></div></div><span className="loading-text">{this.state.txt ? this.state.txt : 'Plaese wait...'}</span></div>;
        }
        return (content);        
    }
}
export default Loading;