import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Utils from '../Common/Utils';
import $ from 'jquery';
import Loading from '../Common/Loading';
import { ApplicationState } from '../../store';
import { Field, reduxForm } from "redux-form";
import { actionCreators } from '../../store/Consent';
import * as HtmlElement from '../Common/HtmlElement';
import * as Actions from '../Common/Actions';
import * as ActionAdmin from '../Common/ActionAdmin';
import * as DataManager from '../Common/DataManager';
import selectOption from '../Common/selectOption';
import Inputmask from "inputmask";
import { stat } from 'fs';

class Consent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: this.props.state.isLoading, props: props, package_selection: [], company_selection: [], company_selected: false,
            modal: { title: '', msg: '' }
        }
        this.getUsers = (input) => {
            let r = this.state.company_selected && this.state.company_selected.value ? { company_id: this.state.company_selected.value } : '';
            return Actions.DropDown_List(ActionAdmin.Models.Users, input, DataManager.serializeToQstr(r)).then(xdata => {
                return xdata
            });
        }
        this.selectedPackage = (e, val) => {
            console.log(val);
        }
        this.selectedCompany = (e, val) => {
            this.setState({ company_selected: val });
            console.log(val);
        }
        this.selectedUser = (e, val) => {
            console.log(val);
        }
        this.loadPackage();
        this.loadCompany();


        this.submit = (vals) => {
            //console.log(vals);
            let data = [];
            data[0] = { id: vals.id_0, name: vals.name_0, description: vals.description_0, is_first: true };
            data[1] = { id: vals.id_1,name: vals.name_1, description: vals.description_1, is_last: true};
            this.setState({ loading: true });   
            ActionAdmin.Post_Model(ActionAdmin.Models.Consent, data).then(xdata => {
                if (xdata.status === 200) {
                    let tmp = {};
                    vals['id_0'] = xdata.data[0]['id'];
                    vals['id_1'] = xdata.data[1]['id'];
                    this.props.dispatch({
                        type: 'RECEIVE_CONSENT_DATA',
                        xIndex:'form',
                        data: vals
                    });
                    this.setState({
                        loading: false,
                        xdata: xdata.data
                    });
                    this.setState({
                        modal: {
                            title: 'การบันทึกข้อมูล',
                            msg: 'บันทึกข้อมูลสำเร็จ'
                        }
                    });
                    $(".modal").modal({ show: true });
                } else {
                    //this.setState({ frmeror: data.Message ? data.Massage : data.title });
                    this.setState({
                        modal: {
                            title: 'ไม่สามารถบันทึกข้อมูลได้',
                            msg: xdata.Message ? xdata.Message : xdata.message
                        }
                    });
                    $(".modal").modal({ show: true });
                }
            }).then((x) => this.setState({
                loading: false
            }));
        }
    }

    
    componentDidMount() {
        this.ensureDataFetched();    
        Inputmask("99/99/9999 99:99").mask(document.getElementsByClassName("imask-datetime"));
        //Inputmask("99:99").mask(document.getElementsByClassName("imask-time"));
    }

    // This method is called when the route parameters change
    componentDidUpdate(prevProps) {
        if (this.props.pristine && !prevProps.pristine) {
            this.setState({ company_selected: false });
        }
        this.ensureDataFetched();
    }
    ensureDataFetched() {
        const xIndex = this.props.match.params.xIndex || 0;
        this.props.requestData(xIndex);
        //ActionAdmin.Get(ActionAdmin.Models.Consent).then(xdata => {
        //    //this.props.form.
        
        //});
        //console.log(this.props.);
    }
    loadPackage() {
        Actions.DropDown_List(ActionAdmin.Models.Package).then(xdata => {
            this.setState({ package_selection: xdata });       
        });
    }
    loadCompany() {
        Actions.DropDown_List(ActionAdmin.Models.Company).then(xdata => {
            this.setState({ company_selection: xdata, company_selected: false });
        });
    }
    
 
    render() {
        //const { handleSubmit, onChange, pristine, reset, submitting  } = this.props;
        const { handleSubmit, onChange, submitting } = this.props;
        return (
            <>
            <form className="form-horizontal" onSubmit={handleSubmit(this.submit)} >
                <div class="card"><Loading loading={this.state.loading} txt={true}/>
                    <div class="card-body">
                <div class="row">
                    <div class="col-sm height_500">                    

                                    <label htmlFor="name_0" className="col-sm-12 col-form-label">First page:</label>
                            <div className="form-group row">                                
                                        <div className="col-sm-12">
                                            <Field  name="name_0" component="input" type="text" className="form-control" id="name_0" required />
                                </div>
                                    </div>
                                    <label htmlFor="description0" className="col-sm-12 col-form-label">First page description:</label>
                            <div className="form-group row">
                                        <div className="col-sm-12">
                                            <Field name="description_0" component={HtmlElement.ContentEditor} type="text" className="form-control" id="description0" />
                                </div>
                            </div>
                            </div>

                                <div className="col-sm height_500">
                                    <label htmlFor="name1" className="col-sm-12 col-form-label">Last page:</label>
                                    <div className="form-group row">
                                        <div className="col-sm-12">
                                            <Field  name="name_1" component="input" type="text" className="form-control" id="name1" required />
                                        </div>
                                    </div>
                                    <label htmlFor="description1" className="col-sm-12 col-form-label">Last page description:</label>
                                    <div className="form-group row">
                                        <div className="col-sm-12">
                                            <Field name="description_1" component={HtmlElement.ContentEditor} height="500" type="text" className="form-control" id="description1" />
                                        </div>
                                    </div>
                                </div>  

                                              
                    </div>
                    <div className="row">
                        {this.props.xerror ? <div className="alert alert-danger" role="alert"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.props.xerror}</div> : <></>}
                    </div>
                            <div className="row">
                                <div className="col-sm text-center">
                        <button type="button" className="btn btn-danger mr-2" ><i className="fa fa-times"></i> Clear</button>
                        <button type="submit" className="btn btn-primary"><i className="fa fa-check"></i> {this.props.btnName ? this.props.btnName : 'Save & Changes'}</button>
                    </div>
                            </div>
                        </div>
                </div>
            </form>
            { HtmlElement.RenderModal(this.state.modal) }
        </>
        );
    }
}


//const mapDispatchToProps = { RequestConsentAction, updateData };

function mapStateToProps({ consent }) {
    return { consent: consent };
}


Consent = reduxForm({
    form: "Frm_Consent",
    enableReinitialize: true    
})(Consent);

Consent = connect(
    (state, props) => ({
        //props: props,
        state: state.consent,
        //consent: state.consent,
        initialValues: state.consent.data
    }), actionCreators
)(Consent);
//export default connect(
//    (state, props) => ({
//        props: props,
//        state: state,
//        consent: state.consent,
//        initialValues: state.consent.data
//    }),// actionCreators
//    dispatch => bindActionCreators(actionCreators, dispatch)
//)(Consent);
export default Consent;

//const mapStateToProps = (state, props) => ({
//    initialValues: props.initialValues, // retrieve name from redux store 
//})
//export default ModalForm(
//    mapStateToProps
//)(reduxForm({
//    form: 'sixxxmple',
//    enableReinitialize: true
//})(ModalForm))