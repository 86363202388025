import React, { Component } from 'react';
import { connect } from 'react-redux';
import Utils from '../Common/Utils';
import $ from 'jquery';
import { ApplicationState } from '../../store';
import { Field, reduxForm } from "redux-form";
import * as OptionalFieldStore from '../../store/OptionalField';
import * as HtmlElement from '../Common/HtmlElement';
import * as Actions from '../Common/Actions';
import * as ActionAdmin from '../Common/ActionAdmin';
import * as DataManager from '../Common/DataManager';
import selectOption from '../Common/selectOption';
import Inputmask from "inputmask";

class OptionalField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false, props: props, package_selection: [], company_selection: [], company_selected: false,
            modal: { title: '', msg: '' }
        }
        


        this.submit = (vals) => {
            console.log(vals);

            //var data = DataManager.Project_FormToModel(vals);
            //this.setState({ modal: { title: '', msg: '' } });
            //console.log(data);
            //if (data.error && data.error.length > 0) {
            //    this.setState({ modal: { title: '', msg: <ul className="err-msg text-danger">{data.error}</ul> } });
            //    $(".modal").appendTo("body").modal({ show: true });
            //    return;
            //}
            this.setState({ loading: true });
            ActionAdmin.Post_Model(ActionAdmin.Models.OptionalField, vals).then(xdata => {
                console.log(xdata);
                if (xdata && xdata.status === 200 && xdata.data) {
                    this.props.dispatch({
                        type: 'UPDATE_OPTIONALFIELD_DATA',
                        xIndex: 'form',
                        data: xdata.data
                    });
                    this.setState({ modal: { title: 'บันทึกข้อมูลสำเร็จ', msg: 'บันทึกข้อมูลเรียบร้อย' } });
                    $('.modal').modal('show');
                } else {
                    this.setState({ modal: { title: 'ไม่สามารถบันทึกข้อมูลได้', msg: xdata.Message ? xdata.Message : xdata.message } });
                    $('.modal').modal('show');
                }
                vals = {};
            }).then((x) => this.setState({
                loading: false
            }));
        }
    }

    
    componentDidMount() {
        this.ensureDataFetched();    
        //Inputmask("99/99/9999 99:99").mask(document.getElementsByClassName("imask-datetime"));
        //Inputmask("99:99").mask(document.getElementsByClassName("imask-time"));
    }

    // This method is called when the route parameters change
    componentDidUpdate(prevProps) {
        //if (this.props.pristine && !prevProps.pristine) {
        //    this.setState({ company_selected: false });
        //}
        this.ensureDataFetched();
    }
    ensureDataFetched() {
        const xIndex = this.props.match.params.xIndex || 0;
        if (xIndex && xIndex != 0)
            this.props.requestData(xIndex);
    }
    
    renderField() {
        let feilds = [];

        for (var i = 1; i <= 10; i++) {
            feilds.push(
                <div className="form-group row">
                    <span className="col-sm-2 col-form-label">
                        {i}
                    </span>
                    <span className="col-sm-3 col-form-label">
                        <Field name={`is_field_${i}`} component="input" type="checkbox" className="form-check-input" id={`is_field_${i}`} />
                        <label class="form-check-label" for={`is_field_${i}`}>Is active</label>
                    </span>
                    <div className="col-sm-7">
                        <Field name={`field_${i}`} component="input" type="text" className="form-control" id={`field_${i}`} />
                    </div>
                </div>
            );
        }

        return feilds;
    }

 
    render() {
        const { handleSubmit, onChange, pristine, reset, submitting  } = this.props;
        return (
            <>
            <form className="form-horizontal" onSubmit={handleSubmit(this.submit)} >
                <div class="card">
                        <div class="card-body">
                            <h5 className="card-title text-secondary card-title-border"><i className="fa fa-cog" aria-hidden="true"></i> Personal optional feilds</h5>
                            <div class="row">
                                <div class="col-md-2"></div>
                    <div class="col-md-8">                    
                                    <div className="form-group row border-bottom">
                                        <span className="col-sm-2 col-form-label">
                                            Field No.
                                    </span>
                                        <span className="col-sm-3 col-form-label">
                                            Status
                                        </span>
                                        <div className="col-sm-7">
                                            Field name
                                        </div>
                                    </div>

                                    {this.renderField()}


                                    <div className="row">
                                        {this.props.xerror ? <div className="alert alert-danger" role="alert"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.props.xerror}</div> : <></>}
                                    </div>
                                    <div className="row">
                                        <div className="col-sm text-center">
                                        <button type="button" className="btn btn-danger mr-2" data-dismiss="modal" disabled={pristine || submitting} onClick={reset}><i className="fa fa-times"></i> Clear</button>
                                        <button type="submit" className="btn btn-primary" disabled={pristine || submitting}><i className="fa fa-check"></i> {this.props.btnName ? this.props.btnName : 'Save & Changes'}</button>
                                        </div>
                                    </div>
                                </div>   
                                <div class="col-md-2"></div>
                    </div>                    
                    </div>
                </div>
            </form>
            { HtmlElement.RenderModal(this.state.modal) }
        </>
        );
    }
}

OptionalField = reduxForm({
    form:"Frm_Project",
    enableReinitialize: true
})(OptionalField);
OptionalField = connect(
    (state, props) => ({
        initialValues: state.optionalField.data
    }), OptionalFieldStore.actionCreators
)(OptionalField);

export default OptionalField;

//const mapStateToProps = (state, props) => ({
//    initialValues: props.initialValues, // retrieve name from redux store 
//})
//export default ModalForm(
//    mapStateToProps
//)(reduxForm({
//    form: 'sixxxmple',
//    enableReinitialize: true
//})(ModalForm))