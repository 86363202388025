import React, { Component } from 'react';
import E404 from '../E404';
import MetaTags from 'react-meta-tags';
import HtmlElement, {getPageDescription} from '../Common/HtmlElement';
class Contact extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }
    /*
     <div className="row" style={{
                'background-image': 'url(/img/contact/bg1.png?v=1)', 'background-position': 'center top', 'background-size': '100% auto',
                'background-repeat':'no-repeat'}}>
                <div className="col-sm-12">
                    <div className="contact-social">
                        <div className="contact-main">
                            <h2>Line: @Wintegrate99</h2>
                            <h2>Face Book: Wintegrate 99</h2>
                            <h2>E-mail: ADMIN@WINTEGRATE99.COM</h2>
                        </div>
                    </div>
                </div>
            </div>
     */
    render(){
        return (<div className="container fcontainer">
            <MetaTags>
                <title>Contact</title>
                <meta name="description" content={'Contact '+ getPageDescription()} />
                <meta property="og:title" content="Contact" />
            </MetaTags>
            <h1 className="header-info mb-0"><i class="fa fa-circle-o text-warning" aria-hidden="true"></i> How to reach us</h1>
            <div className="row">
                <div className="col-sm-12">
                    <div className="bd-callout bd-callout-info uppercase">
                        <h3 className="h3">Line: <span className="text-danger">@Wintegrate99</span></h3>
                        <h3 className="h3">Face Book: <span className="text-danger">Wintegrate 99</span></h3>
                        <h3 className="h3">E-mail: <span className="text-danger">ADMIN@WINTEGRATE99.COM</span></h3>
                    </div>
                    <div className="bd-callout bd-callout-info">
                        <h3 className="text-primary">Wintegrate 99 Co., Ltd.</h3>
                        <div className="d-none"><b>Representative Office:</b> 1687/1 Phahon 19 Building, Floor 2, Phahonyothin Rd.Ladyao, Chatuchak, Bangkok 10900 Thailand</div>
                    </div>
                </div>
            </div>
        </div>
        );
        }
    }

export default Contact;
