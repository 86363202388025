import Utils from './Utils';
import * as DataManager from './DataManager';
const axios = require('axios');

export default function Actions() {

}

export async function Get_Chindate_4p(q) {
    if (!q) return false; 
    try {
        let resp = await fetch('/api/Guest/GetChindate', {
            method: 'POST',
            headers: Utils.headerXRequest(),
            body: JSON.stringify(q)
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export async function SavePerson(q) {
    if (!q) return false;
    try {
        let resp = await fetch('/api/Guest/SavePerson', {
            method: 'POST',
            headers: Utils.headerXRequest(),
            body: JSON.stringify(q)
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export async function Get_Step(id,step,save=true) {
    if (!id) return false;
    try {
        let resp = await fetch(`/api/Guest/Getstep?id=${id}&step=${step}&save=${save}`, {
            method: 'GET',
            headers: Utils.headerXRequest(),
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}


export async function Get_Analysis(id) {
    if (!id) return false;
    try {
        let resp = await fetch('/api/Guest/Analysis?id='+id, {
            method: 'GET',
            headers: Utils.headerXRequest(),
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}
export async function IsProject_Available(id,full = false) {
    if (!id) return false;
    try {
        let resp = await fetch('/api/ProjectClient/IsProject_Available?id=' + id + '&f=' + full, {
            method: 'GET',
            headers: Utils.headerXRequest(),
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}


export async function Get_Verification(q) {
    if (!q) return false;
    try {
        let resp = await fetch('/api/Guest/GetVerification', {
            method: 'POST',
            headers: Utils.headerXRequest(),
            body: JSON.stringify(q)
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export async function Get_OptionalField(id = false) {
    try {
        let resp = await fetch('/api/OptionalField/GetAsync', {
            method: 'GET',
            headers: Utils.headerXRequest()
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export async function SaveAnswer(q) {
    if (!q) return false;
    try {
        let resp = await fetch('/api/Guest/SaveAnswer', {
            method: 'POST',
            headers: Utils.headerXRequest(),
            body: JSON.stringify(q)
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}


export async function Save_Verification(q) {
    if (!q) return false;
    try {
        let resp = await fetch('/api/Guest/SaveVerification', {
            method: 'POST',
            headers: Utils.headerXRequest(),
            body: JSON.stringify(q)
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const select_none = { id: 0, value: 0, label: 'None', name: 'None' };
export function DropDown_List(service_name, q = '', r = null) {
    if (!q) q = '';
    r = (r && r !== null && r !== '') ? '&' + r : '';
    //r = (r && r !== null && r !== '') &&  DataManager.isEmptyObject(r) ? DataManager.serializeToQstr(r) : '';
    try {
        //return fetch(`/api/List/${service_name}?q=${q}${r}`)
        //    .then((response) => { return response.json(); }).then((xdata) => {
        //        return xdata.data;
        //    });
        return fetch(`/api/List/${service_name}?q=${q}${r}`, {
            method: 'GET',
            headers: Utils.headerXRequest()
        }).then((response) => { return response.json(); }).then((xdata) => {
                return xdata.data;
        });
        //let resp_data = await resp.json();
        //return resp_data;

    } catch (err) {
        console.log(err);
        return [];
    }
}
export async function DropDown_Country(service_name, r, q = null) {
    if (!q) q = '';
    try {
        let resp = await fetch(`/api/List/${service_name}?r=${r}&q=${q}`, {
            method: 'GET',
            headers: Utils.headerXRequest(),
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        return [];
    }
}

export async function Login(q) {
    if (!q) return false;
    try {
        let resp = await fetch('/api/Users/Authenticate', {
            method: 'POST',
            headers: Utils.headerXRequest(),
            body: JSON.stringify(q)
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export async function ProjectAuth(q) {
    if (!q) return false;
    try {
        let resp = await fetch('/api/ProjectClient/ProjectAuth', {
            method: 'POST',
            headers: Utils.headerXRequest(),
            body: JSON.stringify(q)
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export async function SentMail(data) {
    if (!data) return false;
    try {
        let resp = await fetch('/api/Guest/SentMail', {
            method: 'POST',
            headers: Utils.headerXRequest(),
            body: JSON.stringify(data)
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export async function GuestDataById(xIndex) {
    if (!xIndex) return false;
    try {
        let resp = await fetch(`/api/Guest/GuestDataById?id=${xIndex}`, {
            method: 'GET',
            headers: Utils.headerXRequest()
        });
        let resp_data = await resp.json();
        return resp_data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}
