import * as React from 'react';
import { connect } from 'react-redux';
import Loading from '../../Common/Loading';
import $ from 'jquery';
import HtmlSet from '../../Common/HtmlSet';
import * as ActionAdmin from '../../Common/ActionAdmin';
import Utils from '../../Common/Utils';
import ModalForm from '../../Common/ModalForm';
import * as Actions from '../../Common/Actions';
import * as ProjectStore from '../../../store/Project';
import * as HtmlElement from '../../Common/HtmlElement';
import * as DataManager from '../../Common/DataManager';
import { Field, reduxForm, change, onChange, submit } from 'redux-form';
import Auth from '../../Common/Auth';
class PromosList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true, modal: [], xdata: false, search: null, xSearch: DataManager.search_args, users_selection: [], users_selected: false,
            project_selection: [], project_selected: false, user: Auth.GetUser()
        }
        this.search = () => {
            let input_val = document.getElementById('search_name').value;
            var q = [];
            if (DataManager.checkNotNull(input_val))
                q.push('doc=' + input_val);
            if (this.state.users_selected && !DataManager.isGuidEmpty(this.state.users_selected.value)) {
                q.push('uid=' + this.state.users_selected.value);
            }
            if (this.state.company_selected && this.state.company_selected.value > 0) {
                q.push('comp=' + this.state.company_selected.value);
            }
            console.log(q);

            this.props.history.push(`/admin/promos/1/${q.join('||')}`);
        }
        this.clear = () => {
            this.props.match.params.search = undefined;
            this.setState({ search: {}, xSearch: false, users_selected: false, project_selected: false, });
            this.props.change('users', false);
            this.props.change('company', false);
            $('input').val('');
            this.loadUsers();
            this.props.history.push(`/admin/promos`);
        }
        this.getQstr = (s = null) => {
            if (DataManager.checkNotNull(s)) {
                let search = DataManager.searchQstr(s);
                if (search) {
                    this.setState({ search: search });
                    return search;
                }
            } else {
                this.setState({ search: {} });
            }
            return false;
        }
        this.selectedCompany = (e, val) => {
            this.setState({ company_selected: val });
            this.loadUsers(val);
        }
        this.selectedUsers = (e, val) => {
            this.setState({ users_selected: val });
        }
        this.loadCompany();
        this.loadUsers();
    }

    submit = (vals) => {
        Utils.HideModalFormTable(vals);

        let is_reload = vals && vals.id && vals.id != 0 ? false : true;

        this.setState({ loading: true });
        ActionAdmin.Post_Model(ActionAdmin.Models.Promos, vals).then(data => {
            if (data.status === 200) {
                let xdata = this.state.xdata;
                const i = xdata.data.findIndex((obj => obj.id == vals.id));
                xdata.data[i] = vals;
                this.setState({
                    loading: false,
                    xdata: xdata
                });
                if (is_reload) this.loadData(this.state.xIndex);
            } else {
                this.setState({ frmeror: data.Message ? data.Message : data.title });
                Utils.ShowModalFormTable(vals);
            }

        }).then((x) => this.setState({
            loading: false
        }));
        return;
    }

    deleteData(data) {
        console.log(data)
        let is_reload = false;
        ActionAdmin.Delete_Model(ActionAdmin.Models.Promos, data.id).then(xdata => {
            console.log(xdata);
            if (xdata && xdata.status === 200) {
                // ok
                is_reload = true;
            }
            if (is_reload) this.loadData(this.state.xIndex);
        });
    }
    updateFrm(data) {
        this.props.history.push('/admin/promo/form/' + (data && data.id ? data.id : ''));
        //this.setState({
        //    modal: <ModalForm form="Form_Modal" xerror={this.state.frmeror} initialValues={data} body={this.renderForm(data)} title={`แก้ไขข้อมูล #${data.id}`} onSubmit={this.submit} />
        //});
        //Utils.ShowModalFormTable(data);
    }
    linkDept(data) {
        this.props.history.push('/admin/department/1/comp=' + data.id);
    }
    linkPost(data) {
        this.props.history.push('/admin/position/1/comp=' + data.id);
    }

    loadCompany() {
        Actions.DropDown_List(ActionAdmin.Models.Company).then(xdata => {
            this.setState({ company_selection: xdata, company_selected: false });
        });
    }
    loadUsers(val = null) {
        let comp_id = val && val.value ? 'company_id=' + val.value : null;//(this.state.company_selected && this.state.company_selected.value > 0) ? 'company_id=' + this.state.company_selected.value : null;
        Actions.DropDown_List(ActionAdmin.Models.Users, null, comp_id).then(xdata => {
            this.setState({ users_selection: xdata, users_selected: false });
        });
    }
    updateShowData(data) {
        console.log(data);
        let modal = [];
        modal['title'] = data.name;
        modal['msg'] = <table class="table table-sm table-hover table-borderless table-data-list">
            <tbody>

                <tr>
                    <th scope="row">Name :</th>
                    <td>{data.name == null ? '-' : data.name}</td>
                </tr>
                <tr>
                    <th scope="row">Login :</th>
                    <td className="bg-info text-white">
                        {data.path == null ? '-' : <><b>URL : </b>{DataManager.getCurrentDomain() + '/pro/' + data.path}</>}<br />
                        {data.pass == null ? '-' : <><b>Username :</b> {data.key}  <br /><b>Password :</b> {data.pass}</>}
                    </td>
                </tr>
                <tr>
                    <th scope="row">Period date :</th>
                    <td>{DataManager.dateTimeToInput(data.start_date, false, false)} - {DataManager.dateTimeToInput(data.end_date, false, false)}</td>
                </tr>
                <tr>
                    <th scope="row">Code :</th>
                    <td>{data.code == null ? '-' : data.code}</td>
                </tr>
                <tr>
                    <th scope="row">Useable :</th>
                    <td>{data.is_active === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-secondary"></i>}</td>
                </tr>

                <tr>
                    <th scope="row">Quantity :</th>
                    <td>{data.quantity}</td>
                </tr>
                <tr>
                    <th scope="row">Path :</th>
                    <td>{data.path == null ? '-' : DataManager.getCurrentDomain() + '/pro/' + data.path}</td>
                </tr>
                <tr>
                    <th scope="row">Description :</th>
                    <td>{data.description == null ? '-' : data.description}</td>
                </tr>
                <tr>
                    <th scope="row">Remark :</th>
                    <td>{data.remark == null ? '-' : data.remark}</td>
                </tr>
                <tr>
                    <th scope="row">Promo admin :</th>
                    <td>{data.project_Manager == null ? '-' : data.project_Manager.full_name}</td>
                </tr>
                <tr>
                    <th scope="row">Package name :</th>
                    <td>{data.packageMain.name}</td>
                </tr>
            </tbody>
        </table>;

        this.setState({ modal: modal });
        $(".modal").modal({ show: true });
    }

    openPage(data) {
        console.log(data);
        this.props.history.push(`/admin/pageform/${data.page_id}`);
    }

    renderForm(data) {
        return (
            <div>
                <div className="form-group row">
                    <label htmlFor="water" className="col-sm-4 col-form-label">ชื่อบริษัท:</label>
                    <div className="col-sm-8">
                        <Field name="name" component="input" type="text" className="form-control" id="name" />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="wood" className="col-sm-4 col-form-label">รายละเอียด:</label>
                    <div className="col-sm-8">
                        <Field name="description" component="textarea" type="text" className="form-control" id="description" />
                    </div>
                </div>

            </div>
        );
    }

    componentDidMount() {
        //this.setState({ modal: { title: '', msg: '' } });
        const xIndex = this.state.xIndex || 1;
        let search = this.getQstr(this.props.match.params.search);
        ActionAdmin.Get_List(ActionAdmin.Models.Promos, xIndex, search).then(data => {
            if (data.status === 200) {
                this.setState({
                    loading: false,
                    xIndex: xIndex,
                    xdata: data,
                    search: this.props.match.params.search,
                    xSearch: search
                });
            }
        }).then((x) => this.setState({
            loading: false
        }));
    }


    renderDataTable(props) {
        let is_admin = Auth.IsAdmin(props.user);
        if (props && props.xdata && props.xdata.data) {
            return (<tbody>
                {props.xdata.data.map((data) => {
                    return <tr key={data.id} id={`rowno${data.id}`}>
                        <td>{data.name}</td>
                        <td className="text-center"><span className="text-primary">{data.used}</span><span className="text-success">/</span><b>{data.quantity}</b></td>
                        <td>{data.description}</td>
                        <td>{data.packageMain.name}</td>                        
                        <td className="text-center center">{is_admin ? <span className="btn btn-outline-secondary pointer" onClick={() => this.openPage(data)}>{data.page_active === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times"></i>}</span> : data.page_active === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times"></i>}</td>
                        <td>{data.project_Manager == null ? '-' : data.project_Manager.full_name}</td>
                        <td className="text-center center">
                            <div className="btn-toolbar text-center center">
                                <button type="button" className="btn btn-success btn-sm mr-1" onClick={() => this.updateShowData(data)}><i className="fa fa-eye"></i> ดู</button>
                                {is_admin ? <><button type="button" className="btn btn-info btn-sm mr-1" onClick={() => this.updateFrm(data)}><i className="fa fa-edit"></i> Edit</button>
                                    <button type="button" className="btn btn-danger btn-sm" onClick={() => { if (window.confirm('คุณต้องการลบข้อมูล หรือไม่?')) { this.deleteData(data); } }}><i className="fa fa-times"></i> Delete</button>
                                </> : <></>}
                            </div>
                        </td>
                    </tr>
                })}
            </tbody>);
        } else {
            return (<tbody>
                <tr>
                    <th colSpan="10"><p>Not found</p></th>
                </tr>
            </tbody>);
        }
    }

    loadData(xIndex, s = false) {
        ActionAdmin.Get_List(ActionAdmin.Models.Promos, xIndex, s).then(data => {
            if (data.status === 200) {
                this.setState({
                    xdata: data,
                    xIndex: xIndex
                    //xSearch: nextProps.match.params.search
                });
            }
            console.log(data);
        }).then((x) => this.setState({ loading: false }));
    }

    componentWillReceiveProps(nextProps) {
        const xIndex = nextProps.match.params.xIndex || 0;
        if ((xIndex === 0 || nextProps.match.params.xIndex === this.state.xIndex)  && (nextProps.match.params.search === this.state.search)) {
            this.setState({
                search: nextProps.match.params.search
            });
            return;
        } else if ((xIndex === 0 || nextProps.match.params.xIndex === this.state.xIndex) && Utils.isEmptyObject(nextProps.match.params.search) && (nextProps.match.params.search === this.state.search))
            return;

        this.setState({
            loading: true
        });

        ActionAdmin.Get_List(ActionAdmin.Models.Promos, xIndex, this.getQstr(nextProps.match.params.search)).then(data => {
            if (data.status === 200) {
                this.setState({
                    xdata: data,
                    xIndex: xIndex,
                    search: nextProps.match.params.search,
                    xSearch: this.getQstr(nextProps.match.params.search)
                });
            }
        }).then((x) => this.setState({ loading: false }));



    }

    renderOptionComapny(state) {
        if (Auth.IsAdmin(state.user)) {
            return (
                <div className="form-group">
                    <label className="col-lg-12 text-left control-label col-form-label">Company</label>
                    <div className="col-lg-12">
                        <span className="width-100">
                            <Field id="select-company" name="company" className="custom-company" required="required"
                                onChange={this.selectedCompany} multi={false} options={this.state.company_selection}
                                component={HtmlElement.RenderSelectInput} aria-describedby="input-company" />
                        </span>
                    </div>
                </div>
            );
        } else {
            return (<div>
                <div>Promos name: </div>
                <b className="text-info">{state.user.packageInfo.projectInfo.name}</b>
            </div>);
        }
    }

    renderOptionUser(state) {
        if (Auth.IsAdmin(state.user)) {
            return (
                <div className="form-group">
                    <label className="col-lg-12 text-left control-label col-form-label">Promo admin</label>
                    <div className="col-lg-12">
                        <span className="width-100">
                            <Field id="select-users" name="users" className="custom-users" required="required"
                                onChange={this.selectedUsers} multi={false} options={this.state.users_selection}
                                component={HtmlElement.RenderSelectInput} aria-describedby="input-users" />
                        </span>
                    </div>
                </div>
            );
        } else {
            return (<div>
                <div>Promos description: </div>
                <div className="text-info">
                    {state.user.packageInfo.projectInfo.description}<br />                    
                </div>
                <div>Period : </div>
                {DataManager.dateTimeToInput(state.user.packageInfo.projectInfo.start_date)} - {DataManager.dateTimeToInput(state.user.packageInfo.projectInfo.end_date, false, false)}
            </div>);
        }
    }
    btnAddData(state) {

        if (Auth.IsAdmin(state.user)) {
            return (<div className="col-sm border-right margin-bottom-20">
                <div className="row">
                    <div className="col-12">
                        <button type="button" className="btn btn-primary mb-2 mr-1" onClick={() => this.updateFrm({})}><i className="fa fa-plus"></i> เพิ่มข้อมูล</button>
                    </div>

                </div>
            </div>);
        } else
            return <></>;
    }


    render() {
        return (<>
            <div className="card">
                <div className="card-body"><Loading loading={this.state.loading} txt={true} />
                    <h5 className="card-title card-title-border text-secondary"><i className="fa fa-cog" aria-hidden="true"></i> Promos managements</h5>
                    <div className="row">
                        {this.btnAddData(this.state)}

                        <div className="col-sm-10 margin-bottom-20">
                            <div className="row">
                                
                                <div className="col-sm-4">
                                    {this.renderOptionUser(this.state)}
                                </div>                                
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label className="col-lg-12 text-left control-label col-form-label">Search</label>
                                        <div className="col-lg-12">
                                            <div className="mb-2">
                                                <label for="search_name" className="sr-only">Search</label>
                                                <input type="text" className="form-control" id="search_name" placeholder="Search..." defaultValue={this.state.xSearch.doc} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 text-right">
                                            <button type="button" className="btn btn-primary mb-2 mr-1" onClick={this.search}><i className="fa fa-search"></i> Search</button>
                                            <button type="button" className="btn btn-secondary mb-2" onClick={this.clear}><i className="fa fa-refresh"></i> Clear</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    {/*this.renderOptionComapny(this.state)*/}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    {this.state.modal}

                    <table className="table table-sm table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Promo name</th>
                                <th scope="col">Used/Quantity</th>
                                <th scope="col">Description</th>
                                <th scope="col">Package</th>
                                <th scope="col">Page active</th>
                                <th scope="col">Promo admin</th>
                                <th scope="col" className="btn-col-3"></th>

                            </tr>
                        </thead>
                        {this.state.loading && !this.state.xdata ? <tbody><tr><td colSpan="10"><p>Loading...</p></td></tr></tbody> : this.renderDataTable(this.state)}
                    </table>
                    <div className="row">
                        <div className="col-sm-12 float-right">
                            <div className="dataTables_paginate paging_simple_numbers float-right padding-right" id="zero_config_paginate">
                                {this.state.loading && !this.state.xdata ? 'Looking for data...' : HtmlSet.renderPagination(this.state, '/admin/promos/', this.props.match.params.search)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {HtmlElement.RenderModal(this.state.modal, <i className="fa fa-info text-primary" aria-hidden="true"></i>, 'modal-lg')}
        </>
        );
    }
}
PromosList = reduxForm({
    form: "Promos_List",
    enableReinitialize: true
})(PromosList);
PromosList = connect(
    (state, props) => ({
        initialValues: state.project.data
    }), ProjectStore.actionCreators
)(PromosList);


export default connect()(PromosList);