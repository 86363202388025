import React from 'react';
import $ from 'jquery';
const date_default_set = '-01-01T00:00:00';
const date_without_month_set = '-01T00:00:00';
const date_without_full_set = 'T00:00:00';
const min_year = '0001';

export default function DataManager() {

}

export function checkNotNull(value, space = false) {
    if (space)
        return (value !== undefined && typeof value !== 'undefined' && value !== null && value !== 'null' && value !== '' && value !== ' ');
    return (value !== undefined && typeof value !== 'undefined' && value !== null && value !== 'null' && value !== '');
}

export function searchQstr(s) {
    if (!s) return false;
    if (isEmptyObject(s)) return false;
    const path = s.split('||');
    let search = {};
    for (var i in path) {
        let p = path[i].split('=');
        if (p.length === 2) {
            if ((p[1].match(new RegExp("-", "g")) || []).length === 2) {
                search[p[0]] = dateToInput(p[1], true);
            } else
                search[p[0]] = p[1];
        }
    }
    return isEmptyObject(search) ? false : search;
}

export function getGender() {
    return [{ value: false, label: 'All..' }, { value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }];
}

export function removeArrayByIndex(arrOriginal, index) {
    return arrOriginal.filter(function (el, i) { return i !== index });
}

export function insertAt(array, index) {
    var arrayToInsert = Array.prototype.splice.apply(arguments, [2]);
    return insertArrayAt(array, index, arrayToInsert);
}

export function insertArrayAt(array, index, arrayToInsert) {
    Array.prototype.splice.apply(array, [index, 0].concat(arrayToInsert));
    return array;
}

export function isEmptyObject(obj) {
    if (typeof obj === 'undefined' || obj === undefined || obj === null)
        return true;
    for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) //obj.hasOwnProperty(prop))
            return false;
    }
    return JSON.stringify(obj) === JSON.stringify({});
}

export function dateToInput(_date, en = false) {
    if (!checkNotNull(_date)) return '';
    var to_date = _date;
    if (_date.search('/') > -1) {
        var parts = _date.split('/');
        if (parts[0].length === 4)
            to_date = zeroPad(parts[2].split('T')[0]) + '/' + zeroPad(parts[1]) + '/' + convertInputToYearNumber(parts[0], en);
        else if (parts[0].length === 2)
            to_date = zeroPad(parts[0].split('T')[0]) + '/' + zeroPad(parts[1]) + '/' + convertInputToYearNumber(parts[2], en);
    } else if (_date.search('-') > -1) {
        var parts = _date.split('-');
        if (parts[0].length === 4)
            to_date = zeroPad(parts[2].split('T')[0]) + '/' + zeroPad(parts[1]) + '/' + convertInputToYearNumber(parts[0], en);
        else if (parts[0].length === 2)
            to_date = zeroPad(parts[0].split('T')[0]) + '/' + zeroPad(parts[1]) + '/' + convertInputToYearNumber(parts[2], en);
    }
    try {
        var y = parseInt(to_date.split('/')[2]);
        if (y < 2000) return '';

    } catch (e) {
        console.error(e);
        return '';
    }
    return to_date;

}


export function dateTimeToInput(_date, en = false, full_time = true) {
    if (!checkNotNull(_date)) return '';
    var to_date = _date;

    let time = _date.split('T');
    time = time.length > 1 && time[1] !== null && time[1] !== '' ? time[1] : false
    if (time) {
        var t = time.split(':');
        time = [];
        if (full_time) {
            for (var i = 0; i < t.length; i++)
                if ($.isNumeric(t[i]))
                    time.push(zeroPad(t[i]));
            time = ' ' + time.join(':');
        } else
            time = ' ' + t[0] + ':' + t[1];
		        _date = _date.split('T')[0];		
    }


     if (_date.search('/') > -1) {
        var parts = _date.split('/');        
        if (parts[0].length === 4)
            to_date = zeroPad(parts[2]) + '/' + zeroPad(parts[1]) + '/' + convertInputToYearNumber(parts[0], en) + (time ? time : ' ');
        else if (parts[0].length === 2)
            to_date = zeroPad(parts[0][0]) + '/' + zeroPad(parts[1]) + '/' + convertInputToYearNumber(parts[2], en) + (time ? time : ' ');
    } else if (_date.search('-') > -1) {
        var parts = _date.split('-');
        if (parts[0].length === 4)
            to_date = zeroPad(parts[2]) + '/' + zeroPad(parts[1]) + '/' + convertInputToYearNumber(parts[0], en) + (time ? time : ' ');
        else if (parts[0].length === 2)
            to_date = zeroPad(parts[0]) + '/' + zeroPad(parts[1]) + '/' + convertInputToYearNumber(parts[2], en) + (time ? time : ' ');
    }
    try {
        var y = parseInt(to_date.split('/')[2]);
        if (!en && y < 2000) return '';

    } catch (e) {
        console.error(e);
        return '';
    }
    return to_date;

}

export function Profile_ToForm(data) {
    if (!data) return {};

    let opt = {value:'',label:''};

    let dob = dateTimeToInput(data.person_Info.dob).split(' ');    
    data.person_Info['date_of_birth'] = dob[0];
    data.person_Info['birth_time'] = dob[1];
    data.person_Info['country'] = {
        value: data.person_Info.birth_Place.country_code, label: data.person_Info.birth_Place.country_name, code: data.person_Info.birth_Place.country_code };
    data.person_Info['province'] = { id: data.person_Info.birth_Place.province_id, value: data.person_Info.birth_Place.province_id, label: data.person_Info.birth_Place.province_name, name: data.person_Info.birth_Place.country_code };
    data.person_Info['district'] = { id: data.person_Info.birth_Place.district_id, value: data.person_Info.birth_Place.district_id, label: data.person_Info.birth_Place.district_name, name: data.person_Info.birth_Place.province_id };
    if (data.person_Info.company_Employee) {
        data.person_Info['department'] = { id: data.person_Info.company_Employee.department.id, value: data.person_Info.company_Employee.department.id, label: data.person_Info.company_Employee.department.name };
        data.person_Info['position'] = { id: data.person_Info.company_Employee.position.id, value: data.person_Info.company_Employee.position.id, label: data.person_Info.company_Employee.position.name };
    }
    //console.log(data);
    return data;
}


export function stringToDateFormat(_date, is_time = true) {
    if (!checkNotNull(_date)) return '0001' + date_default_set;
    var to_date = _date;
    try {
        let time = _date.split(' ');
        if (time.length > 0 && typeof time[0] != 'undefined' && typeof time[1] != 'undefined')
            _date = time[0];
        if (is_time) {
            time = time.length > 0 && typeof time[1] != 'undefined' && time[1] !== null && time[1] !== '' ? time[1] : false
            if (time) {
                var t = time.split(':');
                time = [];
                if (t.length > 0) {
                    if (t[0] > 23) return null;
                    if (t[1] > 59) return null;
                }
                for (var i = 0; i < t.length; i++)
                    if ($.isNumeric(t[i]))
                        time.push(zeroPad(t[i]));
                time = 'T' + time.join(':');
            }
        } else
            time = false;

        if (_date.search('/') > -1) {
            var parts = _date.split('/');
            //console.log(parts);
            if (parts[0].length === 4) {
                if (convertInputToYearNumber(parts[0]) > 5000)
                    return '';
                to_date = convertInputToYearNumber(parts[0]) + '-' + zeroPad(parts[1]) + '-' + zeroPad(parts[2]) + (is_time ? time ? time : date_without_full_set : '');
            } else if (parts[0].length === 2) {
                if (convertInputToYearNumber(parts[2]) > 5000)
                    return '';
                to_date = convertInputToYearNumber(parts[2]) + '-' + zeroPad(parts[1]) + '-' + zeroPad(parts[0]) + (is_time ? time ? time : date_without_full_set : '');
            } else if (parts.length === 3 && parts[2].length === 4) {
                if (convertInputToYearNumber(parts[2]) > 5000)
                    return '';
                to_date = convertInputToYearNumber(parts[2]) + '-' + zeroPad(parts[1]) + '-' + zeroPad(parts[0]) + (is_time ? time ? time : date_without_full_set : '');
            }
        } else if (_date.search('-') > -1) {
            var parts = _date.split('-');
            if (parts[0].length === 4) {
                if (convertInputToYearNumber(parts[0]) > 5000)
                    return '';
                to_date = convertInputToYearNumber(parts[0]) + '-' + zeroPad(parts[1]) + '-' + zeroPad(parts[2]) + (is_time ? time ? time : date_without_full_set : '');
            } else if (parts[0].length === 2) {
                if (convertInputToYearNumber(parts[2]) > 5000)
                    return '';
                to_date = convertInputToYearNumber(parts[2]) + '-' + zeroPad(parts[1]) + '-' + zeroPad(parts[0]) + (is_time ? time ? time : date_without_full_set : '');
            } else if (parts.length === 3 && parts[2].length === 4) {
                if (convertInputToYearNumber(parts[2]) > 5000)
                    return '';
                to_date = convertInputToYearNumber(parts[2]) + '-' + zeroPad(parts[1]) + '-' + zeroPad(parts[0]) + (is_time ? time ? time : date_without_full_set : '');
            }
        }
        //console.log(to_date);
        return to_date;
    } catch (ex) {
        console.error(ex);
        if (ex.message === '_date.search is not a function') {
            return _date;
        }
    }
    return to_date;
}


function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}
export function Guid(gen = true) {
    if (gen) {
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
    return '00000000-0000-0000-0000-000000000000';
}
export function isGuidEmpty(id) {
    return !id || id == '' || id.length < 32  || Number.isInteger(id) || id === '00000000-0000-0000-0000-000000000000';
}

function fullOfName(str) {
    var user = { firstname: '', lastname: '', aslias_name: '' };
    if (!checkNotNull(str)) return user;    
    var res = str.split(' ');
    if (res.length == 1) {
        user.firstname = res[0];
        user.aslias_name = res[0];
    } else if (res.length == 2) {
        user.firstname = res[0];
        user.aslias_name = res[0];
        user.lastname = res[1];
    } else if (res.length == 3) {
        user.firstname = res[0] + ' ' + res[1];
        user.aslias_name = res[0] + ' ' + res[1];
        user.lastname = res[2];
    } else if (res.length == 4) {
        user.firstname = res[0] + ' ' + res[1];
        user.aslias_name = res[0] + ' ' + res[1];
        user.lastname = res[2] + ' ' + res[3];
    } else {
        user.firstname = str;
        user.aslias_name = str;
    }
    return user;
}

export function Project_FormToModel(vals, comp_valid = true) {
    if (!vals) return {};
    let error = [];
    let dates = { start: vals.start_date, end: vals.end_date };
    vals['dates'] = dates;
    if ((!vals.start_date || !checkNotNull(vals.start_date)) || (!vals.end_date || !checkNotNull(vals.end_date))) {
        error.push(<li key="date" className="err-msg">Plase check: start date - end date</li>);
    } else {
        let dt = stringToDateFormat(vals.start_date);
        if (checkNotNull(dt))
            vals.start_date = dt;
        else
            error.push(<li key="date1" className="err-msg">Plase check start date </li>);
        dt = stringToDateFormat(vals.end_date);
        if (checkNotNull(dt))
            vals.end_date = dt;
        else
            error.push(<li key="date1" className="err-msg">Plase check end date </li>);
    }

    if (vals.package && vals.package.value && checkNotNull(vals.package.value)) {
        vals['package_id'] = vals.package.value;
        vals['packageMain'] = {};
        vals['packageMain']['id'] = vals.package.value;
        vals['packageMain']['name'] = vals.package.label;
    } else
        error.push(<li key="package" className="err-msg">Plaese select package</li>);
    //console.log('Package value:');
    //if (vals.package)
    //console.log(vals.package.value);
    if (comp_valid) {
        if (vals.company && vals.company.value && checkNotNull(vals.company.value)) {
            vals['company_id'] = vals.company.value;
            vals['company_Info'] = {};
            vals['company_Info']['id'] = vals.company.value;
            vals['company_Info']['name'] = vals.company.label;
        } else
            error.push(<li key="package" className="err-msg">Plaese select company</li>);
    }
    if (vals.user && vals.user.value && checkNotNull(vals.user.value)) {
        if (vals.user.__isNew__ || isGuidEmpty(vals.user.value)) {
            vals['user_id'] = Guid(false);
            vals['project_Manager'] = fullOfName(vals.user.label);
            vals['project_Manager']['id'] = Guid(false);
        }else {
            vals['user_id'] = vals.user.value;
            vals['project_Manager'] = {};
            vals['project_Manager']['id'] = vals.user.value;
            vals['project_Manager']['firstname'] = vals.user.label;
        }
    } else
        error.push(<li key="package" className="err-msg">Plaese select user</li>);

    if (error.length > 0) {
        vals['start_date'] = dates.start;
        vals['end_date'] = dates.end;
    }

    //error.push(<li key="date_of_birth" className="err-msg">�� ��.�. ��ͧ���������ҧ 2443-2643</li>);
    vals['error'] = error;
    return vals;
}
export function Project_ModelToForm(vals) {
    if (!vals) return {};

    if (vals.packageMain && vals.packageMain.id && checkNotNull(vals.packageMain.id)) {
        vals['package'] = { value: vals.packageMain.id, label: vals.packageMain.name };
    } else
        vals['package'] = {};
    if (vals.project_Manager && vals.project_Manager.id && checkNotNull(vals.project_Manager.id)) {
        vals['user'] = { value: vals.project_Manager.id, label: vals.project_Manager.full_name };
    } else
        vals['user'] = {};

    if (vals.company_Info && vals.company_Info.id && checkNotNull(vals.company_Info.id)) {
        vals['company'] = { value: vals.company_Info.id, label: vals.company_Info.name };
    } else
        vals['company'] = {};

    vals.start_date = dateTimeToInput(vals.start_date);
    vals.end_date = dateTimeToInput(vals.end_date);
    return vals;
}


export function Consent_ModelToForm(data) {
    let guid = Guid(false);
    let xdata = { name_0: '', name_1: '', id_0: guid, id_1: guid, description_0: '', description_1:''};
    if (typeof data[0] != 'undefined') {
        xdata.name_0 = typeof data[0]['name'] === 'undefined' ? '' : data[0]['name'];
        xdata.description_0 = typeof data[0]['description'] === 'undefined' ? '' : data[0]['description'];
        xdata.id_0 = typeof data[0]['id'] === 'undefined' ? '' : data[0]['id'];
    }
    if (typeof data[1] != 'undefined') {
        xdata.name_1 = typeof data[1]['name'] === 'undefined' ? '' : data[1]['name'];
        xdata.description_1 = typeof data[1]['description'] === 'undefined' ? '' : data[1]['description'];
        xdata.id_1 = typeof data[1]['id'] === 'undefined' ? '' : data[1]['id'];
    }
    return xdata;
}

function ConverDateTime() {

}

function zeroPad(numberStr) {
    if (!checkNotNull(numberStr)) return '00';
    if (Number.isInteger(numberStr))
        numberStr = numberStr.toString();
    return numberStr.padStart(2, "0");
}
export function getQueryParams(location) {
    var params = {};
    var query = location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
}

function convertInputToYearNumber(val, en = true) {
    if (!val) return min_year;
    let y = parseInt(val);
    if (!en && y < 1900) return min_year;
    if (en) {
        if (y > 2300)
            y = y - 543;
    } else {
        if (y < 2300)
            y = y + 543;
    }
    return y;// + date_default_set;
}
export function serializeToQstr(obj) {
    return Object.keys(obj).reduce(function (a, k) { a.push(k + '=' + encodeURIComponent(obj[k])); return a }, []).join('&')
}
export function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
export function escapeRegex(value) {
    return value.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");
}
export function getError(xdata) {
    let msg = xdata.Message ? xdata.Message : xdata.message;
    if (!msg || !checkNotNull(msg)) {
        if (xdata.errors) {
            let msg_list = [];
            for (var prop in xdata.errors) {
                msg_list.push(<li key={prop} className="err-msg">{xdata.errors[prop][0]}</li>);
            }
            return <ul className="err-msg text-danger">{msg_list}</ul>;
        } else
            return '�������ö����¡����';
    }
    return msg;
}
export function getCurrentDomain() {

    return document.location.protocol + '//' + document.location.hostname + (document.location.port ? ':' + document.location.port : '');
}

export const search_args = {
    doc: '', name: '', department: '', status: '', date_start: '', date_end: '', amount_start: '', amount_end: '', setid: '', paytype: '', type: '', person: '', company: '', position: '', year: ''
};
export const select_all_obj = { id: 0, value: 0, label: 'All', name: 'All' };
export const select_none = { id: 0, value: 0, label: 'None..', name: 'None..' };
export const use_slash = '[sl]';