import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HtmlSet extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }    
static renderPagination(props, url, s = false) {
        if (!props.xdata || !props.xdata.data) return;
        let paging = [];
        var max = (props.xdata.paging.totalPages + 1) - 5;
        s = (s && s != null && s != '') ? '/' + s : '';
        var spacing = true;
        let disabled = props.xdata.paging.pageNumber === 1 || props.xdata.paging.pageNumber === 0 ? 'disabled' : '';
        let next_back = props.xdata.paging.pageNumber > 1 ? props.xdata.paging.pageNumber - 1 : 1;
        paging.push(<li key={`start-0`} className={`paginate_button page-item previous ${disabled}`}>
            <Link to={`${url}${next_back}${s}`} aria-controls="zero_config" className={`page-link`}>
                <i className="fa fa-step-backward"></i> Previous</Link></li>);
        if (props.xdata.paging.totalPages > 0) {
            var pages = pagination(props.xdata.paging.pageNumber, props.xdata.paging.totalPages);
            /*
            for (var i = 1; i < props.xdata.paging.totalPages + 1; i++) {        
                let active = props.xdata.paging.pageNumber === i;
                paging.push(<li key={'li-'+i} xdata={i} className={'paginate_button page-item ' + (active ? 'active' :'') }>
                    <Link key={'link-' + i} to={`${url}${i}${s}`} aria-controls="zero_config" className="page-link">{i}</Link></li>);            
            }
            */
            for (var k = 0; k < pages.length; k++) {
                let i = pages[k];
                let active = props.xdata.paging.pageNumber === i;
                if (i === '...') {
                    paging.push(<li key={'li-' + k + '_skip'} xdata={i} className={'page-item disabled ' + (active ? 'active' : '')}>
                        <span key={'link-' + k + '_skip'} aria-controls="zero_config" className="page-link disabled page-link-disabled">{i}</span></li >);
                } else {
                    paging.push(<li key={'li-' + i} xdata={i} className={'paginate_button page-item ' + (active ? 'active' : '')}>
                        <Link key={'link-' + i} to={`${url}${i}${s}`} aria-controls="zero_config" className="page-link">{i}</Link></li>);
                }
            }
        }
        disabled = props.xdata.paging.pageNumber === props.xdata.paging.totalPages || props.xdata.paging.totalPages < 2 ? 'disabled' : '';
        next_back = props.xdata.paging.totalPages > 1 ? props.xdata.paging.pageNumber + 1 : props.xdata.paging.totalPages;
        paging.push(<li key={max} className={`paginate_button page-item next ${disabled}`}>
            <Link to={`${url}${next_back}${s}`} aria-controls="zero_config" className={`page-link ${disabled}`}>
                Next <i className="fa fa-step-forward"></i></Link></li>);   
        return <React.Fragment><span className="total-record">Total: {props.xdata.paging.totalItems.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} records</span> <ul className="pagination">{paging}</ul></React.Fragment>;
      }
  }
function pagination(currentPage, nrOfPages) {
    var delta = 2,
        range = [],
        rangeWithDots = [],
        l;

    range.push(1);

    if (nrOfPages <= 1) {
        return range;
    }

    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
        if (i < nrOfPages && i > 1) {
            range.push(i);
        }
    }
    range.push(nrOfPages);

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
}

  export default HtmlSet;